import { infoIcon } from '@core/icons/icons'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import React from 'react'
import Typography from '@atoms/Typography/Typography'
import Icon from '@atoms/Icon/Icon'

export const RecoveryKeysWarning = () => {
  return (
    <StyledAlert>
      <StyledAlertText>
        <div className="mr-2">
          <Icon icon={infoIcon} fill="#FFA530" />
        </div>
        <Typography>
          If you lose access to your authentication device, you can use this
          recovery code to login to your account. Copy this code, and store it
          somewhere safe.
        </Typography>
      </StyledAlertText>
    </StyledAlert>
  )
}

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  background-color: #ffe8c2;
`

const StyledAlertText = styled.div`
  display: flex;
  padding-right: 1rem;
  font-size: 13px;
  color: #ffa530;
  align-items: center;
  line-height: 18px;
`
