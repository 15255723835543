import { Api } from '@api/Api'

export const fetchUpdatePaymenyDetails = async (id, body) => {
  const { data } = await Api.put(`payment-details/${id}/update/`, body)
  return data
}

export const fetchWorker = async () => {
  const { data } = await Api.get(`contracts/my/`)
  return data
}

export const getResetPassword = async (email) => {
  const v = await Api.post(`password/reset-password/`, {
    email,
  })
  return v.data
}

export const fetchUploadFile = async (file, progressCallBack) => {
  const fd = new FormData()
  fd.append('file', file)
  const { data } = await Api.post(`temporary-files/`, fd, {
    onUploadProgress: (progressEvent) => {
      const progress = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total),
        10
      )
      if (progressCallBack) progressCallBack(progress)
    },
  })
  return data
}

export const postUserReport = async (data) => {
  const v = await Api.post(`reports/users/create/`, data)
  return v.data
}

export const fetchCurrencies = async (params) => {
  const v = await Api.get(`currencies/`, { params })
  return v.data
}

export const getOnboadringDocuments = async (contract_id, limit, page) => {
  const offset = (page - 1) * limit
  const { data } = await Api.get(
    `contract-documents-handlers/?limit=${limit}&offset=${offset}&contract_id=${contract_id}`
  )
  return data
}

export const uploadDeclinedDocuments = async (
  declinedId,
  file,
  progressCallBack
) => {
  const fd = new FormData()
  fd.append('file', file)
  const { data } = await Api.put(
    `contract-documents-handlers/${declinedId}/upload/`,
    fd,
    {
      onUploadProgress: (progressEvent) => {
        const progress = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
          10
        )
        if (progressCallBack) progressCallBack(progress)
      },
    }
  )
  return data
}

export const fetchTermsOfServices = async (id) => {
  const { data } = await Api.post(`contracts/${id}/terms-of-services-agree/`)
  return data
}
export const fetchPopUpMfa = async () => {
  const v = await Api.get('popup/mfa')
  return v.data
}
export const fetchMfaRemind = async () => {
  const v = await Api.post('popup/mfa')
  return v.data
}
export const fetchMfaCancel = async () => {
  const v = await Api.delete('popup/mfa')
  return v.data
}
