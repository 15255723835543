export const roles = {
  company_owner: `Company Admin`,
  'company owner': `Company Admin`,
  company_admin: `Company Manager`,
  partner_owner: `Partner Admin`,
  partner_manager: `Partner Manager`,
  admin: `System Admin`,
  dummy: `System Admin`,
  employee: `Employee`,
}
