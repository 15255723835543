import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { MdOutlineInsertDriveFile } from 'react-icons/md'
import styled from 'styled-components'

import AgreementDetail from './agreement-detail/agreement-detail'

const FileIcon = styled(MdOutlineInsertDriveFile)`
  color: #04c53a;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`

export const transformAgreements = (
  agreementsResponse,
  agreementDetail,
  isLoading
) => {
  if (!agreementsResponse || !agreementsResponse.results) return []
  return agreementsResponse.results.map((item) => ({
    id: item.id,
    name: (
      <>
        <FileIcon />
        {item.title}
      </>
    ),
    signature: item.signature,
    status:
      item.agreement_document_type === 'salary_change' &&
      item.state === 'not_signed'
        ? 'salary_not_signed'
        : item.state,
    pending: item.awaiting_signature_count,
    created: moment(item.created_at).format('DD MMM YYYY, HH:mm'),
    original: item,
    collapseContent: isLoading ? (
      <div
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ height: 200 }}
      >
        <DotWave size={32} speed={1} color="black" />
      </div>
    ) : (
      <AgreementDetail data={agreementDetail} />
    ),
  }))
}
