import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { downloadCloud, signIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import HelloSign from 'hellosign-embedded'

import { useToast } from '@core/hooks/useErrorNotification'
import { AbsoulteSpinner } from '@atoms/AbsoulteSpinner'
import { useBoolean } from 'usehooks-ts'
import { AGREEMENT_STATUS, getSignUrl } from '../../../services/sign.service'

import { client } from '../hellosign-client'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;

  .dropdown-menu {
    background: #fff;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
      0px 4px 6px -2px rgb(16 24 40 / 3%);
  }
  .dropdown-item {
    font-weight: 500;
    font-size: 13px;
  }
`

const AgreementActions = ({ item }) => {
  const { successAlert } = useToast()
  const signatureId = item?.original?.signature?.id
  const loading = useBoolean(true)

  const { mutate, data: signUrl } = useMutation(['signUrl', signatureId], {
    mutationFn: () => getSignUrl(signatureId),
    onSuccess: ({ sign_url }) => {
      client.open(sign_url, {
        skipDomainVerification:
          process.env.REACT_APP_HELLOSIGN_SKIP_DOMAIN === 'true',
      })
    },
  })

  useEffect(() => {
    if (signUrl) {
      client.on('finish', () => {
        successAlert(
          'Agreement has been signed! It may take some time to change status.'
        )
      })
    }
    return () => {
      client.off('finish')
    }
  }, [signUrl])

  const handleDownload = () => {
    if (item?.original?.signed_agreement_document) {
      window.open(item.original.signed_agreement_document, '_blank')
    }
  }
  const handleSign = (e) => {
    e.stopPropagation()
    mutate()
  }

  return (
    <Container>
      {item.status === AGREEMENT_STATUS.SIGNED ||
      item.status === AGREEMENT_STATUS.UPLOADED ? (
        <Button
          priority="secondary"
          style={{ height: '32px' }}
          size="small"
          className="px-2 pl-0"
          onClick={handleDownload}
        >
          <Icon fill="none" icon={downloadCloud} />
          <Typography className={classNames('text_light__12', 'ml-2')}>
            Download
          </Typography>
        </Button>
      ) : (
        <Button
          hidden={!item.signature}
          priority="primary_black"
          style={{ height: '32px', width: '113px' }}
          size="small"
          className="px-2 pl-0"
          onClick={handleSign}
        >
          <Icon fill="none" icon={signIcon} />
          <Typography className={classNames('text_light__12', 'ml-2')}>
            Sign
          </Typography>
        </Button>
      )}
    </Container>
  )
}

export default AgreementActions
