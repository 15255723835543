/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './Router.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { AppContext } from '@core/context'
import { Api, useAxiosCall } from '@api/Api'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import ErrorFallback from '@atoms/ErrorFallback'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import {
  contractTemplates,
  expenses,
  invoices,
  notificationNone,
  overview,
  payments,
  payslips,
  requiredDocuments,
  team,
  timeOff,
} from '@core/icons/icons'
import { AgreementsPage } from '@pages/agreements/agreements'
import Chat from '@pages/chat/Chat'
import DocumentsPage from '@pages/documents/DocumentsPage'
import { useMutation, useQuery } from 'react-query'
import EmployeesExpense from '@pages/employeesExpense/EmployeesExpense'
import EmployeesTimeOffPage from '@pages/employeesTimeOff/EmployeesTimeOff'
import InvoicesPage from '@pages/invoices/InvoicesPage'
import OnboardingPage from '@pages/onboarding/OnboardingPage'
import OverviewPage from '@pages/overview/OverviewPage'
import ProfilePage from '@pages/profile/ProfilePage'
import { PaymentsPage } from '@pages/payments/payments'
import { DotWave } from '@uiball/loaders'
import { ErrorBoundary } from 'react-error-boundary'
import { Sidebar } from '@components/sidebar/sidebar'
import Payslips from '@pages/payslips/Payslips'
import { useBoolean } from 'usehooks-ts'
import AlertMFA from '@atoms/AlertMFA'
import { useAuth } from '../AuthProvider'
import {
  fetchPopUpMfa,
  fetchTermsOfServices,
  fetchWorker,
  postUserReport,
} from '../services/app.service'

const FaqPage = React.lazy(() => import('../pages/faq'))

const sideBarItems = ({ contract_type, compensation }, onboardingState) => {
  return [
    {
      title: '',
      children: [
        { name: 'Overview', icon: overview, url: 'overview', count: 0 },
      ],
    },
    {
      title: 'Requests',
      hidden: onboardingState !== 'PASSED',
      children: [
        {
          name: 'Time off',
          icon: timeOff,
          url: 'timeoff',
          hidden: compensation.contractor_wage_type !== 'monthly',
        },
        {
          name: 'Expenses',
          icon: expenses,
          url: 'expense',
        },
      ],
    },
    {
      title: 'Payment',
      hidden: onboardingState !== 'PASSED',
      children: [
        {
          name: 'Payments',
          icon: payments,
          url: 'payments',
          hidden: contract_type !== 'contractor',
        },
        {
          name: 'Invoices',
          icon: invoices,
          url: 'invoices',
          hidden: contract_type !== 'contractor',
        },
        {
          name: 'Payslips',
          url: 'payslips',
          icon: payslips,
          hidden: contract_type === 'contractor',
        },
      ],
    },
    {
      title: 'Documents',
      children: [
        { name: 'Documents', icon: requiredDocuments, url: 'documents' },
        { name: 'Agreements', icon: contractTemplates, url: 'agreements' },
      ],
    },
  ]
}

const handleReset = () => {
  window.location.href = '/'
}

function Pages() {
  const history = useHistory()
  const { logout } = useAuth()

  const [worker, setWorker] = useState(null)
  const [deactivateVisible, setDeactivateVisible] = useState(false)

  const contractsMyQuery = useQuery('worker', {
    queryFn: fetchWorker,
    onSuccess: setWorker,
  })

  const [{ data: profile, loading: profileLoading }, refetchProfile] =
    useAxiosCall({
      method: 'get',
      url: 'profile/me/',
    })
  const [{ data: countries, loading: countriesLoading }] = useAxiosCall({
    method: 'get',
    url: 'countries/?&limit=1000&offset=0',
  })
  const [{ data: countriesActive, loading: countriesActiveLoading }] =
    useAxiosCall({
      method: 'get',
      url: 'countries/?&limit=1000&offset=0&is_forbidden=false',
    })

  const [{ data: currencies, loading: currenciesLoading }] = useAxiosCall({
    method: 'get',
    url: `currencies/?&limit=1000&offset=0`,
  })

  const { isLoading: onBoardingStatusLoading, data: onBoardingStatus } =
    useQuery('onBoardingStatus', {
      queryFn: () => Api.get('/contracts/onboarding/').then((res) => res.data),
      onSuccess: (response) => {
        if (response.onboarding_state === 'NOT_PASSED') {
          history.push('/worker/onboarding')
        }
        if (
          response.onboarding_state === 'PASSED' ||
          response.onboarding_state === 'FILLED_ONBOARDING_DATA'
        ) {
          history.push('/worker/overview')
        }
      },
    })

  const userReport = useMutation('userReport', {
    mutationFn: (body) => postUserReport(body),
  })

  const contextProviderValue = useMemo(
    () => ({
      worker,
      setWorker,
      profile,
      refetchProfile,
      countries: countries ? countries.results : [],
      countriesActive: countriesActive ? countriesActive.results : [],
      currencies: currencies ? currencies.results : [],
    }),
    [worker, countries, profile, currencies, countriesActive]
  )
  const popupMFAQuery = useQuery('popupMFA', fetchPopUpMfa)
  const popupMFAOpen = useBoolean(true)

  const termsMutate = useMutation({
    mutationFn: () => fetchTermsOfServices(contractsMyQuery.data.id),
    onSuccess: () => {
      contractsMyQuery.refetch()
    },
  })

  useEffect(() => {
    userReport.mutate({
      event_name: 'active',
      metadata: {
        start_time: new Date(),
      },
    })
  }, [])

  if (
    onBoardingStatusLoading ||
    profileLoading ||
    contractsMyQuery.isLoading ||
    countriesLoading ||
    currenciesLoading ||
    countriesActiveLoading
  ) {
    return (
      <div className="d-flex w-100 vh-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <AppContext.Provider value={contextProviderValue}>
      <>
        <Route
          exact
          path="/worker/onboarding"
          render={() => (
            <OnboardingPage
              onboardingState={onBoardingStatus?.onboarding_state}
            />
          )}
        />
        {(onBoardingStatus?.onboarding_state === 'PASSED' ||
          onBoardingStatus?.onboarding_state === 'FILLED_ONBOARDING_DATA') && (
          <section className="pages">
            <Sidebar
              firstName={profile?.profile.first_name}
              lastName={profile?.profile.last_name}
              items={sideBarItems(
                contractsMyQuery?.data,
                onBoardingStatus?.onboarding_state
              )}
              profilePagePath="/worker/profile"
              onLogout={logout}
            />
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={handleReset}
            >
              <div>
                {popupMFAQuery?.data?.mfa_show_popup && popupMFAOpen.value && (
                  <AlertMFA onClose={popupMFAOpen.setFalse} />
                )}
                <div className="page-content-wrapper d-flex flex-column justify-content-center w-100">
                  {worker?.state === 'inactive' && (
                    <div
                      className="acc-deactivate d-flex"
                      onClick={() => setDeactivateVisible(true)}
                    >
                      <Icon icon={notificationNone} />
                      <Typography className="text_regular-normal__14 ml-2">
                        Your contract with {worker?.company?.name} has been
                        terminated.{' '}
                      </Typography>
                    </div>
                  )}
                  <div className="page-content ">
                    <Route
                      exact
                      path="/worker/overview"
                      render={() => (
                        <OverviewPage
                          onboardingState={onBoardingStatus?.onboarding_state}
                        />
                      )}
                    />
                    <Route exact path="/worker/chat" render={() => <Chat />} />
                    <Route
                      exact
                      path="/worker/invoices"
                      render={() => <InvoicesPage />}
                    />
                    <Route
                      exact
                      path="/worker/payslips"
                      render={() => <Payslips />}
                    />
                    <Route
                      exact
                      path="/worker/timeoff"
                      render={() => <EmployeesTimeOffPage />}
                    />
                    <Route
                      exact
                      path="/worker/expense"
                      render={() => <EmployeesExpense />}
                    />
                    <Route
                      exact
                      path="/worker/documents"
                      render={() => <DocumentsPage />}
                    />
                    <Route
                      exact
                      path="/worker/agreements"
                      render={() => <AgreementsPage />}
                    />
                    <Route
                      exact
                      path="/worker/payments"
                      render={() => <PaymentsPage />}
                    />

                    <Route
                      exact
                      path="/worker/profile"
                      render={() => <ProfilePage />}
                    />
                    <Route
                      exact
                      path="/worker/faq"
                      render={() => <FaqPage />}
                    />
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </section>
        )}
        <EditModal
          visible={deactivateVisible}
          footer={false}
          closeModal={() => setDeactivateVisible(false)}
        >
          <div className="d-flex flex-column justify-content-center px-3">
            <div className="d-flex justify-content-center mb-5">
              <img
                style={{ width: 'fit-content' }}
                src="/assets/img/Notification.png"
                alt="notification"
              />
            </div>
            <Typography className="heading_semibold__24 text-center">
              The account of {worker?.company?.name} company has been
              deactivated.
            </Typography>
            <Typography className="text_regular-normal__14 text-center mt-2">
              You will no longer receive any invoices and won’t be able to
              upload new documents on the platform. Meanwhile, you can still
              view your profile, personal documents and previous invoices.
            </Typography>
            <div className="d-flex justify-content-center">
              <Button
                priority="primary"
                size="small"
                className="mt-5"
                onClick={() => setDeactivateVisible(false)}
              >
                Ok
              </Button>
            </div>
          </div>
        </EditModal>
      </>
    </AppContext.Provider>
  )
}
export default Pages
