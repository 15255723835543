import { capitalizeFirstLetter, getCurrency, SplitNumber } from '@core/utils'
import moment from 'moment'

const getIdEmployee = (worker) =>
  worker
    ? worker.company.title.slice(0, 3).toUpperCase() +
      worker.profile.first_name[0].toUpperCase() +
      worker.profile.last_name[0].toUpperCase() +
      worker.id
    : null

const getAddress = (worker) => {
  const { profile } = worker
  return worker.contract_type === 'contractor'
    ? [
        {
          label: 'Billing Address',
          value: [
            profile.address?.living_country?.name || '',
            profile.address?.city,
            profile.address?.state,
            profile.address?.street_address,
            profile.address?.postal_code,
            profile.address?.address_line,
          ]
            .filter((v) => !!v)
            .join(', '),
        },
        {
          label: 'Home Address',
          value: [
            profile.home_address?.living_country?.name || '',
            profile.home_address?.city,
            profile.home_address?.state,
            profile.home_address?.street_address,
            profile.home_address?.postal_code,
            profile.home_address?.address_line,
          ]
            .filter((v) => !!v)
            .join(', '),
        },
      ]
    : [
        {
          label: 'Home Address',
          value: [
            profile.address?.living_country?.name || '',
            profile.address?.city,
            profile.address?.street_address,
            profile.address?.postal_code,
            profile.address?.address_line,
          ]
            .filter((v) => !!v)
            .join(', '),
        },
      ]
}

export const getWorkPermit = (worker) =>
  worker.work_permit
    ? [
        {
          label: 'Work permit status',
          value: worker.work_permit
            ? capitalizeFirstLetter(worker.work_permit.status)
            : '',
        },
        {
          label: 'Work permit expiry',
          value: worker.work_permit.expiration_date
            ? moment(worker.work_permit.expiration_date).format('DD MMM YYYY')
            : '-',
        },
      ]
    : []

export const getPersonalInformation = (profile, worker) => [
  {
    label: 'Name',
    value: `${profile.first_name} ${profile.last_name}`,
  },
  {
    label: 'ID',
    value: [getIdEmployee(worker)],
  },
  {
    label: 'Email',
    value: profile.email,
  },
  {
    label: 'Date of birth',
    value: profile.birth_date
      ? moment(profile.birth_date).format('DD MMM YYYY')
      : null,
  },
  {
    label: 'Phone number',
    value: profile.phone_number,
  },
  {
    label: 'Nationality',
    value: profile.nationality ? profile.nationality : '',
  },
  ...getAddress(worker),
  {
    label: 'Work permit required',
    value: [profile.needs_work_permit ? 'Yes' : 'No'],
  },
  ...getWorkPermit(worker),
]

const getJob = (data) =>
  data.contract_type === 'full_time_employee'
    ? [
        {
          label: 'Job Description',
          value: data?.job?.position_description || '',
        },
      ]
    : []

export const getJobDetailsInfo = (data) => [
  {
    label: 'Job Title',
    value: data?.job?.position || '',
  },
  ...getJob(data),
  {
    label: 'Department',
    value: data?.department || '',
  },
  {
    label: 'Annual gross salary',
    value: `${
      data.compensation?.currency?.sign ||
      data.compensation?.currency?.short_code
    } ${SplitNumber(data.compensation.yearly_gross_salary)}`,
    hidden: data.contract_type === 'contractor',
  },
  {
    label: 'Country number of salaries (per year)',
    value: data.working_country.yearly_salary_divider,
    hidden: data.contract_type === 'contractor',
  },
  {
    label: 'Monthly gross salary',
    value: `${
      data.compensation?.currency?.sign ||
      data.compensation?.currency?.short_code
    } ${SplitNumber(data.compensation.monthly_salary)}`,
    hidden: data.contract_type === 'contractor',
  },
  {
    label: 'Contract type',
    value:
      data?.contract_type === 'contractor'
        ? 'Contractor'
        : 'Full time employee',
  },
  {
    label: 'Contract is permanent',
    value: data.job.is_permanent ? 'Yes' : 'No',
  },
  {
    label: 'Start date',
    value: data?.job?.starts_at
      ? moment(data.job.starts_at).format('DD MMM YYYY')
      : '',
  },
  {
    label: 'End date',
    value:
      !data.job?.is_permanent && data?.job?.ends_at
        ? moment(data.job.ends_at).format('DD MMM YYYY')
        : '',
  },
]

const getWorkEquipment = (employee) =>
  employee?.benefit?.provides_work_equipment
    ? [
        {
          label: 'Work equipment specification',
          value: employee?.benefit.equipment_specification,
        },
        {
          label: 'Delivery date',
          value: employee?.benefit.delivered_at
            ? moment(employee.benefit.delivered_at).format('DD MMM YYYY')
            : null,
        },
      ]
    : []

export const getEmergencyContact = (contact) => [
  {
    label: 'Contact name',
    value: contact.name,
  },
  {
    label: 'Relationship',
    value: contact.relationship,
  },
  {
    label: 'Email',
    value: contact.email,
  },
  {
    label: 'Phone number',
    value: contact.phone_number,
  },
]

export const getCompensationInfo = (data) => [
  {
    label: 'Country',
    value: data?.profile?.address?.living_country?.name || '',
  },
  {
    label: 'Receiving currency',
    value: data?.compensation?.receiving_currency?.short_code,
  },
  {
    label: 'Contract rate',
    value: `${getCurrency(data.compensation?.currency)}
  ${SplitNumber(data.compensation.contractor_rate)} / ${
      data.compensation.contractor_wage_type
    }`,
  },
  {
    label: 'Payment frequency',
    value: data?.compensation?.contractor_payment_frequency,
  },
]
