import { Api } from '@api/Api'

export const updateProfile = async () => {
  const { data } = await Api.patch(`profile/`)
  return data
}

export const updateContractProfile = async ({ id, body }) => {
  const { data } = await Api.put(
    `contracts/${id}/change-receiving-currency/`,
    body
  )
  return data
}

export const getActiveHealthInsurance = async ({ ...params }) => {
  const { data } = await Api.get(`/contract-insurances/`, { params })
  return data
}
