import React from 'react'
import styled from 'styled-components'
import { getCurrency, formatDate, SplitNumber } from '@core/utils'

const Wrapper = styled.div`
  display: flex;
  font-family: 'Inter';
`
const Container = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  flex: 1;
`
const SalaryRequestInfo = styled.div`
  width: 40%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  font-size: 0.875rem;
`
const Table = styled.div``
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
`
const Cell = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0.375rem 0.5rem;
`
const Text = styled.div`
  padding: 0.5rem;
`
const AgreementInfo = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`
const Info = styled.div`
  width: 60%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
`
const Title = styled.h4`
  font-size: 18px;
  font-family: 'Manrope', sans-serif;
  color: #121212;
  font-weight: 600;
  margin-bottom: 1.5rem;
`
const Signers = styled.div``
const SignersLabel = styled.div`
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 13px;
`
const SignerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
const Signer = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  width: calc(50% - 4px);
`
const SignerName = styled.div`
  font-weight: 500;
  margin-bottom: 2px;
  color: #121212;
`
const SignerRole = styled.div`
  color: #878787;
`

const MoreInfo = styled.div`
  width: 40%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
`
const MoreInfoItem = styled.div`
  margin-bottom: 1rem;
`
const MoreInfoItemLabel = styled.div`
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 4px;
`
const MoreInfoItemTextPrimary = styled.div`
  margin-bottom: 4px;
`
const MoreInfoItemTextSecondary = styled.div`
  margin-bottom: 4px;
  color: #878787;
`

export default function AgreementDetail({ data }) {
  console.log(data)
  const { salary_change: salaryChange } = data
  return (
    <Wrapper>
      <Container>
        {salaryChange && (
          <SalaryRequestInfo>
            <Title>Salary Request</Title>
            <Table>
              <Row>
                <Cell>Current Salary</Cell>
                <Cell>
                  {`${getCurrency(
                    salaryChange?.contract?.compensation?.currency
                  )} ${SplitNumber(
                    salaryChange?.contract?.compensation?.yearly_gross_salary
                  )}`}
                </Cell>
              </Row>
              <Row>
                <Cell>Requested Salary</Cell>
                <Cell>
                  {`${getCurrency(salaryChange?.currency)} ${SplitNumber(
                    salaryChange?.amount
                  )}`}
                </Cell>
              </Row>
              <Row>
                <Cell>Effective Date</Cell>
                <Cell>{formatDate(salaryChange?.effective_date)}</Cell>
              </Row>
              {salaryChange?.position && (
                <>
                  <Row>
                    <Cell>New Job Title</Cell>
                    <Cell>{salaryChange?.position}</Cell>
                  </Row>
                  <Text>{salaryChange?.position_description}</Text>
                </>
              )}
            </Table>
          </SalaryRequestInfo>
        )}
        <AgreementInfo>
          <Info>
            <Title>{data?.title}</Title>
            <Signers>
              <SignersLabel>Signers</SignersLabel>
              <SignerList>
                {data?.signatures?.map(({ user, signer_role }) => (
                  <Signer key={user?.email}>
                    <SignerName>{user?.email}</SignerName>
                    <SignerRole>{signer_role}</SignerRole>
                  </Signer>
                ))}
              </SignerList>
            </Signers>
          </Info>
          <MoreInfo>
            <Title>More info</Title>
            <MoreInfoItem>
              <MoreInfoItemLabel>Sent by</MoreInfoItemLabel>
              <MoreInfoItemTextPrimary>
                {data?.send_by?.full_name}
              </MoreInfoItemTextPrimary>
              <MoreInfoItemTextSecondary>
                {data?.send_by?.email}
              </MoreInfoItemTextSecondary>
            </MoreInfoItem>
            <MoreInfoItem>
              <MoreInfoItemLabel>Agreement ID</MoreInfoItemLabel>
              <MoreInfoItemTextPrimary>{data?.id}</MoreInfoItemTextPrimary>
            </MoreInfoItem>
            <MoreInfoItem>
              <MoreInfoItemLabel>Message</MoreInfoItemLabel>
              <MoreInfoItemTextPrimary>{data?.subject}</MoreInfoItemTextPrimary>
              <MoreInfoItemTextSecondary>
                This will be used in email
              </MoreInfoItemTextSecondary>
            </MoreInfoItem>
          </MoreInfo>
        </AgreementInfo>
      </Container>
    </Wrapper>
  )
}
