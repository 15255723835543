import React from 'react'
import Typography from '@atoms/Typography/Typography'
import Button from '@atoms/Button/Button'
import List from '@atoms/List/List'
import { useApp } from '@core/context'
import useBoolean from '@core/hooks/useBoolean'
import { EditModalForm } from '@atoms/EditModal/EditModalForm'
import { useMutation } from 'react-query'

import { fetchUpdatePaymenyDetails } from '../../../../services/app.service'

import { PaymentDetailsForm } from './PaymentForm'

const list = ({
  account_number,
  bank_name,
  holder_name,
  additional_info,
  bic,
  iban,
  tax_id,
}) => [
  { label: 'Account number', value: account_number },
  { label: 'Account holder name', value: holder_name },
  { label: 'Bank Name', value: bank_name },
  { label: 'Additional information', value: additional_info },
  { label: 'BIC / SWIFT', value: bic },
  { label: 'IBAN', value: iban },
  { label: 'Tax ID', value: tax_id },
]

export const PaymentDetailsTab = () => {
  const { worker, setWorker } = useApp()

  const { value, toggle } = useBoolean(false)

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdatePaymenyDetails(id, body),
    onSuccess: (payment_details) => {
      setWorker({ ...worker, payment_details })
      toggle()
    },
  })

  const handleSave = (body) => {
    const updateData = {
      iban: body.iban.length > 0 ? body.iban : '',
      bic: body.bic.length > 0 ? body.bic : '',
      holder_name: body.holder_name.length > 0 ? body.holder_name : '',
      bank_name: body.bank_name.length > 0 ? body.bank_name : '',
      account_number: body.account_number.length > 0 ? body.account_number : '',
      additional_info:
        body.additional_info.length > 0 ? body.additional_info : '',
      tax_id: body.tax_id.length > 0 ? body.tax_id : '',
    }
    updateMutate.mutate({
      id: worker.payment_details.id,
      body: updateData,
    })
  }

  return (
    <>
      <div className="d-flex flex-column" style={{ maxWidth: 540 }}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">
            Payment Details
          </Typography>
          <Button priority="secondary" size="small" onClick={toggle}>
            Edit
          </Button>
        </div>
        <List lists={list(worker.payment_details)} />
      </div>

      {worker.payment_details && (
        <EditModalForm
          title="Payment Details"
          show={value}
          loading={updateMutate.isLoading}
          onHide={toggle}
        >
          <PaymentDetailsForm
            data={worker.payment_details}
            onSubmit={handleSave}
          />
        </EditModalForm>
      )}
    </>
  )
}
