import './InvoicesPage.scss'

import { Api } from '@api/Api'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { AppContext } from '@core/context'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'

import InvoiceModal from './InvoiceModal'
import { parseInvoice } from './parser'

export default function InvoicesPage() {
  const [displayInvoice, setDisplayInvoice] = React.useState(null)
  const [displayModal, setDisplayModal] = React.useState(false)
  const [page, setPage] = useState(1)

  const { worker } = useContext(AppContext)

  const { data, isLoading } = useQuery(
    ['invoices', page],
    () =>
      Api.get(`/invoices/?contract_id=${worker?.id}&page=${page}`).then(
        (res) => res.data
      ),
    {
      enabled: !!worker,
    }
  )

  const invoices = data ? data.results : []
  const fields = [
    {
      title: 'Customer',
      key: 'company.name',
      type: 'textMedium',
      maxWidth: 322,
      minWidth: 322,
      width: 322,
    },
    {
      title: 'Date',
      key: 'date',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Amount',
      key: 'amount',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Type',
      key: 'type',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status',
      maxWidth: 138,
      minWidth: 138,
      width: 138,
    },
  ]

  const onRowClick = (item) => {
    setDisplayInvoice(item)
    setDisplayModal(true)
  }

  const onCloseModalClick = () => {
    setDisplayModal(false)
  }

  return (
    <div className="invoices-page h-100">
      <PageTitle styleClass="mb-4"> Invoices </PageTitle>
      {displayModal && (
        <InvoiceModal
          invoice={displayInvoice}
          onCloseClick={onCloseModalClick}
        />
      )}
      <CustomTable
        page={page}
        pageSize={20}
        total={data?.count || 0}
        loading={isLoading}
        fields={fields}
        data={parseInvoice(invoices)}
        onRowClick={onRowClick}
        onPage={setPage}
      />
    </div>
  )
}
