/* eslint-disable no-shadow */
import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { setCurrencyChangeEvent } from '@core/utils'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { fetchUploadFile } from '../../../services/app.service'

export const CreateExpenseForm = ({ formId, currencies, onSubmit }) => {
  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const [doc, setDoc] = useState({
    file_uuid: null,
    isLoading: false,
    success: false,
    progress: 0,
  })

  const handleUpload = async (file) => {
    // ???
    const filesize = (file.size / 1024 / 1024).toFixed(4) // MB
    if (
      file.name !== 'item' &&
      typeof file.name !== 'undefined' &&
      filesize <= 3
    ) {
      const setProgress = (progress) => {
        setDoc((prev) => ({
          ...prev,
          isLoading: true,
          progress,
        }))
      }

      fetchUploadFile(file, setProgress)
        .then(({ file_uuid }) => {
          setDoc((prev) => ({
            ...prev,
            file_uuid,
            isLoading: false,
            success: true,
            progress: 0,
          }))

          // set receipt of each form
          setValue('fields', {
            ...getValues('fields'),
            [formId]: { ...getValues('fields')[formId], receipt: file_uuid },
          })
          clearErrors(`fields.${formId}.receipt`)
        })
        .catch(() => {
          setDoc((prev) => ({
            ...prev,
            isLoading: false,
            success: false,
            progress: 0,
          }))
        })
    } else {
      toast.error(
        'Your file size exceeds the maximum limit. Please upload a file no larger than 3 Mb.',
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        }
      )
    }
  }

  const handleRemoveFile = () => {
    setDoc({})
    // remove receipt of each form
    setValue('fields', {
      ...getValues('fields'),
      [formId]: { ...getValues('fields')[formId], receipt: '' },
    })

    setError(`fields.${formId}.receipt`, { message: 'Please upload a file' })
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column gap-2">
        <Controller
          control={control}
          name={`fields.${formId}.expense_date`}
          rules={{ required: 'Expense date is required' }}
          render={({ field }) => {
            return (
              <DateInput
                {...field}
                addText="Please indicate the date from the receipt"
                maxDate={new Date()}
                isRequired
                label="Expense Date"
              />
            )
          }}
        />
        {errors.fields?.[formId]?.expense_date && (
          <Typography className="text_regular__14 color_red">
            {errors.fields[formId].expense_date.message}
          </Typography>
        )}

        <div className="row">
          <Typography className="text_medium__14">
            Amount and currency <span className="text-danger ml-1">*</span>
          </Typography>
          <Typography className="text_light__12 color_text_300 mb-2">
            Please indicate the amount and the currency from the receipt
          </Typography>
          <div className="col-6">
            <Controller
              control={control}
              name={`fields.${formId}.amount`}
              rules={{
                required: 'Field is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) ||
                    'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  placeholder="Enter the amount"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.fields?.[formId]?.amount && (
              <Typography className="text_regular__14 color_red">
                {errors.fields[formId].amount.message}
              </Typography>
            )}
          </div>
          <div className="col-6">
            <div className="w-100 remo-form-input">
              <Controller
                control={control}
                name={`fields.${formId}.currency`}
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      options={currencies.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.fields?.[formId]?.currency && (
                <Typography className="text_regular__14 color_red">
                  {errors.fields[formId].currency.message}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <Controller
          control={control}
          name={`fields.${formId}.receipt`}
          rules={{ required: 'Please upload a file' }}
          render={({ field }) => (
            <UploadButton
              {...field}
              id={formId}
              addText={doc.description}
              isLoading={doc.isLoading}
              isSuccess={doc.success}
              isRequired
              label="Upload receipt"
              progress={doc.progress}
              onRemove={handleRemoveFile}
              onChange={handleUpload}
              accept="application/pdf, application/msword, .png, .jpg, .jpeg, .doc,.docx"
            />
          )}
        />
        {errors.fields?.[formId]?.receipt && (
          <Typography className="text_regular__14 color_red">
            {errors.fields[formId].receipt.message}
          </Typography>
        )}
      </div>
    </form>
  )
}
