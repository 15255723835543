import './style.scss'

import React from 'react'

export default function Status({ status }) {
  if (!status) return <div className="status status_default">No Status</div>
  switch (status.toLowerCase()) {
    case 'in_progress':
      return <div className="status status_default">In Progress</div>
    case 'timeoff_in_progress':
      return <div className="status status_default">Pending</div>
    case 'pending':
      return <div className="status status_default">Pending</div>
    case 'awaiting_payment':
      return <div className="status status_warning">Awaiting payment</div>
    case 'complete':
      return <div className="status status_success">Complete</div>
    case 'completed':
      return <div className="status status_success">Complete</div>
    case 'app_submitted':
      return <div className="status status_success">Application submitted</div>
    case 'app_accepted':
      return <div className="status status_success">Active</div>
    case 'active':
      return <div className="status status_success">Active</div>
    case 'onboarding':
      return <div className="status status_success">Onboarding</div>
    case 'created':
      return <div className="status status_success">Created</div>
    case 'generating':
      return <div className="status status_disabled">Generating</div>
    case 'confirming':
      return <div className="status status_default">Confirming</div>
    case 'paid':
      return <div className="status status_success">Paid</div>
    case 'failed':
      return <div className="status status_error">Failed</div>
    case 'cancelled':
      return <div className="status status_warning">Cancelled</div>
    case 'approved':
      return <div className="status status_success">Approved</div>
    case 'canceled':
      return <div className="status status_warning">Cancelled</div>
    case 'refunded':
      return <div className="status status_default">Refunded</div>
    case 'in_active':
      return <div className="status status_default">Inactive</div>
    case 'inactive':
      return <div className="status status_default">Inactive</div>
    case 'need_changes_from_partner':
      return <div className="status status_error">Need change from partner</div>
    case 'approved_by_partner':
      return <div className="status status_success">Approved by partner</div>
    case 'approved_by_admin':
      return <div className="status status_success">Approved by admin</div>
    case 'generating_invoice':
      return <div className="status status_default">Generating Invoice</div>
    case 'approved_by_client':
      return <div className="status status_success">Approved by client</div>
    case 'invoice_created':
      return <div className="status status_generate">Invoice created</div>
    case 'signed':
      return <div className="status status_success">Signed</div>
    case 'not_signed':
      return <div className="status status_default">Not Signed</div>
    case 'uploaded':
      return <div className="status status_success">Uploaded</div>
    case 'salary_not_signed':
      return <div className="status status_default">Pending signature</div>
    case 'need_changes_from_admin':
      return <div className="status status_error">Need change from admin</div>
    case 'rejected':
      return <div className="status status_error">Rejected</div>
    case 'declined':
      return <div className="status status_error">Rejected</div>
    case 'review':
      return <div className="status status_default">Under review</div>
    default:
      return <div>{status}</div>
  }
}
