import Icon from '@atoms/Icon/Icon'
import { errorIcon, successIcon } from '@core/icons/icons'
import React from 'react'
import styled, { css } from 'styled-components'

const Root = styled.div`
  border-bottom: 2px solid #f3f3f3;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:last-child {
    border: 0;
  }

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.4;
        pointer-events: none;
        cursor: default;
      `
    }
    return ''
  }}
`

const Content = styled.div`
  flex: 1;
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #101828;
`
const Description = styled.div`
  font-size: 0.875rem;
  color: #878787;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

const ActionsPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #878787;
`

const getActions = (
  disabled,
  disabledText,
  actions,
  actionsPlaceholderText
) => {
  if (disabled) return <ActionsPlaceholder>{disabledText}</ActionsPlaceholder>
  if (actionsPlaceholderText)
    return <ActionsPlaceholder>{actionsPlaceholderText}</ActionsPlaceholder>
  if (actions && actions.length)
    return <Actions>{actions.map((item) => item)}</Actions>
  return null
}

const getIcon = (completed, rejected) => {
  if (completed) return <Icon icon={successIcon} />
  if (rejected) return <Icon icon={errorIcon} />
  return <div style={{ width: 20, height: 20 }}></div>
}

export const CardStepperStep = ({
  title,
  description,
  styleClass,
  actions,
  actionsPlaceholderText,
  disabledText,
  disabled = false,
  completed = false,
  started = false,
  rejected = false,
}) => (
  <Root disabled={disabled} className={styleClass}>
    {getIcon(completed, rejected)}
    <Content>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Content>
    {getActions(disabled, disabledText, actions, actionsPlaceholderText)}
  </Root>
)
