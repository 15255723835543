/* eslint-disable react/no-array-index-key */
import React from 'react'
import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'

export default function List({ lists }) {
  return (
    <div className="list">
      {lists.map(
        (list, index) =>
          (list?.value?.length > 0 || list?.value) && (
            <div
              className={classNames('list__item', {
                'd-none': list.hidden,
              })}
              key={list.label + index}
            >
              <Typography className="text_regular-normal__14 color_black_light mb-1">
                {list.label}
              </Typography>
              <Typography className="text_regular-normal__14 color_black mb-3">
                {list.value}
              </Typography>
              <div
                style={{
                  width: '100%',
                  height: 1,
                  background: '#F3F3F3',
                  marginBottom: 16,
                }}
              />
            </div>
          )
      )}
    </div>
  )
}
