import './style.scss'

import classNames from 'classnames'
import React, { forwardRef, useMemo } from 'react'
import SelectField from 'react-select'

export default forwardRef((props, ref) => {
  const {
    label,
    options,
    name,
    id,
    styleClass,
    isMulti,
    onChange,
    value,
    isDisabled,
    isRequired,
    ...rest
  } = props
  const selectedOption = useMemo(
    () =>
      isMulti
        ? options?.filter((option) => value?.some((v) => v === option.value))
        : options?.find(
            (option) => option.value === value || option.value === value?.value
          ),
    [options, value, isMulti]
  )
  return (
    <div className={classNames(['select', styleClass])}>
      {label && (
        <label className="text_medium__14 remo-form-label" htmlFor={id}>
          {label} {isRequired && <span className="text-danger ml-1">*</span>}
        </label>
      )}
      {options && (
        <div className="select">
          <SelectField
            className="w-100 react-select"
            classNamePrefix="react-select"
            name={name}
            ref={ref}
            minMenuHeight={200}
            maxMenuHeight={220}
            id={id}
            isMulti={isMulti}
            isDisabled={isDisabled}
            defaultValue={isDisabled ? options[0] : undefined}
            onChange={onChange}
            value={selectedOption || ''}
            options={options}
            {...rest}
          />
        </div>
      )}
    </div>
  )
})
