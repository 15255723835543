import { Api } from '@api/Api'

export const fetchTimeOff = async (statusTimeOff) => {
  const { data } = await Api.get(
    `contracts/absences/?limit=1000&offset=0${statusTimeOff}`
  )

  return data
}

export const createTimeOff = async (data) =>
  Api.post(`contracts/absences/create/`, data)
export const calculateDayOff = async (data) => {
  return Api.get(
    `contracts/absences/calculate-day-off/?start_date=${
      data.start_date
    }&country=${data.country}${
      data.end_date ? `&end_date=${data.end_date}` : ''
    }${
      data.start_date_is_half_day
        ? `&start_date_is_half_day=${data.start_date_is_half_day}`
        : ''
    }${
      data.end_date_is_half_day
        ? `&end_date_is_half_day=${data.end_date_is_half_day}`
        : ''
    }`
  )
}
export const cancelTimeOff = async (body) =>
  Api.post(`contracts/absences/${body.id}/status-update/`, { ...body.data })
export const updateTimeOff = async (body) =>
  Api.patch(`contracts/absences/${body.id}/update/`, { ...body.data })
