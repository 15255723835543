import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(15, 15, 16, 0.04),
    0px 2px 4px rgba(15, 15, 16, 0.04);
  margin-bottom: 1rem;
`

export const CardStepper = ({ children }) => <Root>{children}</Root>
