import Icon from '@atoms/Icon/Icon'
import { connect, dots, support, user } from '@core/icons/icons'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import styles from './sidebar.module.scss'

export const Sidebar = ({
  firstName,
  lastName,
  items,
  profilePagePath,
  onLogout,
}) => {
  const isProfileRoute = useRouteMatch({ path: profilePagePath })
  const menuRef = useRef()
  const menu = useBoolean(false)
  const history = useHistory()

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && menuRef.current.contains(e.target)) {
        return
      }
      menu.setFalse()
    }

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('close', handler)
    }
  }, [menu])

  return (
    <div className={styles.root}>
      <div className={styles.links}>
        <div className={styles.logo}>
          <img src="/assets/icons/logo.svg" alt="logo" />
        </div>

        {items
          .filter(({ hidden }) => !hidden)
          .map(({ title, children }) => (
            <div key={v4()} className={styles.section}>
              {title && <div className={styles.section__title}>{title}</div>}

              {children
                .filter(({ hidden }) => !hidden)
                .map(({ url, icon, count, name }) => (
                  <NavLink
                    key={v4()}
                    to={`/worker/${url}`}
                    className={styles.section__item}
                    activeClassName={styles.section__item_active}
                  >
                    <div>
                      <Icon icon={icon} />
                    </div>

                    <div title={name}>{name}</div>
                    {!!count && (
                      <div className="flex-grow-1 d-flex justify-content-end">
                        <div className={styles['section__item-badge']}>
                          {count}
                        </div>
                      </div>
                    )}
                  </NavLink>
                ))}
            </div>
          ))}

        <div className={classNames([styles.section, styles['footer-section']])}>
          <NavLink
            to="/worker/faq"
            activeClassName={styles.section__item_active}
            className={styles.section__item}
          >
            <div>
              <Icon icon={support} />
            </div>
            <div>Support</div>
          </NavLink>
          <NavLink
            to="/worker/chat"
            activeClassName={styles.section__item_active}
            className={styles.section__item}
          >
            <div>
              <Icon icon={connect} />
            </div>
            <div>Connect</div>
          </NavLink>
        </div>
      </div>

      <div className={styles['profile-section']}>
        <div
          className="d-flex align-items-center"
          ref={menuRef}
          role="button"
          aria-hidden
          onClick={menu.toggle}
        >
          <div className={classNames(styles['profile-section__item'])}>
            <div
              className={classNames({
                [styles['profile-section__item_active']]: isProfileRoute,
              })}
            >
              <Icon icon={user} />
            </div>
            <div
              className={classNames([styles['profile-section__item-label']], {
                [styles['profile-section__item_active']]: isProfileRoute,
              })}
            >{`${firstName} ${lastName}`}</div>
          </div>

          <div style={{ paddingRight: 12 }}>
            <Icon icon={dots} />
          </div>
        </div>
        <div
          className={classNames([styles['sidebar-menu']], {
            'd-none': !menu.value,
          })}
        >
          <div
            className={classNames([styles['sidebar-menu__item']])}
            role="button"
            aria-hidden
            onClick={() => {
              history.push('/worker/profile')
            }}
          >
            Account preferences
          </div>
          <div
            className={classNames([styles['sidebar-menu__item']])}
            role="button"
            aria-hidden
            onClick={onLogout}
          >
            Log out
          </div>
        </div>
      </div>
    </div>
  )
}
