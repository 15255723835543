/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import './style.scss'

export default forwardRef((props, ref) => {
  const { id, style, label, isRequired, ...rest } = props

  return (
    <div className="textArea">
      <label
        className="text_medium__14 remo-form-label"
        htmlFor={id}
        style={style}
      >
        {label} {isRequired && <span className="text-danger ml-1">*</span>}
      </label>
      <textarea ref={ref} rows="5" style={{ ...style }} {...rest} />
    </div>
  )
})
