import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { downloadCloud } from '@core/icons/icons'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;

  .dropdown-menu {
    background: #fff;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
      0px 4px 6px -2px rgb(16 24 40 / 3%);
  }
  .dropdown-item {
    font-weight: 500;
    font-size: 13px;
  }
`

const PayslipsEmployeeActions = ({ item }) => {
  const onDownloadFile = () => {
    window.open(item.original.file, '_blank')
  }
  return (
    <Container>
      {item.original.file && (
        <div className="d-flex align-items-center">
          <Button
            priority="secondary"
            style={{ height: '32px' }}
            size="small"
            className="px-2 pl-0 mr-2"
            onClick={onDownloadFile}
          >
            <Icon fill="none" icon={downloadCloud} />
          </Button>
        </div>
      )}
    </Container>
  )
}

export default PayslipsEmployeeActions
