import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import Button from '@atoms/Button/Button'
import { useApp } from '@core/context'
import TextArea from '@atoms/TextArea/TextArea'
import RadioButton from '@atoms/RadioButton/RadioButton'
import { Alert } from 'react-bootstrap'
import Icon from '@atoms/Icon/Icon'
import { infoIcon } from '@core/icons/icons'
import styled from 'styled-components'

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
`

const StyledAlertText = styled.div`
  display: flex;
  padding-right: 1rem;
  font-size: 13px;
  color: #121212;
`

export default ({ loading, onFinish, livingCountryId }) => {
  const { worker, countries, currencies } = useApp()
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account_number: worker?.payment_details?.account_number || null,
      holder_name: worker?.payment_details?.holder_name || null,
      bank_name: worker?.payment_details?.bank_name || null,
      additional_info: worker?.payment_details?.additional_info || null,
      bic: worker?.payment_details?.bic || null,
      tax_id: worker?.payment_details?.tax_id || null,
      iban: worker?.payment_details?.iban || null,
      receiving_currency: worker?.payment_details?.receiving_currency || null,
    },
  })

  useEffect(() => {
    resetField('receiving_currency')
  }, [livingCountryId])

  const submit = (formValues) => {
    const payload = {
      payment_details: { ...formValues },
      receiving_currency: +formValues.receiving_currency,
    }

    if (formValues.tax_id === '') {
      delete payload.payment_details.tax_id
    }

    if (formValues.iban === '') {
      delete payload.payment_details.iban
    }

    if (payload.payment_details.receiving_currency) {
      delete payload.payment_details.receiving_currency
    }

    onFinish(payload)
  }

  const getReceivingCurrencyOptions = () => {
    const defaultCurrencyShortCode = 'USD'
    const country = countries.find((item) => item.id === livingCountryId)
    const localCurrency = country?.currency
    const usdCurrency = currencies.find(
      (item) => item.short_code === defaultCurrencyShortCode
    )

    return [
      {
        id: 1,
        text: defaultCurrencyShortCode,
        value: usdCurrency?.id,
      },
      {
        id: 2,
        text: `${localCurrency?.short_code || ''} (local currency)`,
        value: localCurrency?.id,
      },
    ]
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <RadioButton
            {...register('receiving_currency', {
              required: 'Field is required',
            })}
            label="Receiving currency"
            options={getReceivingCurrencyOptions()}
          />
          {errors.receiving_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.receiving_currency.message}
            </Typography>
          )}
        </div>
        <StyledAlert>
          <StyledAlertText>
            <div className="mr-2">
              <Icon icon={infoIcon} />
            </div>
            <Typography className="">
              This is the currency in which you will receive the money and the
              invoice. Make sure your bank can receive the currency you pick.
            </Typography>
          </StyledAlertText>
        </StyledAlert>
        <StyledAlert>
          <StyledAlertText>
            <div className="mr-2">
              <Icon icon={infoIcon} />
            </div>
            <Typography className="">
              Usually, when receiving in USD, there could be SWIFT interbank
              charges (20-90 USD). We are not responsible for these fees.
            </Typography>
          </StyledAlertText>
        </StyledAlert>
        <div className="remo-form-input">
          <Input
            {...register('account_number', {
              required: 'Account number is required',
            })}
            type="text"
            label="Account Number"
            placeholder="Enter your account number"
            isRequired
          />
          {errors.account_number && (
            <Typography className="text_regular__14 color_red">
              {errors.account_number.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('holder_name', {
              required: 'Account holder name is required',
            })}
            type="text"
            label="Account holder name"
            placeholder="Enter your name"
            isRequired
          />
          {errors.holder_name && (
            <Typography className="text_regular__14 color_red">
              {errors.holder_name.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('bank_name', {
              required: 'Bank name is required',
            })}
            type="text"
            label="Bank Name"
            placeholder="Enter bank name"
            isRequired
          />
          {errors.bank_name && (
            <Typography className="text_regular__14 color_red">
              {errors.bank_name.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <TextArea
            {...register('additional_info', {
              required: 'Additional information is required',
            })}
            maxLength={4000}
            label="Additional information"
            placeholder="Please, provide your country specific bank details (sort code/routing number and others)"
            isRequired
          />

          {errors.additional_info && (
            <Typography className="text_regular__14 color_red">
              {errors.additional_info.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('bic')}
            type="text"
            label="BIC / SWIFT"
            placeholder="Enter your valid BIC / SWIFT Identification Code"
          />
          {errors.bic && (
            <Typography className="text_regular__14 color_red">
              {errors.bic.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Input
            {...register('iban')}
            type="text"
            label="IBAN / Routing Number / Branch Number"
            placeholder="Enter your valid IBAN / routing number / branch number"
          />
          {errors.iban && (
            <Typography className="text_regular__14 color_red">
              {errors.iban.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('tax_id')}
            type="text"
            label="Tax ID"
            placeholder="Enter your Tax ID"
          />
          {errors.tax_id && (
            <Typography className="text_regular__14 color_red">
              {errors.tax_id.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button
          type="submit"
          className="align-self-end"
          loading={loading}
          disabled={loading}
        >
          Finish
        </Button>
      </div>
    </form>
  )
}
