import { Api } from '@api/Api'

export const getContractEmergencyContact = async (id, user_id) => {
  const { data } = await Api.get(`user/emergency-contacts/?user_id=${user_id}`)

  return data
}

export const patchEmergencyContact = async (id, data) =>
  Api.put(`user/emergency-contacts/${id}/update/`, data)

export const getContractsPaidLeaves = async (id) => {
  const { data } = await Api.get(`contracts/${id}/paid-leaves/`)
  return data
}

export const updateContractWorkPermit = async (id, payload) => {
  const { data } = await Api.put(`contracts/${id}/work-permit/update/`, payload)
  return data
}
