/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './style.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { CloseIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom/cjs/react-dom.production.min'

export default function ModalSide({
  wrapperStyle,
  className,
  title,
  isFullScreen,
  footer = true,
  okText,
  onOk,
  okButtonProps = { loading: false },
  onClose,
  isFetching = false,
  okButtonID = undefined,
  ...rest
}) {
  const renderContent = () => (
    <div className="modalSideWrapper" onClick={onClose} style={wrapperStyle}>
      <div
        {...rest}
        onClick={(e) => e.stopPropagation()}
        className={classNames([
          'modalSideBox',
          className,
          isFullScreen && 'modalBox_full',
        ])}
      >
        <div className="modalSideHeader heading_semibold__24">
          {title}
          <Icon icon={CloseIcon} onClick={onClose} />
        </div>
        <div className="modalSideContent">{rest.children}</div>
        {footer && (
          <div className="modalSideFooter">
            <Button priority="secondary" size="small" onClick={onClose}>
              Close
            </Button>
            {(okText && (
              <Button
                size="small"
                onClick={onOk}
                disabled={okButtonProps.loading || okButtonProps.disabled}
                loading={okButtonProps.loading}
                {...okButtonProps}
              >
                {okText}
              </Button>
            )) ||
              null}
          </div>
        )}
      </div>
    </div>
  )

  return createPortal(renderContent(), document.body)
}
