import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'

export default ({ onNext }) => {
  const { worker } = useApp()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      living_country: worker?.profile?.address?.living_country?.id || '',
      street_address: worker?.profile?.address?.street_address || '',
      address_line: worker?.profile?.address?.address_line || '',
      city: worker?.profile?.address?.city || '',
      postal_code: worker?.profile?.address?.postal_code || '',
    },
  })

  const { countries } = useApp()

  const submit = (formValues) => {
    const payload = {
      ...formValues,
      living_country: formValues.living_country.value,
    }

    if (formValues.address_line === '') {
      delete payload.address_line
    }

    onNext(payload)
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <Controller
            control={control}
            name="living_country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label="Country"
                  options={countries.map((v) => ({
                    value: v.id,
                    label: v.name,
                  }))}
                />
              )
            }}
          />
          {errors.living_country && (
            <Typography className="text_regular__14 color_red">
              {errors.living_country.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('street_address', {
              required: 'Full legal name is required',
            })}
            type="text"
            label="Address"
            placeholder="Enter your address"
          />
          {errors.street_address && (
            <Typography className="text_regular__14 color_red">
              {errors.street_address.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('address_line')}
            type="text"
            label="Address line 2 (Optional)"
            placeholder="Enter your address complement"
          />
          {errors.address_line && (
            <Typography className="text_regular__14 color_red">
              {errors.address_line.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('city')}
            type="text"
            label="City"
            placeholder="Enter your city"
          />
          {errors.city && (
            <Typography className="text_regular__14 color_red">
              {errors.city.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('postal_code', {
              required: 'Field is required',
            })}
            type="text"
            label="Postal code"
            placeholder="Enter your zip or postal code"
          />
          {errors.postal_code && (
            <Typography className="text_regular__14 color_red">
              {errors.postal_code.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
