import classNames from 'classnames'
import React, { forwardRef } from 'react'
import PhoneInputField from 'react-phone-number-input/input'
import './style.scss'

export default forwardRef((props, ref) => {
  const { label, styleClass, id, isRequired, ...rest } = props
  return (
    <div className={classNames(['phone_number', styleClass])}>
      {label && (
        <label className="text_medium__14 remo-form-label" htmlFor={id}>
          {label} {isRequired && <span className="text-danger ml-1">*</span>}
        </label>
      )}
      <div className="input-wrapper d-flex align-items-center">
        <PhoneInputField ref={ref} label={label} {...rest} />
      </div>
    </div>
  )
})
