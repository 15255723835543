import React from 'react'
import styled from 'styled-components'
import { DotWave } from '@uiball/loaders'

const AbsoulteDIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ffffffd4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  position: relative;
`

export const AbsoulteSpinner = ({ children, spinner, isFetching }) => {
  if (!isFetching) {
    return <div>{children}</div>
  }

  return (
    <Wrapper>
      {children}
      <AbsoulteDIV>
        {spinner || <DotWave size={48} speed={1} color="black" />}
      </AbsoulteDIV>
    </Wrapper>
  )
}
