/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import InfiniteScroll from 'react-infinite-scroll-component'
import classNames from 'classnames'
import Typography from '@atoms/Typography/Typography'
import TextArea from '@atoms/TextArea/TextArea'
import Icon from '@atoms/Icon/Icon'
import {
  attachFile,
  send,
  addMember,
  chatDownloadFile,
} from '@core/icons/icons'
import Button from '@atoms/Button/Button'
import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react'
import { AppContext } from '@core/context'
import { useAxiosCall } from '@api/Api'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'

export default function ChatList({
  messages,
  setMessagePage,
  setMessages,
  currentThread,
  messagePage,
  setVisibleAbout,
  activeChat,
  onClickAddMember,
  onClickResolve,
  isOwner = false,
}) {
  const { profile } = useContext(AppContext)
  const inputRef = useRef(null)
  const [message, setMessage] = useState()
  const [messageMore, setMessageMore] = useState(false)

  const [, postMessage] = useAxiosCall(
    {
      method: 'post',
      url: `threads/${currentThread?.id}/messages/create/`,
    },
    {
      manual: true,
    }
  )
  const [, postMessageDocument] = useAxiosCall(
    {
      method: 'post',
      url: `threads/${currentThread?.id}/documents/create/`,
    },
    {
      manual: true,
    }
  )

  const [{ loading: loadingMessage }, getThreadMessage] = useAxiosCall(
    {
      url: `threads/${currentThread?.id}/messages/?limit=16`,
    },
    {
      manual: true,
    }
  )

  const loadFunc = useCallback(() => {
    getThreadMessage({
      url: `threads/${currentThread?.id}/messages/?limit=16&offset=${
        messagePage + 16
      }`,
    }).then((res) => {
      const reverseMessage = res.data.results.reverse()
      setMessages((prevState) => [...reverseMessage, ...prevState])
      setMessagePage((prevState) => prevState + 16)
      setMessageMore(!!res.data.next)
    })
  }, [messagePage, currentThread])

  const onChangeMessage = useCallback((e) => {
    setMessage(e.target.value)
  }, [])

  const onSendMessage = useCallback(
    (e) => {
      if (
        e.keyCode === 13 &&
        e.shiftKey === false &&
        message &&
        message.trim().length > 0
      ) {
        e.preventDefault()
        postMessage({
          data: { message_type: 'TEXT', content: message },
        }).then(() => {
          getThreadMessage().then((res) => {
            const reverseMessage = res.data.results.reverse()
            setMessages(reverseMessage)
            setMessagePage(0)
            setMessageMore(!!res.data.next)
          })
          setMessage('')
        })
      }
    },
    [currentThread, message]
  )

  const onClickSendMessage = useCallback(() => {
    if (message && message.trim().length > 0) {
      postMessage({
        data: { message_type: 'TEXT', content: message },
      }).then(() => {
        getThreadMessage().then((res) => {
          const reverseMessage = res.data.results.reverse()
          setMessages(reverseMessage)
          setMessagePage(0)
          setMessageMore(!!res.data.next)
        })
        setMessage('')
      })
    }
  }, [currentThread, message])

  const onChangeFile = (event) => {
    const dataFile = new FormData()
    const file = event.target.files[0]
    if (file) {
      dataFile.append('document_meta', file)
      postMessageDocument({ data: dataFile }).then(() => {
        getThreadMessage().then((res) => {
          const reverseMessage = res.data.results.reverse()
          setMessages(reverseMessage)
          setMessagePage(0)
          setMessageMore(!!res.data.next)
        })
        setMessage('')
      })
    }
  }

  useEffect(() => {
    if (currentThread) {
      getThreadMessage().then((res) => {
        const reverseMessage = res.data.results.reverse()
        setMessages(reverseMessage)
        setMessageMore(!!res.data.next)
      })
    }
  }, [currentThread])

  const onClickFile = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const renderMessage = useCallback((msg) => {
    switch (msg.message_type) {
      case 'DOCUMENT':
        return (
          <div className="d-flex">
            <a
              target="_blank"
              className="mr-2"
              style={{ textDecoration: 'none' }}
              href={msg.thread_document}
              rel="noreferrer"
            >
              <Icon
                fill="white"
                icon={chatDownloadFile}
                style={{ cursor: 'pointer' }}
              />
            </a>
            <div className="d-flex flex-column pt-1 w-100">
              <Typography className="text_regular__14">
                {msg.content}
              </Typography>
              <Typography className="text_light__12 w-100 text-right">
                {moment(msg.created_at).format('HH:mm')}
              </Typography>
            </div>
          </div>
        )
      case 'NEW_MEMBER':
        return (
          <div className="d-flex">
            <div className="msg_line">
              <Typography
                as="span"
                className="text_medium__14 color_text_200"
                style={{ background: '#fff', padding: '0 10px' }}
              >
                {msg.content}{' '}
                <span className="text_regular__14 color_text_300">
                  was added to the ticket
                </span>
              </Typography>
            </div>
          </div>
        )
      default:
        return (
          <div className="d-flex flex-column">
            <Typography className="text_regular__14">{msg.content}</Typography>
            <Typography className="text_light__12 w-100 text-right">
              {moment(msg.created_at).format('HH:mm')}
            </Typography>
          </div>
        )
    }
  }, [])
  return loadingMessage && messages.length === 0 ? (
    <div
      className="d-flex w-100 h-100 align-items-center justify-content-center"
      style={{ height: 200 }}
    >
      <DotWave size={40} speed={1} color="black" />
    </div>
  ) : (
    <div className="chat_wrapper d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <div className="chat_header d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <Typography className="heading_semibold__20">
              {currentThread.topic}
            </Typography>
          </div>
          <div className="d-flex align-items-center">
            <Button
              priority="secondary"
              size="small"
              onClick={onClickAddMember}
            >
              <img src="/assets/icons/add-member.svg" alt="add-member" />
            </Button>
            <Button
              className="ml-2"
              priority="secondary"
              size="small"
              onClick={() => setVisibleAbout(true)}
            >
              <img src="/assets/icons/info.svg" alt="info" />
            </Button>
            {isOwner && (
              <Button
                className="ml-2"
                priority="secondary"
                size="small"
                title={activeChat ? 'close' : 'open'}
                onClick={onClickResolve}
              >
                {activeChat ? (
                  <img src="/assets/icons/resolve.svg" alt="resolve" />
                ) : (
                  <img src="/assets/icons/unresolve.svg" alt="unresolve" />
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column chat_messages">
        <div id="message_content" className="message_content">
          <InfiniteScroll
            dataLength={messages.length}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            next={loadFunc}
            hasMore={messageMore}
            inverse
            scrollableTarget="message_content"
            loader={
              <div
                key={0}
                className="d-flex w-100 loader align-items-center justify-content-center"
                style={{ height: 80 }}
              >
                <DotWave size={32} speed={1} color="black" />
              </div>
            }
          >
            <div>
              {messages?.map((msg, index) => (
                <div key={`${msg.content + index}`}>
                  <div
                    className={classNames(
                      'mb-3 message',
                      msg.author.id !== profile.id
                        ? 'chat_message'
                        : 'chat_message__user',
                      msg.message_type === 'NEW_MEMBER' &&
                        'chat_message__member'
                    )}
                  >
                    {msg.message_type !== 'NEW_MEMBER' &&
                      msg.author.id !== profile.id && (
                        <Typography className="text_medium__14 mb-2">
                          {msg.author.full_name}
                        </Typography>
                      )}
                    {renderMessage(msg)}
                  </div>
                  <div className="clearfix" />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
        <div className="d-flex position-relative justify-content-center">
          {activeChat ? (
            <div className="chat_textarea w-100">
              <TextArea
                onKeyDown={onSendMessage}
                rows={3}
                value={message}
                placeholder="Write a message"
                style={{
                  resize: 'none',
                }}
                onChange={onChangeMessage}
              />
              <div className="chat-file d-flex align-items-center justify-content-between">
                <Button
                  style={{ padding: '8px', minWidth: 40 }}
                  priority="secondary"
                  size="small"
                  onClick={onClickFile}
                >
                  <Icon
                    fill="black"
                    icon={attachFile}
                    style={{ cursor: 'pointer' }}
                  />
                </Button>
                <input
                  type="file"
                  className="d-none"
                  ref={inputRef}
                  onChange={onChangeFile}
                  onClick={(e) => {
                    e.target.value = null
                  }}
                />
                <Button priority="primary" size="small">
                  <Icon
                    icon={send}
                    onClick={onClickSendMessage}
                    style={{ cursor: 'pointer' }}
                  />
                </Button>
              </div>
            </div>
          ) : (
            <Typography className="text_medium__14 color_text_300 my-3 text-center">
              This ticket has been marked as closed
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}
