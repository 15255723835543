import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import DateInput from '@atoms/DateInput/DateInput'
import Input from '@atoms/Input/Input'
import Button from '@atoms/Button/Button'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'
import Typography from '@atoms/Typography/Typography'
import moment from 'moment'
import { useApp } from '@core/context'
import Checkbox from '@atoms/Checkbox/Checkbox'

export default ({ onNext }) => {
  const { worker } = useApp()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      full_name: `${worker?.profile?.first_name} ${worker?.profile?.last_name}`,
      birth_date: worker?.profile?.birth_date || '',
      phone_number: worker?.profile?.phone_number || '',

      terms: false,
      privacy: false,
    },
  })

  const submit = (formValues) => {
    onNext({
      profile: {
        ...formValues,
        birth_date: moment(formValues.birth_date).format('YYYY-MM-DD'),
      },
    })
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <Input
            {...register('full_name', {
              required: 'Full legal name is required',
            })}
            type="text"
            label="Full legal name"
            placeholder="Enter your legal name"
            isRequired
          />
          {errors.full_name && (
            <Typography className="text_regular__14 color_red">
              {errors.full_name.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="birth_date"
            rules={{ required: 'Birthdate is required' }}
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  maxDate={new Date()}
                  label="Birthdate"
                  isRequired
                />
              )
            }}
          />
          {errors.birth_date && (
            <Typography className="text_regular__14 color_red">
              {errors.birth_date.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="phone_number"
            rules={{
              required: 'Phone number is required',
            }}
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  international
                  withCountryCallingCode
                  label="Phone number"
                  placeholder="Enter phone number"
                  isRequired
                />
              )
            }}
          />
          {errors.phone_number && (
            <Typography className="text_regular__14 color_red">
              {errors.phone_number.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Checkbox
            id="terms-check"
            {...register('terms', {
              required: 'Please read and accept the Terms of use to continue',
            })}
            label={
              <span>
                I agree to the
                <a
                  className="ml-1 link"
                  href="https://www.remofirst.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
              </span>
            }
          />
          {errors.terms && (
            <Typography className="text_regular__14 color_red">
              {errors.terms.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Checkbox
            id="privacy-check"
            {...register('privacy', {
              required: 'Please read and accept the Privacy policy to continue',
            })}
            label={
              <span className="text_medium__14">
                I agree to the
                <a
                  className="ml-1 link"
                  href="https://www.remofirst.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </span>
            }
          />
          {errors.privacy && (
            <Typography className="text_regular__14 color_red">
              {errors.privacy.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
