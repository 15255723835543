import Typography from '@atoms/Typography/Typography'
import Button from '@atoms/Button/Button'
import React, { useCallback, useEffect, useState } from 'react'
import Input from '@atoms/Input/Input'
import { useAxiosCall } from '@api/Api'
import Select from '@atoms/Select/Select'
import EditModal from '@atoms/EditModal/EditModal'
import { DotWave } from '@uiball/loaders'

export default function CreateThread({ setVisible, onSave }) {
  const [topic, setTopic] = useState()
  const [member, setMember] = useState([])
  const [{ data, loading }, getThreadsMembers] = useAxiosCall(
    {
      url: `threads/users/`,
    },
    {
      manual: true,
    }
  )

  const onChangeTopic = useCallback((e) => {
    setTopic(e.target.value)
  }, [])
  const onChangeMember = useCallback((selectMember) => {
    const selectMembers = selectMember.map((user) => user.value)
    setMember(selectMembers)
  }, [])

  const onClickSaveThread = useCallback(() => {
    onSave({ members: member, topic })
  }, [member, topic])

  useEffect(() => {
    getThreadsMembers()
  }, [])
  return (
    <EditModal
      visible
      // scrollable should be eq to {false} because of select dropdown menu scroll
      scrollable={false}
      footer={false}
      title="New ticket"
      closeModal={() => setVisible(false)}
      className="d-flex align-items-center flex-column"
    >
      <div className="d-flex justify-content-between w-100 mb-4">
        <div className="flex-column">
          <Typography className="text_regular__14 color_text_300">
            Do you need to communicate with one of your customers or employers?
            You can start the discussion right here.
          </Typography>
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={32} speed={1} color="black" />
        </div>
      ) : (
        <div style={{ minHeight: 300 }}>
          <Input
            onChange={onChangeTopic}
            label="Ticket topic"
            type="text"
            name="topic"
            placeholder="Topic"
            styleClass="mb-3"
            value={topic}
          />
          <Select
            options={data?.map((user) => ({
              value: user.id,
              label: user.full_name,
            }))}
            isMulti
            name="thread_member"
            id="thread_member"
            placeholder="Select members"
            onChange={onChangeMember}
            value={member}
            label="Add members"
          />
        </div>
      )}
      <div className="d-flex justify-content-end mt-5 w-100">
        <Button
          priority="secondary"
          size="small"
          className="mr-3"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button priority="primary" size="small" onClick={onClickSaveThread}>
          Create
        </Button>
      </div>
    </EditModal>
  )
}
