import './DocumentsPage.scss'

import Button from '@atoms/Button/Button'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { AppContext } from '@core/context'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import DocumentsCheck from '@pages/onboarding/documents/DocumentsCheck'
import { useBoolean } from 'usehooks-ts'
import Confirm from '@atoms/Confirm'
import Typography from '@atoms/Typography/Typography'
import { parseDocuments } from './parser'
import {
  addContractDocuments,
  deleteDocumentsById,
  getContractDocuments,
} from '../../services/documents.service'

export default function DocumentsPage() {
  const { worker } = useContext(AppContext)
  const fileInputRef = useRef(null)
  const confirmDelete = useBoolean(false)
  const [page, setPage] = useState(1)
  const [documentId, setDocumentId] = useState()

  const { data, isFetching, refetch } = useQuery(['documents', page], {
    queryFn: () =>
      getContractDocuments({
        contract_id: worker?.id,
        page,
        document_type_is_not_in: 'payslip,agreement',
      }),
  })

  const deleteDocument = useMutation({
    mutationFn: (payload) =>
      deleteDocumentsById(payload.id, payload.contractId),
    onSuccess: () => {
      refetch()
      setPage(1)
    },
  })

  const addDocument = useMutation({
    mutationFn: (payload) => addContractDocuments(payload),
    onSuccess: () => {
      setPage(1)
      refetch()
    },
  })

  const onClickDownload = useCallback((row) => {
    window.open(row.file, '_blank')
  }, [])

  const onClickDelete = useCallback(
    (row) => {
      setDocumentId(row.id)
      confirmDelete.setTrue()
    },
    [confirmDelete]
  )

  const onChangeFile = (event) => {
    const fd = new FormData()
    const file = event.target.files[0]
    fd.append('file', file)
    fd.append('contract', worker.id)

    addDocument.mutate(fd)

    // addDocumentsByContractId(data).then((res) => {
    //   setDocuments((prevState) => [...prevState, res.data])
    // })
  }

  const onClickAddDocument = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onDeleteFile = useCallback(() => {
    deleteDocument.mutate({ id: documentId, contractId: worker.id })
    confirmDelete.setFalse()
  }, [confirmDelete, documentId, worker])

  const documents = data ? data.results : []
  const fields = () => [
    {
      title: 'Name',
      key: 'name',
      type: 'textMedium',
      icon: 'description',
      maxWidth: 680,
      minWidth: 680,
      width: 680,
    },
    {
      title: 'Uploaded',
      key: 'uploaded',
      type: 'string',
      maxWidth: 170,
      minWidth: 170,
      width: 170,
    },
    {
      title: 'Actions',
      key: 'actions',
      type: 'actions',
      maxWidth: 170,
      minWidth: 170,
      width: 170,
      actions: [
        {
          action: onClickDownload,
          icon: 'download',
        },
        {
          action: onClickDelete,
          icon: 'deleteIcon',
        },
      ],
    },
  ]

  if (
    worker.documents_state === 'waiting_document' ||
    worker.documents_state === 'waiting_declined_documents' ||
    worker.documents_state === 'waiting_document_approval'
  ) {
    return (
      <>
        <PageTitle styleClass="mb-4"> Documents </PageTitle>
        <DocumentsCheck />
      </>
    )
  }

  return (
    <div className="documents-page">
      <div className="d-flex justify-content-between">
        <PageTitle styleClass="mb-4"> Documents </PageTitle>
        <Button priority="secondary" size="small" onClick={onClickAddDocument}>
          Add document
        </Button>

        <input
          type="file"
          className="d-none"
          ref={fileInputRef}
          multiple
          onChange={onChangeFile}
        />
      </div>
      <CustomTable
        page={page}
        pageSize={20}
        total={data?.count || 0}
        loading={isFetching}
        fields={fields()}
        data={parseDocuments(documents)}
        onPage={setPage}
      />
      {confirmDelete.value && (
        <Confirm
          title="Are you sure you want to delete this document?"
          yesButtonProps={{
            text: 'Delete',
          }}
          cancelButtonProps={{
            text: 'Cancel',
          }}
          isLoading={deleteDocument.isLoading}
          onCancel={confirmDelete.setFalse}
          onYes={onDeleteFile}
        >
          <Typography>This file will be deleted from the platform</Typography>
        </Confirm>
      )}
    </div>
  )
}
