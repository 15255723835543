import React from 'react'
import Typography from '@atoms/Typography/Typography'
import { Controller, useForm } from 'react-hook-form'
import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Select from '@atoms/Select/Select'
import { useApp } from '@core/context'

export const TabAnyAmount = ({ onSubmit }) => {
  const { currencies } = useApp()
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
  } = useForm()

  const startDate = watch('start_date')

  return (
    <form id="timeOff-form" key="AnyAmount" onSubmit={handleSubmit(onSubmit)}>
      <Typography className="text_medium__14 my-1">
        {' '}
        Specify the period{' '}
      </Typography>
      <div className="row">
        <div className="col-6 pr-2">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="start_date"
              rules={{ required: 'Start date is required' }}
              render={({ field }) => {
                return <DateInput {...field} placeholder="Start date" />
              }}
            />
            {errors.start_date && (
              <Typography className="text_regular__14 color_red">
                {errors.start_date.message}
              </Typography>
            )}
          </div>
        </div>
        <div className="col-6 pl-2">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="end_date"
              rules={{ required: 'End date is required' }}
              render={({ field }) => {
                return (
                  <DateInput
                    {...field}
                    placeholder="End date"
                    minDate={startDate}
                  />
                )
              }}
            />
            {errors.end_date && (
              <Typography className="text_regular__14 color_red">
                {errors.end_date.message}
              </Typography>
            )}
          </div>
        </div>
      </div>

      <div className="w-100 remo-form-input">
        <CurrencyInput
          {...register('amount', {
            required: `Amount is required`,
          })}
          type="text"
          label="Enter any amount"
          placeholder="Amount"
          required
        />
        {errors?.amount && (
          <Typography className="text_regular__14 color_red">
            {errors?.amount.message}
          </Typography>
        )}
      </div>
      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="currency"
          rules={{ required: 'Currency is required' }}
          render={({ field }) => {
            return (
              <Select
                {...field}
                label="Currency"
                options={currencies.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
              />
            )
          }}
        />
        {errors.currency && (
          <Typography className="text_regular__14 color_red">
            {errors.currency.message}
          </Typography>
        )}
      </div>
    </form>
  )
}
