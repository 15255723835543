import Button from '@atoms/Button/Button'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import moment from 'moment'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { TabCurrentRate } from '@pages/payments/new-payment/tab-current-rate/TabCurrentRate'
import { TabAnyAmount } from '@pages/payments/new-payment/tab-any-amount/TabAnyAmount'
import { createPayment } from '../../../services/payments.service'

const PaymentType = {
  CurrentRate: 0,
  AnyAmount: 1,
}
const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
  .modal-body {
    .tabs {
      .tab-content {
        overflow: initial;
      }
    }
  }
`

export default ({ close, refetch }) => {
  const [tabId, setTabId] = useState(PaymentType.CurrentRate)
  const { reset } = useForm()

  const createPaymentRequest = useMutation(createPayment, {
    onSuccess: () => {
      close()
      refetch()
      toast.success('Payment created!')
    },
  })

  const onCreatePayment = (values) => {
    const data = {
      ...values,
      start_date: moment(values.start_date).format('YYYY-MM-DD'),
      end_date: moment(values.end_date).format('YYYY-MM-DD'),
      amount: tabId === 1 ? values.amount : undefined,
      currency: values.currency?.value,
    }
    if (tabId === PaymentType.CurrentRate) delete data.currency
    createPaymentRequest.mutate(data)
  }

  const onTabChange = (tab) => {
    setTabId(tab)
    reset()
  }

  return (
    <StyledModal show onHide={close} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">New payment</Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4">
        <Tabs selectedTab={tabId} onTabChange={onTabChange}>
          <Tab tabId={PaymentType.CurrentRate} title="Current rate">
            <TabCurrentRate onSubmit={onCreatePayment} />
          </Tab>
          <Tab tabId={PaymentType.AnyAmount} title="Any amount">
            <TabAnyAmount onSubmit={onCreatePayment} />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" size="small" priority="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          form="timeOff-form"
          type="submit"
          size="small"
          loading={createPaymentRequest.isLoading}
          priority="primary"
        >
          Generate
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
