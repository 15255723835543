import React, { createContext, useCallback, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { DotWave } from '@uiball/loaders'
import Cookies from 'js-cookie'

export const axiosBffInstance = axios.create({
  baseURL: process.env.REACT_APP_BFF_API,
  withCredentials: true,
})

export const fetchTokens = async () => {
  const v = await axiosBffInstance.get('/auth/token')
  return v.data
}

export const fetchUserApp = async () => {
  const v = await axiosBffInstance.get('/auth/app')
  return v.data
}

const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: true,
  tokens: null,
  login: () => '',
  logout: () => '',
})

export const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN'

export function AuthProvider({ children, logoutUrl, loginUrl }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [tokens, setTokens] = useState(null)

  const { isLoading } = useQuery('tokens', {
    queryFn: fetchTokens,
    // eslint-disable-next-line no-shadow
    onSuccess: (tokens) => {
      localStorage.setItem(ACCESS_TOKEN_NAME, tokens.access_token)
      setTokens(tokens)
      setIsAuthenticated(true)
      Cookies.remove('redirect_url', {
        domain: '.remofirst.com',
      })
    },
    onError: () => {
      Cookies.set('redirect_url', window.location.href, {
        domain: '.remofirst.com',
      })
    },
  })

  const login = useCallback(() => {
    window.location.replace(loginUrl)
  }, [loginUrl])

  const logout = useCallback(() => {
    window.location.replace(logoutUrl)
    localStorage.removeItem(ACCESS_TOKEN_NAME)
  }, [logoutUrl])

  useQuery('app', {
    queryFn: fetchUserApp,
    onSuccess: (user_app_host) => {
      if (!user_app_host) {
        logout()
        return
      }
      if (user_app_host !== window.location.origin) {
        window.location.replace(user_app_host)
      }
    },
    onError: () => {
      logout()
    },
  })

  if (isLoading)
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )

  if (!isAuthenticated) return <>{window.location.replace(loginUrl)}</>

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAuthenticated,
        isLoading,
        login,
        logout,
        tokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
