import { getCurrency, SplitNumber } from '@core/utils'
import moment from 'moment'

export const parseInvoice = (invoices) =>
  invoices.map((invoice) => ({
    ...invoice,
    amount: `${
      invoice.receiving_currency
        ? getCurrency(invoice.receiving_currency)
        : getCurrency(invoice.currency)
    } ${SplitNumber(
      invoice.amount_in_receiving_currency
        ? invoice.amount_in_receiving_currency
        : invoice.amount
    )}`,
    date: moment(invoice.date).format('DD MMM YYYY'),
  }))
