import React, { useEffect, useState } from 'react'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Step from '@atoms/Stepper/Step'
import { useApp } from '@core/context'

import PersonalProfileForm from './forms/PersonalProfileForm'
import AdministrativeForm from './forms/AdministrativeForm'
import HomeAddressForm from './forms/HomeAddressForm'
import EmergencyForm from './forms/EmergencyForm'
import { data } from './nationalities'
import Documents from './forms/Documents'
import StepAgreement from '../step-agreement/step-agreement.component'
import { useAuth } from '../../../AuthProvider'

const step1 = 'Personal profile'
const step2 = 'Administrative details'
const step3 = 'Home address'
const step4 = 'Emergency contact'
const step5 = 'Documents'
const stepAgreementTitle = 'Agreement'

export default ({ onboardingState, loading, onSave }) => {
  const { worker, profile } = useApp()
  const [activeStep, setStep] = useState(1)
  const [payload, setPayload] = useState({})
  const [stepsCount, setStepsCount] = useState(0)
  const { logout } = useAuth()

  const handleBack = () => setStep((prev) => prev - 1)

  const handleSaveAndNext = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      ...formValues,
    }))
    setStep((prev) => Number(prev) + 1)
  }

  const handleAddress = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        address: formValues,
      },
    }))
    setStep((prev) => prev + 1)
  }

  const handlePassport = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        passport_number: formValues.passport_number,
      },
    }))
    setStep((prev) => prev + 1)
  }

  const handleFinish = (formValues) => {
    setPayload((prev) => {
      const body = { ...prev, ...formValues }
      onSave(body)
      return body
    })
  }

  useEffect(() => {
    console.log(payload)
  }, [payload])

  useEffect(() => {
    if (onboardingState === 'FILLED_ONBOARDING_DATA') {
      // Agreement step is last
      setStep(stepsCount)
    }
  }, [onboardingState, stepsCount])

  return (
    <Stepper
      activeStep={activeStep}
      title="Finish activating your account"
      description="Please ensure you complete all fields carefully and accurately. Remofirst will then create your Employment Agreement using the information you provide"
      setStepsCount={setStepsCount}
      logout={logout}
    >
      <Step
        title={step1}
        setStep={setStep}
        forceDisabled={activeStep === stepsCount}
      >
        <StepContent title={step1}>
          <PersonalProfileForm
            nationalities={data}
            onNext={handleSaveAndNext}
          />
        </StepContent>
      </Step>

      <Step
        title={step2}
        setStep={setStep}
        forceDisabled={activeStep === stepsCount}
      >
        <StepContent title={step2} onBack={handleBack}>
          <AdministrativeForm onNext={handlePassport} />
        </StepContent>
      </Step>

      <Step
        title={step3}
        setStep={setStep}
        forceDisabled={activeStep === stepsCount}
      >
        <StepContent title={step3} onBack={handleBack}>
          <HomeAddressForm onNext={handleAddress} />
        </StepContent>
      </Step>

      <Step
        title={step4}
        setStep={setStep}
        forceDisabled={activeStep === stepsCount}
      >
        <StepContent title={step4} onBack={handleBack}>
          <EmergencyForm
            loading={loading}
            submitTitle="Continue"
            onFinish={
              worker.need_to_provide_documents
                ? handleSaveAndNext
                : handleFinish
            }
          />
        </StepContent>
      </Step>

      {worker.need_to_provide_documents && (
        <Step
          title={step5}
          setStep={setStep}
          forceDisabled={activeStep === stepsCount}
        >
          <StepContent title={step5} onBack={handleBack}>
            <Documents loading={loading} onFinish={handleFinish} />
          </StepContent>
        </Step>
      )}

      <Step title={stepAgreementTitle} setStep={setStep}>
        <StepContent title={stepAgreementTitle} onBack={handleBack}>
          <StepAgreement worker={worker} />
        </StepContent>
      </Step>
    </Stepper>
  )
}
