import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import { add } from '@core/icons/icons'
import Icon from '@atoms/Icon/Icon'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import NewPaymentModal from '@pages/payments/new-payment/NewPaymentModal'
import { getFields } from '@pages/payments/payContractorsMock'
import { payContractorsTableParse } from '@pages/payments/parse'
import { DotWave } from '@uiball/loaders'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'
import ContentPlaceholder from '../../components/content-placeholder/content-placeholder.component'
import {
  fetchDeleteContractorPayments,
  getPayments,
} from '../../services/payments.service'

const DEFAULT_PAGE_LIMIT = 10

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

const StyledIcon = styled(Icon)`
  path {
    fill: white;
  }
`

export const PaymentsPage = () => {
  const modalOpen = useBoolean(false)
  const { worker } = useContext(AppContext)

  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)
  const [selectedPayContractDelete, setSelectedPayContractDelete] =
    useState(null)

  const [agreementId, setAgreementId] = useState()
  const [page, setPage] = useState(1)
  const [contracts, setContracts] = useState([])

  const {
    isFetching,
    isLoading,
    data: paymentsResponse,
    refetch,
  } = useQuery(['getPayments', page], {
    queryFn: () =>
      getPayments({
        limit: DEFAULT_PAGE_LIMIT,
        offset: (page - 1) * DEFAULT_PAGE_LIMIT,
      }),
    onSuccess: (v) => {
      if (v?.results.length) {
        setContracts(payContractorsTableParse(v.results, worker))
      }
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (contractorId) => fetchDeleteContractorPayments(contractorId),
    onSuccess: () => {
      refetch()
      toggleDelete()
      toast.success('Contractor payment successfully deleted')
    },
  })

  const handleCollapse = (row) => {
    if (row.id === agreementId) return
    setAgreementId(row.id)
  }
  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayContractDelete(row.id)
  }

  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>Payments</PageTitle>

        <Button
          priority="primary_black"
          size="small"
          hidden={!paymentsResponse?.results?.length}
          onClick={modalOpen.setTrue}
        >
          <StyledIcon icon={add} color="white" className="mr-2" />
          New payment
        </Button>
      </StyledTopbar>
      <NotificationBlock text="Create and view your payments here" />

      {isFetching ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {paymentsResponse?.results?.length > 0 ? (
            <CustomTable
              type="collapse"
              fields={getFields({ handleDelete })}
              data={contracts}
              onCollapseClick={handleCollapse}
              page={page}
              total={paymentsResponse?.count}
              loading={isLoading || isFetching}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <ContentPlaceholder
              imgSrc="/assets/img/quiet.png"
              title={`It's quiet in here ...`}
              buttonText="+ New payment"
              description="We'll let you know when we get news for you."
              onClick={modalOpen.setTrue}
            />
          )}
        </div>
      )}
      {modalOpen.value && (
        <NewPaymentModal close={modalOpen.setFalse} refetch={refetch} />
      )}
      <EditModal
        visible={visibleDelete}
        title="Delete payment?"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          This action will delete the request permanently and you will not be
          able to edit it anymore.
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayContractDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
    </StyledContainer>
  )
}
