import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { AppContext } from '@core/context'
import { coin } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import ContentPlaceholder from '../../components/content-placeholder/content-placeholder.component'
import { getAgreement, getAgreements } from '../../services/sign.service'
import AgreementActions from './agreement-actions/agreement-actions'
import { transformAgreements } from './agreements.utils'

const DEFAULT_PAGE_LIMIT = 10

const fields = [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    maxWidth: 320,
    minWidth: 320,
    width: 320,
  },
  {
    title: 'Created',
    key: 'created',
    type: 'textMedium',
    maxWidth: 320,
    minWidth: 320,
    width: 320,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 320,
    minWidth: 320,
    width: 320,
  },
  {
    title: 'Pending Signatures',
    key: 'pending',
    type: 'pending',
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'custom',
    render: (item) => <AgreementActions item={item} />,
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
]

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

const StyledToolbar = styled.div`
  display: flex;
  gap: 8px;
`
const StyledInputContainer = styled.div`
  width: 248px;
`

export const AgreementsPage = () => {
  const modalOpen = useBoolean(false)
  const { worker } = useContext(AppContext)

  const [agreementId, setAgreementId] = useState()
  const [agreementDetail, setAgreementDetail] = useState()
  const [employee, setEmployee] = useState()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const { isFetching, data: agreementsResponse } = useQuery(
    ['getAgreements', page],
    {
      queryFn: () =>
        getAgreements({
          limit: DEFAULT_PAGE_LIMIT,
          offset: (page - 1) * DEFAULT_PAGE_LIMIT,
        }),
      onSuccess: ({ count }) => {
        setTotal(count)
      },
    }
  )

  const { isLoading } = useQuery(['getAgreement', agreementId], {
    queryFn: () => getAgreement(agreementId),
    onSuccess: (data) => {
      setAgreementDetail(data)
    },
    enabled: !!agreementId,
  })

  const handleCollapse = (row) => {
    if (row.id === agreementId) return
    setAgreementId(row.id)
  }

  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>All agreements</PageTitle>
      </StyledTopbar>
      <StyledToolbar>
        <StyledInputContainer></StyledInputContainer>
      </StyledToolbar>
      {isFetching ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          <NotificationBlock
            text="All agreements will be stored here"
            cards={[
              {
                title: 'Sign Pending agreements',
                description:
                  'Select the “Sign” button next to the agreement and sign it on the platform.',
                icon: coin,
              },
              {
                title: 'Review your signed agreements',
                description:
                  'All signed agreements will be stored in the “Signed agreements” tab. You can download the documents from there.',
                icon: coin,
              },
            ]}
          />
          {agreementsResponse?.results?.length > 0 ? (
            <CustomTable
              type="collapse"
              fields={fields}
              data={transformAgreements(
                agreementsResponse,
                agreementDetail,
                isLoading
              )}
              onCollapseClick={handleCollapse}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <ContentPlaceholder
              imgSrc="/assets/img/quiet.png"
              title={`It's quiet in here ...`}
            />
          )}
        </div>
      )}
    </StyledContainer>
  )
}
