import React from 'react'
import { useForm } from 'react-hook-form'
import Input from '@atoms/Input/Input'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'

export default ({ onNext }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      passport_number: '',
    },
  })

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(onNext)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <Input
            {...register('passport_number', {
              required: 'ID/passport number is required',
            })}
            type="text"
            label="ID/passport number"
            placeholder="Enter your ID/passport number"
          />
          {errors.passport_number && (
            <Typography className="text_regular__14 color_red">
              {errors.passport_number.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
