/* eslint-disable react/button-has-type */
import React from 'react'
import classNames from 'classnames'
import './style.scss'

export default function Button({
  children,
  priority = 'primary',
  size = 'large',
  loading = false,
  ...rest
}) {
  return (
    <button
      {...rest}
      className={classNames([
        'button',
        `button__${priority}`,
        `button__${size}`,
        rest.className,
      ])}
    >
      <span className="label">{loading ? 'loading...' : children}</span>
    </button>
  )
}
