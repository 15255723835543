import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Image = styled.img`
  margin-bottom: 24px;
  width: 204px;
`

const Title = styled.h2`
  color: #121212;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
`
const Description = styled.div`
  font-size: 14px;
  color: #878787;
  margin-bottom: 24px;
`

export default function ContentPlaceholder({
  imgSrc,
  title,
  description,
  onClick,
  buttonIcon,
  buttonText,
}) {
  return (
    <Container>
      <Image src={imgSrc} alt={buttonText} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      {buttonText && (
        <Button priority="primary_black" size="small" onClick={onClick}>
          {buttonIcon && <Icon icon={buttonIcon} className="mr-2" />}
          {buttonText}
        </Button>
      )}
    </Container>
  )
}
