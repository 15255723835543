import React from 'react'

export const createIcon = (width, height, path, svgParams) => ({
  width,
  height,
  path,
  svgParams,
})

export const clear = createIcon(
  24,
  24,
  <path d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z" />,
  {}
)
export const clearMedium = createIcon(
  28,
  28,
  <path
    d="M22.1668 7.47825L20.5218 5.83325L14.0002 12.3549L7.4785 5.83325L5.8335 7.47825L12.3552 13.9999L5.8335 20.5216L7.4785 22.1666L14.0002 15.6449L20.5218 22.1666L22.1668 20.5216L15.6452 13.9999L22.1668 7.47825Z"
    fill="#878787"
  />,
  {}
)

export const send = createIcon(
  20,
  20,
  <path
    d="M1.67508 17.5L19.1667 10L1.67508 2.5L1.66675 8.33333L14.1667 10L1.66675 11.6667L1.67508 17.5Z"
    fill="white"
  />
)

export const add = createIcon(
  16,
  16,
  <path
    d="M12.6663 9.16671H8.66634V13.1667H7.33301V9.16671H3.33301V7.83337H7.33301V3.83337H8.66634V7.83337H12.6663V9.16671Z"
    fill="black"
  />
)
export const attachFile = createIcon(
  20,
  20,
  <path
    d="M13.7499 5.00065V14.584C13.7499 16.4256 12.2583 17.9173 10.4166 17.9173C8.57492 17.9173 7.08325 16.4256 7.08325 14.584V4.16732C7.08325 3.01732 8.01659 2.08398 9.16659 2.08398C10.3166 2.08398 11.2499 3.01732 11.2499 4.16732V12.9173C11.2499 13.3756 10.8749 13.7506 10.4166 13.7506C9.95825 13.7506 9.58325 13.3756 9.58325 12.9173V5.00065H8.33325V12.9173C8.33325 14.0673 9.26659 15.0006 10.4166 15.0006C11.5666 15.0006 12.4999 14.0673 12.4999 12.9173V4.16732C12.4999 2.32565 11.0083 0.833984 9.16659 0.833984C7.32492 0.833984 5.83325 2.32565 5.83325 4.16732V14.584C5.83325 17.1173 7.88325 19.1673 10.4166 19.1673C12.9499 19.1673 14.9999 17.1173 14.9999 14.584V5.00065H13.7499Z"
    fill="#878787"
  />
)

export const chatBubble = createIcon(
  20,
  21,
  <path d="M16.667 2.16663H3.33366C2.41699 2.16663 1.66699 2.91663 1.66699 3.83329V18.8333L5.00033 15.5H16.667C17.5837 15.5 18.3337 14.75 18.3337 13.8333V3.83329C18.3337 2.91663 17.5837 2.16663 16.667 2.16663ZM16.667 13.8333H5.00033L3.33366 15.5V3.83329H16.667V13.8333Z" />
)
export const notificationNone = createIcon(
  20,
  20,
  <path
    d="M16.075 14.9083L15 13.8333V9.66668C15 7.10834 13.6334 4.96668 11.25 4.40001V3.83334C11.25 3.14168 10.6917 2.58334 10 2.58334C9.30838 2.58334 8.75004 3.14168 8.75004 3.83334V4.40001C6.35838 4.96668 5.00004 7.10001 5.00004 9.66668V13.8333L3.92504 14.9083C3.40004 15.4333 3.76671 16.3333 4.50838 16.3333H15.4834C16.2334 16.3333 16.6 15.4333 16.075 14.9083ZM13.3334 14.6667H6.66671V9.66668C6.66671 7.60001 7.92504 5.91668 10 5.91668C12.075 5.91668 13.3334 7.60001 13.3334 9.66668V14.6667ZM10 18.8333C10.9167 18.8333 11.6667 18.0833 11.6667 17.1667H8.33338C8.33338 18.0833 9.07504 18.8333 10 18.8333Z"
    fill="#484848"
  />
)

export const searchIcon = createIcon(
  20,
  20,
  <path
    d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
    fill="#878787"
  />
)

export const addMember = createIcon(
  16,
  16,
  <>
    <g clipPath="url(#clip0_3645_67761)">
      <path
        d="M13.3333 6.66699V8.66699M11.3333 6.66699H13.3333H11.3333ZM15.3333 6.66699H13.3333H15.3333ZM13.3333 6.66699V4.66699V6.66699Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666748 13.3333V12.6667C0.666748 10.0893 2.75609 8 5.33341 8C7.91075 8 10.0001 10.0893 10.0001 12.6667V13.3333"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33341 8.00033C6.80615 8.00033 8.00008 6.80639 8.00008 5.33366C8.00008 3.8609 6.80615 2.66699 5.33341 2.66699C3.86065 2.66699 2.66675 3.8609 2.66675 5.33366C2.66675 6.80639 3.86065 8.00033 5.33341 8.00033Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3645_67761">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </>
)

export const ArrowRight = createIcon(
  18,
  18,
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 5L12.5 10L7.5 15" stroke="black" />
  </svg>,
  {}
)

export const ArrowLeft = createIcon(
  18,
  18,
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 5L7.5 10L12.5 15" stroke="black" />
  </svg>,
  {}
)
export const check = createIcon(
  14,
  15,
  <path
    d="M5.25009 9.93241L2.81759 7.49991L1.98926 8.32241L5.25009 11.5832L12.2501 4.58324L11.4276 3.76074L5.25009 9.93241Z"
    fill="#04C53A"
    stroke="#04C53A"
    strokeWidth="0.5"
  />,
  {}
)

export const checkNone = createIcon(
  14,
  15,
  <path
    d="M5.25009 9.93241L2.81759 7.49991L1.98926 8.32241L5.25009 11.5832L12.2501 4.58324L11.4276 3.76074L5.25009 9.93241Z"
    strokeWidth="0.5"
  />,
  {}
)

export const androidIcon = createIcon(
  20,
  20,
  <>
    <g clipPath="url(#clip0_4487_2212)">
      <path
        d="M12.6641 1.67184L12.8828 1.34372L13.1016 1.02341L13.5859 0.296845C13.6484 0.210908 13.625 0.0859072 13.5312 0.0312197C13.4453 -0.0312803 13.3203 -0.00784181 13.2656 0.0859082L12.75 0.859345L12.5312 1.18747L12.3125 1.51559C11.6094 1.24216 10.8359 1.09372 10.0078 1.09372C9.1875 1.09372 8.40625 1.24216 7.70312 1.51559L7.48438 1.18747L7.26562 0.859345L6.75 0.0859082C6.6875 -2.93087e-05 6.57031 -0.0234678 6.48437 0.0312197C6.39844 0.0937197 6.375 0.210908 6.42969 0.296845L6.91406 1.02341L7.13281 1.34372L7.35156 1.67184C5.71094 2.43747 4.59375 3.88278 4.59375 5.54685H15.4297C15.4219 3.88278 14.3125 2.43747 12.6641 1.67184ZM7.67969 3.99216C7.35938 3.99216 7.10156 3.73434 7.10156 3.41403C7.10156 3.09372 7.35938 2.83591 7.67969 2.83591C8 2.83591 8.25781 3.09372 8.25781 3.41403C8.25781 3.73434 8 3.99216 7.67969 3.99216ZM12.3203 3.99216C12 3.99216 11.7422 3.73434 11.7422 3.41403C11.7422 3.09372 12 2.83591 12.3203 2.83591C12.6406 2.83591 12.8984 3.09372 12.8984 3.41403C12.9062 3.73434 12.6484 3.99216 12.3203 3.99216Z"
        fill="#6D6D6C"
      />
      <path
        d="M4.66406 6.3125L4.57812 6.32031V7.28125V8.07031V14.8359C4.57812 15.5156 5.125 16.0625 5.80469 16.0625H6.6875C6.65625 16.1641 6.64062 16.2734 6.64062 16.3828V16.4453V16.8359V18.8359C6.64062 19.4766 7.16406 20 7.80469 20C8.44531 20 8.96875 19.4766 8.96875 18.8359V16.8359V16.4453V16.3828C8.96875 16.2734 8.95312 16.1641 8.92188 16.0625H11.0781C11.0469 16.1641 11.0313 16.2734 11.0313 16.3828V16.4453V16.8359V18.8359C11.0313 19.4766 11.5547 20 12.1953 20C12.8359 20 13.3594 19.4766 13.3594 18.8359V16.8359V16.4453V16.3828C13.3594 16.2734 13.3438 16.1641 13.3125 16.0625H14.1953C14.875 16.0625 15.4219 15.5156 15.4219 14.8359V8.07031V7.28125V6.3125H15.3359H4.66406Z"
        fill="#6D6D6C"
      />
      <path
        d="M2.64844 6.32031C2.00781 6.32031 1.48438 6.84375 1.48438 7.48438V12.4531C1.48438 13.0937 2.00781 13.6172 2.64844 13.6172C3.28906 13.6172 3.8125 13.0937 3.8125 12.4531V7.48438C3.80469 6.83594 3.28906 6.32031 2.64844 6.32031Z"
        fill="#6D6D6C"
      />
      <path
        d="M17.3594 6.32031C16.7188 6.32031 16.1953 6.84375 16.1953 7.48438V12.4531C16.1953 13.0937 16.7188 13.6172 17.3594 13.6172C18 13.6172 18.5234 13.0937 18.5234 12.4531V7.48438C18.5156 6.83594 18 6.32031 17.3594 6.32031Z"
        fill="#6D6D6C"
      />
    </g>
    <defs>
      <clipPath id="clip0_4487_2212">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </>
)

export const iosIcon = createIcon(
  20,
  20,
  <>
    <path
      d="M14.5257 13.8202C14.9252 14.5152 13.9098 15.0936 13.5082 14.4444C12.6364 13.0961 11.8707 11.6812 11.0155 10.3204C10.7533 9.67123 9.98135 8.98252 10.333 8.2501C11.2589 7.02454 12.0288 8.7245 12.3825 9.51727C13.323 11.469 12.7175 10.9426 14.8066 10.928C15.7138 10.9301 15.8074 12.32 14.9023 12.4199C14.5569 12.4594 14.2177 12.4553 13.8682 12.4636C14.1074 12.9109 14.2697 13.3812 14.5257 13.8202Z"
      fill="white"
    />
    <path
      d="M9.88145 5.36823C10.1374 4.81061 10.772 4.13436 11.3588 4.68576C11.7562 5.0478 11.4857 5.61378 11.2152 5.98413C10.1915 7.57798 9.37999 9.28834 8.44991 10.9342C9.28012 11.007 10.1166 10.955 10.9406 11.1007C11.752 11.2463 11.6438 12.4469 10.8178 12.4656C9.21147 12.528 7.60514 12.4053 5.99672 12.4927C5.42245 12.449 4.68795 12.7569 4.24476 12.2721C3.35629 10.7594 5.74497 10.9883 6.61679 10.928C7.16195 10.4036 7.43035 9.5193 7.87147 8.87011C8.2356 8.22092 8.59141 7.56548 8.95554 6.9163C9.02004 6.7124 8.72874 6.54386 8.65591 6.35659C7.27431 4.80644 9.21147 3.60169 9.88145 5.36823Z"
      fill="white"
    />
    <path
      d="M6.88108 13.3999C6.86235 12.6883 5.9281 12.4136 5.5286 13.044C5.35175 13.3395 5.12702 13.635 5.04796 13.9741C4.8919 14.6462 5.74083 15.104 6.21525 14.6025C6.53152 14.2634 6.77496 13.8514 6.88108 13.3999ZM11.3588 4.68575C10.77 4.13227 10.1374 4.80852 9.8815 5.36823C9.2115 3.60169 7.27225 4.80644 8.65594 6.35867C8.72877 6.54592 9.018 6.71238 8.95556 6.91838C8.59144 7.56965 8.23565 8.223 7.87152 8.87219C7.4304 9.52138 7.16198 10.4036 6.61683 10.93C5.745 10.9883 3.35631 10.7594 4.24688 12.2742C4.69006 12.7611 5.42456 12.451 5.99885 12.4947C7.60725 12.4074 9.2115 12.5301 10.8199 12.4677C11.646 12.449 11.7542 11.2484 10.9427 11.1027C10.1187 10.9571 9.28225 11.007 8.45204 10.9363C9.38213 9.29042 10.1936 7.57796 11.2173 5.98621C11.4858 5.61584 11.7562 5.04988 11.3588 4.68575ZM7.13077 1.80602C9.65263 1.7644 12.2473 1.36698 14.6984 2.18679C17.3014 2.98996 18.2086 5.79477 18.1836 8.27294C18.2024 10.027 18.2086 11.7956 17.8944 13.5247C17.3805 16.7686 15.5453 18.1876 12.3055 18.2209C8.79952 18.3395 3.59352 18.9367 2.17654 14.8127C1.59394 12.4885 1.80618 10.0228 1.83739 7.64248C2.06211 4.18221 3.4021 1.88092 7.13077 1.80602ZM14.9044 12.4198C15.8095 12.32 15.7159 10.93 14.8087 10.9279C12.7196 10.9425 13.3251 11.4689 12.3846 9.51721C12.0288 8.72446 11.261 7.02657 10.3351 8.25004C9.98346 8.98038 10.7575 9.66911 11.0176 10.3204C11.8728 11.6791 12.6385 13.094 13.5103 14.4444C13.9119 15.0936 14.9273 14.5151 14.5278 13.8202C14.2718 13.3811 14.1095 12.9109 13.8703 12.4635C14.2178 12.4552 14.559 12.4594 14.9044 12.4198Z"
      fill="#6D6D6C"
    />
    <path
      d="M5.53065 13.0442C5.93015 12.4117 6.8644 12.6885 6.88312 13.4001C6.777 13.8516 6.53148 14.2636 6.21937 14.6048C5.74496 15.1042 4.89602 14.6485 5.05208 13.9764C5.12906 13.6352 5.35171 13.3397 5.53065 13.0442Z"
      fill="white"
    />
  </>
)

export const CloseIcon = createIcon(
  12,
  12,
  <path
    d="M11.25 0.758309C10.925 0.433309 10.4 0.433309 10.075 0.758309L5.99998 4.82498L1.92498 0.749976C1.59998 0.424976 1.07498 0.424976 0.749976 0.749976C0.424976 1.07498 0.424976 1.59998 0.749976 1.92498L4.82498 5.99998L0.749976 10.075C0.424976 10.4 0.424976 10.925 0.749976 11.25C1.07498 11.575 1.59998 11.575 1.92498 11.25L5.99998 7.17497L10.075 11.25C10.4 11.575 10.925 11.575 11.25 11.25C11.575 10.925 11.575 10.4 11.25 10.075L7.17497 5.99998L11.25 1.92498C11.5666 1.60831 11.5666 1.07498 11.25 0.758309Z"
    fill="#121212"
  />
)
export const keyboardArrowLeft = createIcon(
  18,
  18,
  <path d="M5.61867 12.7451L9.36847 8.99527L5.61867 5.24547C5.24176 4.86855 5.24176 4.25969 5.61867 3.88278C5.99558 3.50587 6.60444 3.50587 6.98135 3.88278L11.4173 8.31876C11.7942 8.69567 11.7942 9.30453 11.4173 9.68144L6.98135 14.1174C6.60444 14.4943 5.99558 14.4943 5.61867 14.1174C5.25142 13.7405 5.24176 13.122 5.61867 12.7451Z" />,
  {}
)

export const analyticsIcon = createIcon(
  20,
  20,
  <>
    <path d="M15.8333 3.49805H4.16667C3.25 3.49805 2.5 4.24805 2.5 5.16471V16.8314C2.5 17.748 3.25 18.498 4.16667 18.498H15.8333C16.75 18.498 17.5 17.748 17.5 16.8314V5.16471C17.5 4.24805 16.75 3.49805 15.8333 3.49805ZM15.8333 16.8314H4.16667V5.16471H15.8333V16.8314Z" />
    <path d="M7.50004 10.998H5.83337V15.1647H7.50004V10.998Z" />
    <path d="M14.1667 6.83105H12.5V15.1644H14.1667V6.83105Z" />
    <path d="M10.8333 12.665H9.16663V15.165H10.8333V12.665Z" />
    <path d="M10.8333 9.33105H9.16663V10.9977H10.8333V9.33105Z" />
  </>
)

export const copyrightIcon = createIcon(
  20,
  20,
  <path d="M9.89996 8.61406C10.9666 8.66406 11.2416 9.5724 11.2583 9.9974H12.75C12.6833 8.3474 11.5083 7.33906 9.87496 7.33906C8.03329 7.33906 6.66663 8.4974 6.66663 11.1141C6.66663 12.7307 7.44163 14.6474 9.86663 14.6474C11.7166 14.6474 12.7083 13.2724 12.7333 12.1891H11.2416C11.2166 12.6807 10.8666 13.3391 9.88329 13.3891C8.79163 13.3557 8.33329 12.5057 8.33329 11.1141C8.33329 8.70573 9.39996 8.63073 9.89996 8.61406ZM9.99996 2.66406C5.39996 2.66406 1.66663 6.3974 1.66663 10.9974C1.66663 15.5974 5.39996 19.3307 9.99996 19.3307C14.6 19.3307 18.3333 15.5974 18.3333 10.9974C18.3333 6.3974 14.6 2.66406 9.99996 2.66406ZM9.99996 17.6641C6.32496 17.6641 3.33329 14.6724 3.33329 10.9974C3.33329 7.3224 6.32496 4.33073 9.99996 4.33073C13.675 4.33073 16.6666 7.3224 16.6666 10.9974C16.6666 14.6724 13.675 17.6641 9.99996 17.6641Z" />
)

export const fiberSmartRecord = createIcon(
  20,
  20,
  <path d="M7.50004 4.33133C3.81671 4.33133 0.833374 7.31466 0.833374 10.998C0.833374 14.6813 3.81671 17.6647 7.50004 17.6647C11.1834 17.6647 14.1667 14.6813 14.1667 10.998C14.1667 7.31466 11.1834 4.33133 7.50004 4.33133ZM7.50004 15.998C4.74171 15.998 2.50004 13.7563 2.50004 10.998C2.50004 8.23966 4.74171 5.998 7.50004 5.998C10.2584 5.998 12.5 8.23966 12.5 10.998C12.5 13.7563 10.2584 15.998 7.50004 15.998ZM14.1667 4.548V6.28966C16.1084 6.973 17.5 8.823 17.5 10.998C17.5 13.173 16.1084 15.023 14.1667 15.7063V17.448C17.0417 16.7063 19.1667 14.1063 19.1667 10.998C19.1667 7.88966 17.0417 5.28966 14.1667 4.548Z" />
)

export const dollarIcon = createIcon(
  20,
  20,
  <>
    <path d="M9.58339 15.248C7.86672 15.248 7.19172 14.4814 7.10006 13.498H5.26672C5.36672 15.323 6.73339 16.348 8.33339 16.6897V18.498H10.8334V16.7064C12.4584 16.398 13.7501 15.4564 13.7501 13.748C13.7501 11.3814 11.7251 10.573 9.83339 10.0814C7.94172 9.58971 7.33339 9.08138 7.33339 8.28971C7.33339 7.38138 8.17506 6.74805 9.58339 6.74805C11.0667 6.74805 11.6167 7.45638 11.6667 8.49805H13.5084C13.4501 7.06471 12.5751 5.74805 10.8334 5.32305V3.49805H8.33339V5.29805C6.71672 5.64805 5.41672 6.69805 5.41672 8.30638C5.41672 10.2314 7.00839 11.1897 9.33339 11.748C11.4167 12.248 11.8334 12.9814 11.8334 13.7564C11.8334 14.3314 11.4251 15.248 9.58339 15.248Z" />{' '}
  </>
)

export const fileIcon = createIcon(
  20,
  20,
  <path d="M6.66671 14.3307H13.3334V15.9974H6.66671V14.3307ZM6.66671 10.9974H13.3334V12.6641H6.66671V10.9974ZM11.6667 2.66406H5.00004C4.08337 2.66406 3.33337 3.41406 3.33337 4.33073V17.6641C3.33337 18.5807 4.07504 19.3307 4.99171 19.3307H15C15.9167 19.3307 16.6667 18.5807 16.6667 17.6641V7.66406L11.6667 2.66406ZM15 17.6641H5.00004V4.33073H10.8334V8.4974H15V17.6641Z" />
)

export const accountIcon = createIcon(
  20,
  20,
  <path d="M9.99996 2.16602C5.39996 2.16602 1.66663 5.89935 1.66663 10.4993C1.66663 15.0993 5.39996 18.8327 9.99996 18.8327C14.6 18.8327 18.3333 15.0993 18.3333 10.4993C18.3333 5.89935 14.6 2.16602 9.99996 2.16602ZM5.89163 15.7327C6.24996 14.9827 8.43329 14.2493 9.99996 14.2493C11.5666 14.2493 13.7583 14.9827 14.1083 15.7327C12.975 16.6327 11.55 17.166 9.99996 17.166C8.44996 17.166 7.02496 16.6327 5.89163 15.7327ZM15.3 14.5243C14.1083 13.0743 11.2166 12.5827 9.99996 12.5827C8.78329 12.5827 5.89163 13.0743 4.69996 14.5243C3.84996 13.4077 3.33329 12.016 3.33329 10.4993C3.33329 6.82435 6.32496 3.83268 9.99996 3.83268C13.675 3.83268 16.6666 6.82435 16.6666 10.4993C16.6666 12.016 16.15 13.4077 15.3 14.5243ZM9.99996 5.49935C8.38329 5.49935 7.08329 6.79935 7.08329 8.41602C7.08329 10.0327 8.38329 11.3327 9.99996 11.3327C11.6166 11.3327 12.9166 10.0327 12.9166 8.41602C12.9166 6.79935 11.6166 5.49935 9.99996 5.49935ZM9.99996 9.66602C9.30829 9.66602 8.74996 9.10768 8.74996 8.41602C8.74996 7.72435 9.30829 7.16602 9.99996 7.16602C10.6916 7.16602 11.25 7.72435 11.25 8.41602C11.25 9.10768 10.6916 9.66602 9.99996 9.66602Z" />
)

export const logoutIcon = createIcon(
  20,
  20,
  <path d="M14.1666 6.33333L12.9916 7.50833L15.1416 9.66667H6.66663V11.3333H15.1416L12.9916 13.4833L14.1666 14.6667L18.3333 10.5L14.1666 6.33333ZM3.33329 4.66667H9.99996V3H3.33329C2.41663 3 1.66663 3.75 1.66663 4.66667V16.3333C1.66663 17.25 2.41663 18 3.33329 18H9.99996V16.3333H3.33329V4.66667Z" />
)

export const east = createIcon(
  20,
  20,
  <path d="M12.5 4.16666L11.325 5.34166L15.1416 9.16666H1.66663V10.8333H15.1416L11.3166 14.6583L12.5 15.8333L18.3333 9.99999L12.5 4.16666Z" />
)

export const arrowDown = createIcon(
  20,
  20,
  <path d="M6.175 7.6582L10 11.4749L13.825 7.6582L15 8.8332L10 13.8332L5 8.8332L6.175 7.6582Z" />
)

export const description = createIcon(
  20,
  20,
  <path
    d="M6.66634 13.8332H13.333V15.4998H6.66634V13.8332ZM6.66634 10.4998H13.333V12.1665H6.66634V10.4998ZM11.6663 2.1665H4.99967C4.08301 2.1665 3.33301 2.9165 3.33301 3.83317V17.1665C3.33301 18.0832 4.07467 18.8332 4.99134 18.8332H14.9997C15.9163 18.8332 16.6663 18.0832 16.6663 17.1665V7.1665L11.6663 2.1665ZM14.9997 17.1665H4.99967V3.83317H10.833V7.99984H14.9997V17.1665Z"
    fill="#04C53A"
  />
)
export const deleteIcon = createIcon(
  24,
  24,
  <path
    d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
    fill="black"
  />
)
export const download = createIcon(
  24,
  24,
  <path
    d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z"
    fill="black"
  />
)

export const emptyAvatar = createIcon(
  48,
  48,
  <>
    <circle cx="24" cy="24" r="24" fill="#F5F5F5" />
    <path
      d="M24 14C18.48 14 14 18.48 14 24C14 29.52 18.48 34 24 34C29.52 34 34 29.52 34 24C34 18.48 29.52 14 24 14ZM19.07 30.28C19.5 29.38 22.12 28.5 24 28.5C25.88 28.5 28.51 29.38 28.93 30.28C27.57 31.36 25.86 32 24 32C22.14 32 20.43 31.36 19.07 30.28ZM30.36 28.83C28.93 27.09 25.46 26.5 24 26.5C22.54 26.5 19.07 27.09 17.64 28.83C16.62 27.49 16 25.82 16 24C16 19.59 19.59 16 24 16C28.41 16 32 19.59 32 24C32 25.82 31.38 27.49 30.36 28.83ZM24 18C22.06 18 20.5 19.56 20.5 21.5C20.5 23.44 22.06 25 24 25C25.94 25 27.5 23.44 27.5 21.5C27.5 19.56 25.94 18 24 18ZM24 23C23.17 23 22.5 22.33 22.5 21.5C22.5 20.67 23.17 20 24 20C24.83 20 25.5 20.67 25.5 21.5C25.5 22.33 24.83 23 24 23Z"
      fill="#B2B2B2"
    />
  </>
)
export const notificationIcon = createIcon(
  24,
  24,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.45472 12C4.45472 7.93318 7.75153 4.63636 11.8184 4.63636C15.8852 4.63636 19.182 7.93318 19.182 12C19.182 16.0668 15.8852 19.3636 11.8184 19.3636C7.75153 19.3636 4.45472 16.0668 4.45472 12ZM11.8184 3C6.8478 3 2.81836 7.02944 2.81836 12C2.81836 16.9706 6.8478 21 11.8184 21C16.7889 21 20.8184 16.9706 20.8184 12C20.8184 7.02944 16.7889 3 11.8184 3ZM11.8184 7.90909C11.3665 7.90909 11.0002 8.2754 11.0002 8.72727C11.0002 9.17914 11.3665 9.54545 11.8184 9.54545H11.8265C12.2784 9.54545 12.6447 9.17914 12.6447 8.72727C12.6447 8.2754 12.2784 7.90909 11.8265 7.90909H11.8184ZM12.6365 12C12.6365 11.5481 12.2702 11.1818 11.8184 11.1818C11.3665 11.1818 11.0002 11.5481 11.0002 12V15.2727C11.0002 15.7246 11.3665 16.0909 11.8184 16.0909C12.2702 16.0909 12.6365 15.7246 12.6365 15.2727V12Z"
    fill="#878787"
  />
)
export const notificationIconRed = createIcon(
  24,
  24,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.45472 12C4.45472 7.93318 7.75153 4.63636 11.8184 4.63636C15.8852 4.63636 19.182 7.93318 19.182 12C19.182 16.0668 15.8852 19.3636 11.8184 19.3636C7.75153 19.3636 4.45472 16.0668 4.45472 12ZM11.8184 3C6.8478 3 2.81836 7.02944 2.81836 12C2.81836 16.9706 6.8478 21 11.8184 21C16.7889 21 20.8184 16.9706 20.8184 12C20.8184 7.02944 16.7889 3 11.8184 3ZM11.8184 7.90909C11.3665 7.90909 11.0002 8.2754 11.0002 8.72727C11.0002 9.17914 11.3665 9.54545 11.8184 9.54545H11.8265C12.2784 9.54545 12.6447 9.17914 12.6447 8.72727C12.6447 8.2754 12.2784 7.90909 11.8265 7.90909H11.8184ZM12.6365 12C12.6365 11.5481 12.2702 11.1818 11.8184 11.1818C11.3665 11.1818 11.0002 11.5481 11.0002 12V15.2727C11.0002 15.7246 11.3665 16.0909 11.8184 16.0909C12.2702 16.0909 12.6365 15.7246 12.6365 15.2727V12Z"
    fill="#FF4545"
  />
)
export const coin = createIcon(
  16,
  16,
  <path
    d="M10.6251 11.1251C12.9068 10.8197 14.6666 8.86533 14.6666 6.49998C14.6666 3.92265 12.5772 1.83331 9.99992 1.83331C7.63457 1.83331 5.68025 3.59309 5.37476 5.87483M10.6666 10.5C10.6666 13.0773 8.57725 15.1666 5.99992 15.1666C3.42259 15.1666 1.33325 13.0773 1.33325 10.5C1.33325 7.92265 3.42259 5.83331 5.99992 5.83331C8.57725 5.83331 10.6666 7.92265 10.6666 10.5Z"
    stroke="#037B0F"
    strokeWidth="1.4"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)
export const calendar = createIcon(
  20,
  20,
  <path
    d="M17.5 8.83335H2.5M13.3333 2.16669V5.50002M6.66667 2.16669V5.50002M6.5 18.8334H13.5C14.9001 18.8334 15.6002 18.8334 16.135 18.5609C16.6054 18.3212 16.9878 17.9387 17.2275 17.4683C17.5 16.9336 17.5 16.2335 17.5 14.8334V7.83335C17.5 6.43322 17.5 5.73316 17.2275 5.19838C16.9878 4.72797 16.6054 4.34552 16.135 4.10584C15.6002 3.83335 14.9001 3.83335 13.5 3.83335H6.5C5.09987 3.83335 4.3998 3.83335 3.86502 4.10584C3.39462 4.34552 3.01217 4.72797 2.77248 5.19838C2.5 5.73316 2.5 6.43322 2.5 7.83335V14.8334C2.5 16.2335 2.5 16.9336 2.77248 17.4683C3.01217 17.9387 3.39462 18.3212 3.86502 18.5609C4.3998 18.8334 5.09987 18.8334 6.5 18.8334Z"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)
export const payments = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1 4C1 3.44772 1.44772 3 2 3H18C18.5523 3 19 3.44772 19 4V12C19 12.5523 18.5523 13 18 13H2C1.44772 13 1 12.5523 1 12V4ZM13 8C13 9.65685 11.6569 11 10 11C8.34315 11 7 9.65685 7 8C7 6.34315 8.34315 5 10 5C11.6569 5 13 6.34315 13 8ZM4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9ZM17 8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8ZM1.75 14.5C1.33579 14.5 1 14.8358 1 15.25C1 15.6642 1.33579 16 1.75 16C6.16731 16 10.4426 16.6028 14.4987 17.7301C15.6102 18.039 16.75 17.2183 16.75 16.0336V15.25C16.75 14.8358 16.4142 14.5 16 14.5C15.5858 14.5 15.25 14.8358 15.25 15.25V16.0336C15.25 16.1952 15.0861 16.3365 14.9004 16.2849C10.7147 15.1215 6.30435 14.5 1.75 14.5Z"
    fill="#037B0F"
  />
)
export const downloadIcon = createIcon(
  20,
  20,
  <>
    <g clipPath="url(#clip0_1104_744)">
      <path
        d="M15.0125 9.03C14.5025 6.4425 12.23 4.5 9.5 4.5C7.3325 4.5 5.45 5.73 4.5125 7.53C2.255 7.77 0.5 9.6825 0.5 12C0.5 14.4825 2.5175 16.5 5 16.5H14.75C16.82 16.5 18.5 14.82 18.5 12.75C18.5 10.77 16.9625 9.165 15.0125 9.03ZM14.75 15H5C3.3425 15 2 13.6575 2 12C2 10.4625 3.1475 9.18 4.67 9.0225L5.4725 8.94L5.8475 8.2275C6.56 6.855 7.955 6 9.5 6C11.465 6 13.16 7.395 13.5425 9.3225L13.7675 10.4475L14.915 10.53C16.085 10.605 17 11.5875 17 12.75C17 13.9875 15.9875 15 14.75 15ZM10.5875 9H8.4125V11.25H6.5L9.5 14.25L12.5 11.25H10.5875V9Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1104_744">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.5 1.5)"
        />
      </clipPath>
    </defs>
  </>
)

export const edit = createIcon(
  20,
  20,
  <path
    d="M11.7167 7.51667L12.4833 8.28333L4.93333 15.8333H4.16667V15.0667L11.7167 7.51667ZM14.7167 2.5C14.5083 2.5 14.2917 2.58333 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C15.1417 2.575 14.9333 2.5 14.7167 2.5ZM11.7167 5.15833L2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833Z"
    fill="currentColor"
  />
)

export const chatDownloadFile = createIcon(
  40,
  40,
  <>
    <rect width="40" height="40" rx="20" fill="white" />
    <path
      d="M15 26.667H25"
      stroke="#04C53A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9999 23.333L17.0833 20.4163M19.9999 13.333V23.333V13.333ZM19.9999 23.333L22.9166 20.4163L19.9999 23.333Z"
      stroke="#04C53A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export const infoIcon = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.45475 9.00003C2.45475 4.93321 5.75157 1.63639 9.81839 1.63639C13.8852 1.63639 17.182 4.93321 17.182 9.00003C17.182 13.0669 13.8852 16.3637 9.81839 16.3637C5.75157 16.3637 2.45475 13.0669 2.45475 9.00003ZM9.81839 3.05176e-05C4.84783 3.05176e-05 0.81839 4.02947 0.81839 9.00003C0.81839 13.9706 4.84783 18 9.81839 18C14.789 18 18.8184 13.9706 18.8184 9.00003C18.8184 4.02947 14.789 3.05176e-05 9.81839 3.05176e-05ZM9.81839 4.90912C9.36652 4.90912 9.00021 5.27543 9.00021 5.7273C9.00021 6.17917 9.36652 6.54548 9.81839 6.54548H9.82657C10.2784 6.54548 10.6448 6.17917 10.6448 5.7273C10.6448 5.27543 10.2784 4.90912 9.82657 4.90912H9.81839ZM10.6366 9.00003C10.6366 8.54816 10.2703 8.18185 9.81839 8.18185C9.36652 8.18185 9.00021 8.54816 9.00021 9.00003V12.2728C9.00021 12.7246 9.36652 13.0909 9.81839 13.0909C10.2703 13.0909 10.6366 12.7246 10.6366 12.2728V9.00003Z"
  />
)

export const downloadCloud = createIcon(
  20,
  20,
  <path
    d="M10.5 1.70215V4.80005C10.5 5.22009 10.5 5.43011 10.5817 5.59055C10.6537 5.73167 10.7684 5.8464 10.9095 5.91831C11.0699 6.00005 11.28 6.00005 11.7 6.00005H14.7979M6.75 11.25L9 13.5M9 13.5L11.25 11.25M9 13.5L9 9M10.5 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5H11.4C12.6601 16.5 13.2902 16.5 13.7715 16.2548C14.1948 16.039 14.539 15.6948 14.7548 15.2715C15 14.7902 15 14.1601 15 12.9V6L10.5 1.5Z"
    stroke="black"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export const bell = createIcon(
  20,
  20,
  <path
    d="M7.51571 15.75C8.04456 16.2168 8.73924 16.5 9.50008 16.5C10.2609 16.5 10.9556 16.2168 11.4844 15.75M14.0001 6C14.0001 4.80653 13.526 3.66193 12.6821 2.81802C11.8381 1.97411 10.6935 1.5 9.50008 1.5C8.3066 1.5 7.16201 1.97411 6.31809 2.81802C5.47418 3.66193 5.00007 4.80653 5.00007 6C5.00007 8.31764 4.41543 9.90447 3.76232 10.9541C3.21142 11.8394 2.93597 12.2821 2.94607 12.4056C2.95725 12.5423 2.98622 12.5944 3.09641 12.6762C3.19592 12.75 3.64452 12.75 4.54172 12.75H14.4584C15.3556 12.75 15.8042 12.75 15.9037 12.6762C16.0139 12.5944 16.0429 12.5423 16.0541 12.4056C16.0642 12.2821 15.7887 11.8394 15.2378 10.9541C14.5847 9.90447 14.0001 8.31764 14.0001 6Z"
    stroke="black"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export const helpIcon = createIcon(
  20,
  20,
  <path
    d="M7.57496 8.00001C7.77088 7.44306 8.15759 6.97343 8.66659 6.67428C9.17559 6.37514 9.77404 6.26579 10.3559 6.3656C10.9378 6.46541 11.4656 6.76794 11.8459 7.21961C12.2261 7.67128 12.4342 8.24294 12.4333 8.83334C12.4333 10.5 9.93329 11.3333 9.93329 11.3333M9.99996 14.6667H10.0083M18.3333 10.5C18.3333 15.1024 14.6023 18.8333 9.99996 18.8333C5.39759 18.8333 1.66663 15.1024 1.66663 10.5C1.66663 5.89763 5.39759 2.16667 9.99996 2.16667C14.6023 2.16667 18.3333 5.89763 18.3333 10.5Z"
    stroke="#878787"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="#fff"
  />
)

export const signIcon = createIcon(
  16,
  16,
  <path
    d="M14.5 12.0001L13.8332 12.7294C13.4796 13.1162 13.0001 13.3334 12.5001 13.3334C12.0001 13.3334 11.5205 13.1162 11.1669 12.7294C10.8128 12.3435 10.3333 12.1268 9.83345 12.1268C9.33359 12.1268 8.85409 12.3435 8.49998 12.7294M2.5 13.3334H3.61636C3.94248 13.3334 4.10554 13.3334 4.25899 13.2966C4.39504 13.2639 4.5251 13.21 4.6444 13.1369C4.77895 13.0545 4.89425 12.9392 5.12486 12.7086L13.5 4.3334C14.0523 3.78112 14.0523 2.88569 13.5 2.3334C12.9477 1.78112 12.0523 1.78112 11.5 2.3334L3.12484 10.7086C2.89424 10.9392 2.77894 11.0545 2.69648 11.189C2.62338 11.3083 2.5695 11.4384 2.53684 11.5744C2.5 11.7279 2.5 11.8909 2.5 12.2171V13.3334Z"
    stroke="white"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export const menuSignIcon = createIcon(
  20,
  20,

  <path
    d="M17.5 15.5L16.6666 16.4117C16.2245 16.8951 15.6251 17.1667 15.0001 17.1667C14.3751 17.1667 13.7757 16.8951 13.3337 16.4117C12.891 15.9293 12.2916 15.6584 11.6668 15.6584C11.042 15.6584 10.4426 15.9293 9.99998 16.4117M2.5 17.1667H3.89545C4.3031 17.1667 4.50693 17.1667 4.69874 17.1206C4.8688 17.0798 5.03138 17.0125 5.1805 16.9211C5.34869 16.818 5.49282 16.6739 5.78107 16.3856L16.25 5.91669C16.9404 5.22634 16.9404 4.10705 16.25 3.41669C15.5597 2.72634 14.4404 2.72634 13.75 3.41669L3.28105 13.8856C2.9928 14.1739 2.84867 14.318 2.7456 14.4862C2.65422 14.6353 2.58688 14.7979 2.54605 14.968C2.5 15.1598 2.5 15.3636 2.5 15.7713V17.1667Z"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export const overview = createIcon(
  20,
  20,
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 10.25C0.875 6.45304 3.95304 3.375 7.75 3.375C8.09518 3.375 8.375 3.65482 8.375 4V9.625H14C14.3452 9.625 14.625 9.90482 14.625 10.25C14.625 14.047 11.547 17.125 7.75 17.125C3.95304 17.125 0.875 14.047 0.875 10.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.625 1.5C9.625 1.15482 9.90482 0.875 10.25 0.875C14.047 0.875 17.125 3.95304 17.125 7.75C17.125 8.09518 16.8452 8.375 16.5 8.375H10.25C9.90482 8.375 9.625 8.09518 9.625 7.75V1.5Z"
    />
  </>
)

export const team = createIcon(
  20,
  20,
  <>
    <path d="M9 6C10.6569 6 12 4.65685 12 3C12 1.34315 10.6569 0 9 0C7.34315 0 6 1.34315 6 3C6 4.65685 7.34315 6 9 6Z" />
    <path d="M5 5C5 6.10457 4.10457 7 3 7C1.89543 7 1 6.10457 1 5C1 3.89543 1.89543 3 3 3C4.10457 3 5 3.89543 5 5Z" />
    <path d="M0.490644 12.3257C0.321065 12.2271 0.190213 12.0718 0.132468 11.8843C0.0463562 11.6048 0 11.3078 0 11C0 9.34315 1.34315 8 3 8C3.522 8 4.01287 8.13332 4.4404 8.36777C3.39329 9.39885 2.69414 10.7825 2.53478 12.3267C2.5118 12.5494 2.52139 12.7692 2.55996 12.9809C1.81061 12.9156 1.10861 12.6849 0.490644 12.3257Z" />
    <path d="M15.4405 12.9809C16.1897 12.9155 16.8915 12.6849 17.5094 12.3257C17.6789 12.2271 17.8098 12.0718 17.8675 11.8843C17.9536 11.6048 18 11.3078 18 11C18 9.34315 16.6569 8 15 8C14.4781 8 13.9873 8.13326 13.5599 8.36762C14.6071 9.39872 15.3063 10.7824 15.4656 12.3267C15.4886 12.5494 15.479 12.7692 15.4405 12.9809Z" />
    <path d="M17 5C17 6.10457 16.1046 7 15 7C13.8954 7 13 6.10457 13 5C13 3.89543 13.8954 3 15 3C16.1046 3 17 3.89543 17 5Z" />
    <path d="M4.30383 13.1909C4.10473 13.0106 3.99922 12.7478 4.02679 12.4807C4.28657 9.96329 6.41408 8 9.00015 8C11.5862 8 13.7137 9.96329 13.9735 12.4807C14.0011 12.7478 13.8956 13.0107 13.6965 13.1909C12.4545 14.3152 10.8073 15 9.00015 15C7.19298 15 5.54576 14.3152 4.30383 13.1909Z" />
  </>
)

export const timeOff = createIcon(
  20,
  20,
  <>
    <path d="M9.625 9.625C9.625 9.97018 9.34518 10.25 9 10.25C8.65482 10.25 8.375 9.97018 8.375 9.625C8.375 9.27982 8.65482 9 9 9C9.34518 9 9.625 9.27982 9.625 9.625Z" />
    <path d="M5.25 12.125C5.59518 12.125 5.875 11.8452 5.875 11.5C5.875 11.1548 5.59518 10.875 5.25 10.875C4.90482 10.875 4.625 11.1548 4.625 11.5C4.625 11.8452 4.90482 12.125 5.25 12.125Z" />
    <path d="M5.875 13.375C5.875 13.7202 5.59518 14 5.25 14C4.90482 14 4.625 13.7202 4.625 13.375C4.625 13.0298 4.90482 12.75 5.25 12.75C5.59518 12.75 5.875 13.0298 5.875 13.375Z" />
    <path d="M7.125 12.125C7.47018 12.125 7.75 11.8452 7.75 11.5C7.75 11.1548 7.47018 10.875 7.125 10.875C6.77982 10.875 6.5 11.1548 6.5 11.5C6.5 11.8452 6.77982 12.125 7.125 12.125Z" />
    <path d="M7.75 13.375C7.75 13.7202 7.47018 14 7.125 14C6.77982 14 6.5 13.7202 6.5 13.375C6.5 13.0298 6.77982 12.75 7.125 12.75C7.47018 12.75 7.75 13.0298 7.75 13.375Z" />
    <path d="M9 12.125C9.34518 12.125 9.625 11.8452 9.625 11.5C9.625 11.1548 9.34518 10.875 9 10.875C8.65482 10.875 8.375 11.1548 8.375 11.5C8.375 11.8452 8.65482 12.125 9 12.125Z" />
    <path d="M9.625 13.375C9.625 13.7202 9.34518 14 9 14C8.65482 14 8.375 13.7202 8.375 13.375C8.375 13.0298 8.65482 12.75 9 12.75C9.34518 12.75 9.625 13.0298 9.625 13.375Z" />
    <path d="M10.875 12.125C11.2202 12.125 11.5 11.8452 11.5 11.5C11.5 11.1548 11.2202 10.875 10.875 10.875C10.5298 10.875 10.25 11.1548 10.25 11.5C10.25 11.8452 10.5298 12.125 10.875 12.125Z" />
    <path d="M11.5 13.375C11.5 13.7202 11.2202 14 10.875 14C10.5298 14 10.25 13.7202 10.25 13.375C10.25 13.0298 10.5298 12.75 10.875 12.75C11.2202 12.75 11.5 13.0298 11.5 13.375Z" />
    <path d="M12.75 12.125C13.0952 12.125 13.375 11.8452 13.375 11.5C13.375 11.1548 13.0952 10.875 12.75 10.875C12.4048 10.875 12.125 11.1548 12.125 11.5C12.125 11.8452 12.4048 12.125 12.75 12.125Z" />
    <path d="M11.5 9.625C11.5 9.97018 11.2202 10.25 10.875 10.25C10.5298 10.25 10.25 9.97018 10.25 9.625C10.25 9.27982 10.5298 9 10.875 9C11.2202 9 11.5 9.27982 11.5 9.625Z" />
    <path d="M12.75 10.25C13.0952 10.25 13.375 9.97018 13.375 9.625C13.375 9.27982 13.0952 9 12.75 9C12.4048 9 12.125 9.27982 12.125 9.625C12.125 9.97018 12.4048 10.25 12.75 10.25Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.625 0.875C4.97018 0.875 5.25 1.15482 5.25 1.5V2.75H12.75V1.5C12.75 1.15482 13.0298 0.875 13.375 0.875C13.7202 0.875 14 1.15482 14 1.5V2.75H14.625C16.0057 2.75 17.125 3.86929 17.125 5.25V14.625C17.125 16.0057 16.0057 17.125 14.625 17.125H3.375C1.99429 17.125 0.875 16.0057 0.875 14.625V5.25C0.875 3.86929 1.99429 2.75 3.375 2.75H4V1.5C4 1.15482 4.27982 0.875 4.625 0.875ZM15.875 8.375C15.875 7.68464 15.3154 7.125 14.625 7.125H3.375C2.68464 7.125 2.125 7.68464 2.125 8.375V14.625C2.125 15.3154 2.68464 15.875 3.375 15.875H14.625C15.3154 15.875 15.875 15.3154 15.875 14.625V8.375Z"
    />
  </>
)

export const expenses = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.93005 2.31046C6.5916 2.10551 8.28365 2 10 2C11.7164 2 13.4084 2.10551 15.07 2.31046C16.1942 2.44913 17 3.41374 17 4.51661V17.25C17 17.5078 16.8676 17.7475 16.6494 17.8848C16.4312 18.0221 16.1579 18.0377 15.9255 17.9261L13.125 16.5819L10.3245 17.9261C10.1194 18.0246 9.88061 18.0246 9.67545 17.9261L6.875 16.5819L4.07455 17.9261C3.84215 18.0377 3.56875 18.0221 3.35057 17.8848C3.13239 17.7475 3 17.5078 3 17.25V4.51661C3 3.41374 3.80579 2.44913 4.93005 2.31046ZM9.75172 6.30747C10.0596 6.03038 10.0846 5.55616 9.80747 5.24828C9.53038 4.94039 9.05616 4.91544 8.74828 5.19253L6.24828 7.44253C6.09024 7.58476 6 7.78738 6 8C6 8.21262 6.09024 8.41524 6.24828 8.55747L8.74828 10.8075C9.05616 11.0846 9.53038 11.0596 9.80747 10.7517C10.0846 10.4438 10.0596 9.96962 9.75172 9.69253L8.70447 8.75H10.625C11.6605 8.75 12.5 9.58947 12.5 10.625C12.5 11.6605 11.6605 12.5 10.625 12.5C10.2108 12.5 9.875 12.8358 9.875 13.25C9.875 13.6642 10.2108 14 10.625 14C12.489 14 14 12.489 14 10.625C14 8.76104 12.489 7.25 10.625 7.25H8.70447L9.75172 6.30747Z"
    fill="#878787"
  />
)

export const salaryRevision = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 1.5C10.215 1.5 11.4171 1.55484 12.6038 1.66214C12.9249 1.69118 13.1802 1.93931 13.2185 2.25934C13.3426 3.29702 13.4265 4.34718 13.4685 5.40824L11.7804 3.71973C11.4875 3.4268 11.0126 3.42675 10.7197 3.71961C10.4268 4.01247 10.4267 4.48735 10.7196 4.78027L13.7189 7.78017C14.0117 8.07309 14.4866 8.07315 14.7795 7.78031L17.7803 4.78041C18.0732 4.48755 18.0733 4.01268 17.7804 3.71975C17.4876 3.42681 17.0127 3.42674 16.7197 3.71959L14.9719 5.46698C14.9299 4.32601 14.8413 3.19681 14.7079 2.08123C14.5855 1.05775 13.7654 0.261055 12.7389 0.168238C11.5073 0.0568784 10.2602 0 9 0C7.73981 0 6.49271 0.0568784 5.26115 0.168238C4.23465 0.261055 3.41449 1.05775 3.2921 2.08123C3.22831 2.61464 3.17477 3.15117 3.13167 3.69061C3.09868 4.10351 3.40666 4.46497 3.81956 4.49796C4.23245 4.53095 4.59392 4.22297 4.6269 3.81007C4.66843 3.29024 4.72003 2.77326 4.78148 2.25934C4.81976 1.93931 5.07514 1.69118 5.39623 1.66214C6.58294 1.55484 7.78497 1.5 9 1.5ZM4.28113 6.22C3.98828 5.9271 3.51345 5.92705 3.22052 6.21987L0.219763 9.21958C-0.0731814 9.51242 -0.0732653 9.98729 0.219578 10.2802C0.512419 10.5732 0.987293 10.5733 1.28024 10.2804L3.02815 8.53313C3.0701 9.67406 3.15869 10.8032 3.2921 11.9188C3.41449 12.9423 4.23465 13.7389 5.26115 13.8318C6.49271 13.9431 7.73981 14 9 14C10.2602 14 11.5073 13.9431 12.7389 13.8318C13.7654 13.7389 14.5855 12.9423 14.7079 11.9188C14.7717 11.3849 14.8253 10.848 14.8684 10.3081C14.9014 9.89523 14.5934 9.53377 14.1805 9.5008C13.7676 9.46782 13.4062 9.77581 13.3732 10.1887C13.3316 10.709 13.28 11.2263 13.2185 11.7407C13.1802 12.0607 12.9249 12.3088 12.6038 12.3379C11.4171 12.4452 10.215 12.5 9 12.5C7.78497 12.5 6.58294 12.4452 5.39623 12.3379C5.07514 12.3088 4.81976 12.0607 4.78148 11.7407C4.6574 10.703 4.57352 9.65289 4.53148 8.59188L6.21963 10.2803C6.5125 10.5732 6.98737 10.5732 7.28029 10.2804C7.57321 9.9875 7.57324 9.51263 7.28037 9.21971L4.28113 6.22Z"
  />
)
export const payroll = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1 4C1 3.44772 1.44772 3 2 3H18C18.5523 3 19 3.44772 19 4V12C19 12.5523 18.5523 13 18 13H2C1.44772 13 1 12.5523 1 12V4ZM13 8C13 9.65685 11.6569 11 10 11C8.34315 11 7 9.65685 7 8C7 6.34315 8.34315 5 10 5C11.6569 5 13 6.34315 13 8ZM4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9ZM17 8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8ZM1.75 14.5C1.33579 14.5 1 14.8358 1 15.25C1 15.6642 1.33579 16 1.75 16C6.16731 16 10.4426 16.6028 14.4987 17.7301C15.6102 18.039 16.75 17.2183 16.75 16.0336V15.25C16.75 14.8358 16.4142 14.5 16 14.5C15.5858 14.5 15.25 14.8358 15.25 15.25V16.0336C15.25 16.1952 15.0861 16.3365 14.9004 16.2849C10.7147 15.1215 6.30435 14.5 1.75 14.5Z"
  />
)
export const invoices = createIcon(
  20,
  20,
  <>
    <path d="M4.25 1.8125C4.25 0.949555 4.94956 0.25 5.8125 0.25H6.125C7.85089 0.25 9.25 1.64911 9.25 3.375V4.9375C9.25 5.80045 9.94956 6.5 10.8125 6.5H12.375C14.1009 6.5 15.5 7.89911 15.5 9.625V12.4375C15.5 13.3004 14.8004 14 13.9375 14H5.8125C4.94955 14 4.25 13.3004 4.25 12.4375V1.8125Z" />
    <path d="M10.5 3.375C10.5 2.28079 10.0983 1.2804 9.43433 0.513254C12.2668 1.25315 14.4969 3.48323 15.2367 6.31567C14.4696 5.6517 13.4692 5.25 12.375 5.25H10.8125C10.6399 5.25 10.5 5.11009 10.5 4.9375V3.375Z" />
    <path d="M2.0625 4H3V12.4375C3 13.9908 4.2592 15.25 5.8125 15.25H11.75V16.1875C11.75 17.0504 11.0504 17.75 10.1875 17.75H2.0625C1.19956 17.75 0.5 17.0504 0.5 16.1875V5.5625C0.5 4.69955 1.19956 4 2.0625 4Z" />
  </>
)
export const paymentMethods = createIcon(
  20,
  20,
  <>
    <path d="M2.75 0.125C1.36929 0.125 0.25 1.24429 0.25 2.625V3.25H17.75V2.625C17.75 1.24429 16.6307 0.125 15.25 0.125H2.75Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.75 5.125H0.25V11.375C0.25 12.7557 1.36929 13.875 2.75 13.875H15.25C16.6307 13.875 17.75 12.7557 17.75 11.375V5.125ZM2.75 8.25C2.75 7.90482 3.02982 7.625 3.375 7.625H8.375C8.72018 7.625 9 7.90482 9 8.25C9 8.59518 8.72018 8.875 8.375 8.875H3.375C3.02982 8.875 2.75 8.59518 2.75 8.25ZM3.375 10.125C3.02982 10.125 2.75 10.4048 2.75 10.75C2.75 11.0952 3.02982 11.375 3.375 11.375H5.875C6.22018 11.375 6.5 11.0952 6.5 10.75C6.5 10.4048 6.22018 10.125 5.875 10.125H3.375Z"
    />
  </>
)
export const payContractors = createIcon(
  20,
  20,
  <>
    <path d="M7.71967 6.28855C7.90901 6.13814 8.13392 6.02876 8.375 5.96041V8.28954C8.12931 8.21978 7.90503 8.10869 7.71967 7.96145C7.39156 7.7008 7.2646 7.39413 7.2646 7.125C7.2646 6.85587 7.39156 6.5492 7.71967 6.28855Z" />
    <path d="M9.625 12.0513V9.69861C9.91437 9.76898 10.1781 9.88816 10.3928 10.0491C10.7482 10.3157 10.875 10.6207 10.875 10.875C10.875 11.1292 10.7482 11.4343 10.3928 11.7008C10.1781 11.8618 9.91437 11.981 9.625 12.0513Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.875C4.51269 0.875 0.875 4.51269 0.875 9C0.875 13.4873 4.51269 17.125 9 17.125C13.4873 17.125 17.125 13.4873 17.125 9C17.125 4.51269 13.4873 0.875 9 0.875ZM9.625 4C9.625 3.65482 9.34518 3.375 9 3.375C8.65482 3.375 8.375 3.65482 8.375 4V4.67969C7.85259 4.77224 7.35444 4.98228 6.94215 5.3098C6.34852 5.78138 6.0146 6.43442 6.0146 7.125C6.0146 7.81558 6.34852 8.46862 6.94215 8.9402C7.36087 9.27283 7.86055 9.47918 8.375 9.57028V12.0513C8.0857 11.9809 7.822 11.8618 7.6074 11.7008L6.87501 11.1515C6.59887 10.9444 6.20712 11.0003 6.00001 11.2765C5.7929 11.5526 5.84885 11.9444 6.12499 12.1515L6.85738 12.7008C7.30172 13.0341 7.83162 13.238 8.375 13.3253V14C8.375 14.3452 8.65482 14.625 9 14.625C9.34518 14.625 9.625 14.3452 9.625 14V13.3253C10.1685 13.238 10.6984 13.0341 11.1428 12.7008C11.7637 12.2351 12.125 11.5804 12.125 10.875C12.125 10.1695 11.7637 9.51484 11.1428 9.04916C10.6984 8.71586 10.1685 8.51194 9.625 8.42465V5.96047C9.86599 6.02883 10.0908 6.13819 10.2801 6.28855L10.6258 6.56321C10.8961 6.77792 11.2893 6.73287 11.504 6.46259C11.7187 6.19232 11.6736 5.79916 11.4034 5.58445L11.0576 5.3098C10.6454 4.98233 10.1473 4.7723 9.625 4.67973V4Z"
    />
  </>
)
export const agreements = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.43306 12.9168L5.69485 9.76231C5.89603 9.25935 6.19728 8.80249 6.58033 8.41945L13.4995 1.50071C14.3279 0.672287 15.6711 0.672289 16.4995 1.50072C17.3279 2.32914 17.3279 3.67229 16.4995 4.50072L9.58033 11.4194C9.19728 11.8025 8.74042 12.1037 8.23746 12.3049L5.08299 13.5667C4.67484 13.73 4.2698 13.3249 4.43306 12.9168ZM1.5 4.75C1.5 4.05964 2.05964 3.5 2.75 3.5H9C9.41421 3.5 9.75 3.16421 9.75 2.75C9.75 2.33579 9.41421 2 9 2H2.75C1.23122 2 0 3.23122 0 4.75V15.25C0 16.7688 1.23122 18 2.75 18H13.25C14.7688 18 16 16.7688 16 15.25V9C16 8.58579 15.6642 8.25 15.25 8.25C14.8358 8.25 14.5 8.58579 14.5 9V15.25C14.5 15.9404 13.9404 16.5 13.25 16.5H2.75C2.05964 16.5 1.5 15.9404 1.5 15.25V4.75Z"
  />
)
export const support = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.93934 4.93931C6.64645 5.23221 6.17157 5.23221 5.87868 4.93931C5.58579 4.64642 5.58579 4.17155 5.87868 3.87865C7.05025 2.70708 8.94975 2.70708 10.1213 3.87865C11.2929 5.05023 11.2929 6.94972 10.1213 8.12129C9.72884 8.51378 9.25285 8.77563 8.75 8.90508V9.24997C8.75 9.66418 8.41421 9.99997 8 9.99997C7.58579 9.99997 7.25 9.66418 7.25 9.24997V8.74997C7.25 8.02968 7.81995 7.57826 8.33134 7.46322C8.59823 7.40318 8.8516 7.26969 9.06066 7.06063C9.64645 6.47485 9.64645 5.5251 9.06066 4.93931C8.47487 4.35353 7.52513 4.35353 6.93934 4.93931ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44771 11 7 11.4477 7 12C7 12.5523 7.44771 13 8 13Z"
  />
)
export const connect = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8 0C3.69006 0 0 3.0334 0 7C0 9.02442 0.978492 10.8253 2.49899 12.0848C2.48371 12.7294 2.29476 13.329 1.97742 13.8409C1.83914 14.064 1.82753 14.3431 1.9468 14.5769C2.06608 14.8107 2.29888 14.9651 2.56065 14.9841C2.70585 14.9947 2.85237 15 3 15C4.3037 15 5.51177 14.5834 6.49617 13.8766C6.98381 13.9577 7.48658 14 8 14C12.3099 14 16 10.9666 16 7C16 3.0334 12.3099 0 8 0ZM8 8C8.55229 8 9 7.55228 9 7C9 6.44772 8.55229 6 8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8ZM6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
  />
)
export const user = createIcon(
  20,
  20,
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM10.5 5.5C10.5 6.88071 9.38071 8 8 8C6.61929 8 5.5 6.88071 5.5 5.5C5.5 4.11929 6.61929 3 8 3C9.38071 3 10.5 4.11929 10.5 5.5ZM8 10C6.04133 10 4.30187 10.9385 3.20679 12.3904C4.39509 13.687 6.1026 14.5 8 14.5C9.8974 14.5 11.6049 13.687 12.7932 12.3904C11.6981 10.9385 9.95867 10 8 10Z"
  />
)
export const dots = createIcon(
  20,
  20,
  <>
    <path
      d="M5.625 10C5.625 10.3452 5.34518 10.625 5 10.625C4.65482 10.625 4.375 10.3452 4.375 10C4.375 9.65482 4.65482 9.375 5 9.375C5.34518 9.375 5.625 9.65482 5.625 10Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 10C10.625 10.3452 10.3452 10.625 10 10.625C9.65482 10.625 9.375 10.3452 9.375 10C9.375 9.65482 9.65482 9.375 10 9.375C10.3452 9.375 10.625 9.65482 10.625 10Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 10C15.625 10.3452 15.3452 10.625 15 10.625C14.6548 10.625 14.375 10.3452 14.375 10C14.375 9.65482 14.6548 9.375 15 9.375C15.3452 9.375 15.625 9.65482 15.625 10Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export const requiredDocuments = createIcon(
  20,
  20,
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6875 1.25C3.82456 1.25 3.125 1.94955 3.125 2.8125V17.1875C3.125 18.0504 3.82455 18.75 4.6875 18.75H15.3125C16.1754 18.75 16.875 18.0504 16.875 17.1875V10.625C16.875 8.89911 15.4759 7.5 13.75 7.5H12.1875C11.3246 7.5 10.625 6.80044 10.625 5.9375V4.375C10.625 2.64911 9.22589 1.25 7.5 1.25H4.6875ZM6.25 12.5C6.25 12.1548 6.52982 11.875 6.875 11.875H13.125C13.4702 11.875 13.75 12.1548 13.75 12.5C13.75 12.8452 13.4702 13.125 13.125 13.125H6.875C6.52982 13.125 6.25 12.8452 6.25 12.5ZM6.875 14.375C6.52982 14.375 6.25 14.6548 6.25 15C6.25 15.3452 6.52982 15.625 6.875 15.625H10C10.3452 15.625 10.625 15.3452 10.625 15C10.625 14.6548 10.3452 14.375 10 14.375H6.875Z"
    />
    <path d="M10.8093 1.51325C11.4733 2.2804 11.875 3.28079 11.875 4.375V5.9375C11.875 6.11009 12.0149 6.25 12.1875 6.25H13.75C14.8442 6.25 15.8446 6.6517 16.6117 7.31567C15.8719 4.48323 13.6418 2.25315 10.8093 1.51325Z" />
  </>
)
export const contractTemplates = createIcon(
  20,
  20,
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9999 5.25C17.9999 4.08761 17.1185 3.1311 15.9875 3.0124C15.8688 1.88145 14.9123 1 13.7499 1H12.2499C11.0875 1 10.131 1.88145 10.0123 3.0124C9.13721 3.10424 8.4115 3.69769 8.12793 4.5H10.9999C12.3806 4.5 13.4999 5.61929 13.4999 7V14H15.7499C16.9925 14 17.9999 12.9926 17.9999 11.75V5.25ZM12.2499 2.5C11.8357 2.5 11.4999 2.83579 11.4999 3.25V3.5H14.4999V3.25C14.4999 2.83579 14.1641 2.5 13.7499 2.5H12.2499Z"
      fill="#878787"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C2.44772 6 2 6.44771 2 7V17C2 17.5523 2.44772 18 3 18H11C11.5523 18 12 17.5523 12 17V7C12 6.44772 11.5523 6 11 6H3ZM9.87404 10.166C10.1038 9.82138 10.0107 9.35573 9.66603 9.12596C9.32138 8.8962 8.85573 8.98933 8.62596 9.33397L6.13343 13.0728L5.28033 12.2197C4.98744 11.9268 4.51256 11.9268 4.21967 12.2197C3.92678 12.5126 3.92678 12.9874 4.21967 13.2803L5.71967 14.7803C5.87855 14.9392 6.1003 15.0185 6.3239 14.9963C6.5475 14.9742 6.7494 14.853 6.87404 14.666L9.87404 10.166Z"
      fill="#878787"
    />
  </>
)
export const payslips = createIcon(
  20,
  20,
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25153 5H9.37305L9.375 5H12.1915C13.7429 5.00223 14.9999 6.26058 14.9999 7.8125V15.625C16.3806 15.625 17.4999 14.5057 17.4999 13.125V5.09018C17.4999 3.83568 16.5626 2.74717 15.2799 2.6407C15.0934 2.62522 14.9066 2.61101 14.7194 2.59806C14.3028 1.79706 13.4653 1.25 12.5 1.25H11.25C10.2847 1.25 9.44724 1.79706 9.03064 2.59804C8.84339 2.611 8.65649 2.62522 8.46995 2.6407C7.21804 2.74461 6.29518 3.78391 6.25153 5ZM11.25 2.5C10.5596 2.5 10 3.05964 10 3.75H13.75C13.75 3.05964 13.1904 2.5 12.5 2.5H11.25Z"
      fill="#878787"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 7.8125C2.5 6.94956 3.19956 6.25 4.0625 6.25H12.1875C13.0504 6.25 13.75 6.94956 13.75 7.8125V17.1875C13.75 18.0504 13.0504 18.75 12.1875 18.75H4.0625C3.19956 18.75 2.5 18.0504 2.5 17.1875V7.8125ZM5 10C5 9.65482 5.27982 9.375 5.625 9.375H5.63125C5.97643 9.375 6.25625 9.65482 6.25625 10V10.0063C6.25625 10.3514 5.97643 10.6313 5.63125 10.6313H5.625C5.27982 10.6313 5 10.3514 5 10.0063V10ZM6.875 10C6.875 9.65482 7.15482 9.375 7.5 9.375H10.625C10.9702 9.375 11.25 9.65482 11.25 10C11.25 10.3452 10.9702 10.625 10.625 10.625H7.5C7.15482 10.625 6.875 10.3452 6.875 10ZM5 12.5C5 12.1548 5.27982 11.875 5.625 11.875H5.63125C5.97643 11.875 6.25625 12.1548 6.25625 12.5V12.5063C6.25625 12.8514 5.97643 13.1313 5.63125 13.1313H5.625C5.27982 13.1313 5 12.8514 5 12.5063V12.5ZM6.875 12.5C6.875 12.1548 7.15482 11.875 7.5 11.875H10.625C10.9702 11.875 11.25 12.1548 11.25 12.5C11.25 12.8452 10.9702 13.125 10.625 13.125H7.5C7.15482 13.125 6.875 12.8452 6.875 12.5ZM5 15C5 14.6548 5.27982 14.375 5.625 14.375H5.63125C5.97643 14.375 6.25625 14.6548 6.25625 15V15.0063C6.25625 15.3514 5.97643 15.6313 5.63125 15.6313H5.625C5.27982 15.6313 5 15.3514 5 15.0063V15ZM6.875 15C6.875 14.6548 7.15482 14.375 7.5 14.375H10.625C10.9702 14.375 11.25 14.6548 11.25 15C11.25 15.3452 10.9702 15.625 10.625 15.625H7.5C7.15482 15.625 6.875 15.3452 6.875 15Z"
      fill="#878787"
    />
  </>
)
export const successIcon = createIcon(
  20,
  20,
  <>
    <rect y="0.5" width="18" height="18" rx="9" fill="#04C53A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.375 5.90039C12.0645 5.90039 11.7832 6.02639 11.5796 6.23002L10.4546 7.35502L7.875 9.93464L6.42037 8.48002C6.21675 8.27639 5.9355 8.15039 5.625 8.15039C5.004 8.15039 4.5 8.65439 4.5 9.27539C4.5 9.58589 4.626 9.86714 4.82963 10.0708L7.07962 12.3208C7.28325 12.5244 7.5645 12.6504 7.875 12.6504C8.1855 12.6504 8.46675 12.5244 8.67037 12.3208L12.0454 8.94577L13.1704 7.82077C13.374 7.61714 13.5 7.33589 13.5 7.02539C13.5 6.40383 12.996 5.90039 12.375 5.90039Z"
      fill="white"
    />
  </>
)

export const errorIcon = createIcon(
  20,
  20,
  <>
    <rect y="0.5" width="18" height="18" rx="9" fill="#FF4545" />
    <path
      d="M11.3996 7.09961L6.59961 11.8996M6.59961 7.09961L11.3996 11.8996"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export const copyIcon = createIcon(
  20,
  20,
  <path
    d="M5.503 4.627L5.5 6.75V17.254C5.5 18.116 5.84241 18.9426 6.4519 19.5521C7.0614 20.1616 7.88805 20.504 8.75 20.504H17.366C17.2107 20.9427 16.9233 21.3225 16.5433 21.5911C16.1633 21.8598 15.7094 22.004 15.244 22.004H8.75C7.49022 22.004 6.28204 21.5036 5.39124 20.6128C4.50045 19.722 4 18.5138 4 17.254V6.75C4 5.77 4.627 4.935 5.503 4.627ZM17.75 2C18.0455 2 18.3381 2.0582 18.611 2.17127C18.884 2.28434 19.1321 2.45008 19.341 2.65901C19.5499 2.86794 19.7157 3.11598 19.8287 3.38896C19.9418 3.66194 20 3.95453 20 4.25V17.25C20 17.5455 19.9418 17.8381 19.8287 18.111C19.7157 18.384 19.5499 18.6321 19.341 18.841C19.1321 19.0499 18.884 19.2157 18.611 19.3287C18.3381 19.4418 18.0455 19.5 17.75 19.5H8.75C8.15326 19.5 7.58097 19.2629 7.15901 18.841C6.73705 18.419 6.5 17.8467 6.5 17.25V4.25C6.5 3.65326 6.73705 3.08097 7.15901 2.65901C7.58097 2.23705 8.15326 2 8.75 2H17.75ZM17.75 3.5H8.75C8.55109 3.5 8.36032 3.57902 8.21967 3.71967C8.07902 3.86032 8 4.05109 8 4.25V17.25C8 17.664 8.336 18 8.75 18H17.75C17.9489 18 18.1397 17.921 18.2803 17.7803C18.421 17.6397 18.5 17.4489 18.5 17.25V4.25C18.5 4.05109 18.421 3.86032 18.2803 3.71967C18.1397 3.57902 17.9489 3.5 17.75 3.5Z"
    fill="#262842"
  />
)
