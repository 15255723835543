import React from 'react'
import { useForm } from 'react-hook-form'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import { EditForm } from '@atoms/EditModal/EditModalForm'
import TextArea from '@atoms/TextArea/TextArea'

export const PaymentDetailsForm = ({ data, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      iban: data.iban || '',
      bic: data.bic || '',
      holder_name: data.holder_name || '',
      bank_name: data.bank_name || '',
      account_number: data.account_number || '',
      additional_info: data.additional_info || '',
      tax_id: data.tax_id || '',
    },
  })

  const submit = (formValues) => {
    onSubmit(formValues)
  }

  return (
    <EditForm
      className="d-flex flex-column h-100"
      onSubmit={handleSubmit(submit)}
    >
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <div className="remo-form-input">
            <Input
              {...register('account_number', {
                required: 'Account number is required',
              })}
              type="text"
              label="Account Number"
              placeholder="Enter your account number"
              isRequired
            />
            {errors.account_number && (
              <Typography className="text_regular__14 color_red">
                {errors.account_number.message}
              </Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              {...register('holder_name', {
                required: 'Account holder name is required',
              })}
              type="text"
              label="Account holder name"
              placeholder="Enter your name"
              isRequired
            />
            {errors.holder_name && (
              <Typography className="text_regular__14 color_red">
                {errors.holder_name.message}
              </Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              {...register('bank_name', {
                required: 'Bank name is required',
              })}
              type="text"
              label="Bank Name"
              placeholder="Enter bank name"
              isRequired
            />
            {errors.bank_name && (
              <Typography className="text_regular__14 color_red">
                {errors.bank_name.message}
              </Typography>
            )}
          </div>

          <div className="remo-form-input">
            <TextArea
              {...register('additional_info', {
                required: 'Additional information is required',
              })}
              maxLength={4000}
              label="Additional information"
              placeholder="Please, provide your country specific bank details (sort code/routing number and others)"
              isRequired
            />

            {errors.additional_info && (
              <Typography className="text_regular__14 color_red">
                {errors.additional_info.message}
              </Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              {...register('bic')}
              type="text"
              label="BIC / SWIFT"
              placeholder="Enter your valid BIC / SWIFT Identification Code"
            />
            {errors.bic && (
              <Typography className="text_regular__14 color_red">
                {errors.bic.message}
              </Typography>
            )}
          </div>

          <Input
            {...register('iban')}
            type="text"
            label="IBAN"
            placeholder="Enter your valid IBAN account number"
          />
          {errors.iban && (
            <Typography className="text_regular__14 color_red">
              {errors.iban.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('tax_id')}
            type="text"
            label="Tax ID"
            placeholder="Enter your Tax ID"
          />
          {errors.tax_id && (
            <Typography className="text_regular__14 color_red">
              {errors.tax_id.message}
            </Typography>
          )}
        </div>
      </section>
    </EditForm>
  )
}
