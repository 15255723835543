import React, { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Api } from '@api/Api'
import { toast } from 'react-toastify'
import { useApp } from '@core/context'

import FullTimeStepper from './fulltime-stepper/FullTimeStepper'
import ContractorStepper from './contractor-stepper/ContractorStepper'
import Documents from './documents'

export default function OnboardingPage({ onboardingState }) {
  const { worker } = useApp()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => Api.post('/contracts/onboarding/', payload),
    onSuccess: () => {
      queryClient.invalidateQueries('onboardingUpdate')
      toast.success('Onboarding updated successfully')
      window.location.href = '/worker/profile'
    },
  })

  const onSaveClick = useCallback(
    (customerData) => {
      mutate(customerData)
    },
    [mutate]
  )

  if (
    worker.documents_state === 'waiting_declined_documents' ||
    worker.documents_state === 'waiting_document_approval'
  ) {
    return <Documents />
  }

  if (worker.contract_type === 'full_time_employee') {
    return (
      <FullTimeStepper
        loading={isLoading}
        onSave={onSaveClick}
        onboardingState={onboardingState}
      />
    )
  }

  return <ContractorStepper loading={isLoading} onSave={onSaveClick} />
}
