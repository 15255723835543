import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { infoIcon } from '@core/icons/icons'
import React from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
  width: 385px;
  text-align: center;
`
const Image = styled.img`
  margin-bottom: 24px;
  width: 204px;
`

const Title = styled.h2`
  color: #121212;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
`
const Description = styled.div`
  font-size: 14px;
  color: #878787;
  margin-bottom: 24px;
`
const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
`

const StyledAlertText = styled.div`
  display: flex;
  padding-right: 1rem;
  font-size: 13px;
  color: #121212;
`

export default function ContentPlaceholder({
  imgSrc,
  title,
  description,
  onClick,
  buttonIcon,
  buttonText,
  info,
}) {
  return (
    <Wrapper>
      <Container>
        <Image src={imgSrc} alt={buttonText} />
        <Title>{title}</Title>
        <Description>{description}</Description>
        {buttonText && (
          <Button priority="primary_black" size="small" onClick={onClick}>
            {buttonIcon && <Icon icon={buttonIcon} className="mr-2" />}
            {buttonText}
          </Button>
        )}
        {info && (
          <StyledAlert>
            <StyledAlertText>
              <div className="mr-2">
                <Icon icon={infoIcon} />
              </div>
              <Typography className="">{info}</Typography>
            </StyledAlertText>
          </StyledAlert>
        )}
      </Container>
    </Wrapper>
  )
}
