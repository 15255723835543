import { Api } from '@api/Api'

export const getPayments = async (params) => {
  const { data } = await Api.get(`contractor-payments/my/`, {
    params,
  })
  return data
}

export const createPayment = async (data) =>
  Api.post(`contractor-payments/create/`, data)

export const fetchDeleteContractorPayments = async (contractorId) => {
  const { data } = await Api.delete(
    `contractor-payments/${contractorId}/delete/`
  )
  return data
}
