/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import TableCheckbox from '@atoms/Table/libs/TableCheckbox/TableCheckbox'
import { useTable, useRowSelect } from 'react-table'
import './Table.scss'

export default function SelectTable({
  columns: columnsData,
  data,
  onChangeSelect,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: columnsData,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          width: 32,
          minWidth: 32,
          maxWidth: 32,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <TableCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ])
    }
  )
  // Render the UI for your table
  useEffect(() => {
    if (selectedFlatRows.length > 0) onChangeSelect(selectedFlatRows.map((d) => d.original))
  }, [selectedRowIds])
  return (
    <table {...getTableProps()} cellSpacing="0" cellPadding="0">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  minWidth: column.minWidth,
                  width: column.width,
                  maxWidth: column.maxWidth,
                }}
              >
                <div className="d-flex align-items-center table_header">
                  {column.render('Header')}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    minWidth: cell.column.minWidth,
                    width: cell.column.width,
                    maxWidth: cell.column.maxWidth,
                  }}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
