import { generateTokenUnisure, SplitNumber } from '@core/utils'
import moment from 'moment'

export const REMOHEALTH_STATUSES = {
  CREATED: 'created',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  SUBMITTED: 'app_submitted',
  ACCEPTED: 'app_accepted',
  INFO_OUTSTANDING: 'info_outstanding',
  MEDICAL_OUTSTANDING: 'medical_outstanding',
}

export const RemohealthStateDescription = (status) => {
  switch (status) {
    case REMOHEALTH_STATUSES.CREATED:
      return 'Fill out the form to complete your health coverage'
    case REMOHEALTH_STATUSES.SUBMITTED:
    case REMOHEALTH_STATUSES.ACCEPTED:
      return 'Thank you for submitting the application. We will email you when it is activated.'
    case REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING:
    case REMOHEALTH_STATUSES.INFO_OUTSTANDING:
      return 'Important details are missing. Kindly provide additional information to complete the form.'
    case REMOHEALTH_STATUSES.ACTIVE:
      return 'Your application has been activated. View information about your package.'
    default:
      return ''
  }
}

export const getOverviews = (
  stats,
  insurance,
  onUnisure,
  handleShowRemohealth
) => {
  return [
    {
      contract_type: '',
      title: 'Paid this month',
      image: '/assets/img/overview/note.png',
      number: `${SplitNumber(stats.income)} ${
        stats.currency ? stats.currency.sign || stats.currency.short_code : ''
      }`,
    },
    {
      contract_type: 'full_time_employee',
      title: 'See Invoices',
      description: 'Bonuses, commissions and other stipends',
      link: '/worker/invoices',
      image: '/assets/img/overview/papers.png',
    },
    {
      contract_type: 'contractor',
      title: 'RemoHealth',
      hide:
        !insurance ||
        !insurance.contract.needs_unisure_confirmation ||
        insurance?.status === REMOHEALTH_STATUSES.CANCELED ||
        insurance?.status === REMOHEALTH_STATUSES.EXPIRED,
      iconFill: '#037B0F',
      linkIcon:
        insurance?.status === REMOHEALTH_STATUSES.CREATED ||
        insurance?.status === REMOHEALTH_STATUSES.INFO_OUTSTANDING ||
        insurance?.status === REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING,
      description: RemohealthStateDescription(insurance?.status),
      onClick: () => {
        if (
          insurance?.status === REMOHEALTH_STATUSES.CREATED ||
          insurance?.status === REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING ||
          insurance?.status === REMOHEALTH_STATUSES.INFO_OUTSTANDING
        )
          onUnisure()
        if (insurance?.status === REMOHEALTH_STATUSES.ACTIVE)
          handleShowRemohealth()
      },
      image: '/assets/img/overview/remohealth-heart.png',
    },
  ]
}

export const transformToUnisureData = (worker, insurance, ip) => {
  const token = generateTokenUnisure(worker.user_id, ip)

  const data = {
    first_name: worker.profile.first_name,
    last_name: worker.profile.last_name,
    unique_id: worker.user_id,
    nod: insurance.family_members_amount,
    token,
    employer_contribution: 'Full time worker',
    coverage: '2',
    selected_product: insurance?.insurance_type || '-',
    residence: worker.working_country?.short_code || '-',
    gender: worker.profile?.sex[0] || 'M',
    dob: worker.profile?.birth_date
      ? moment(worker.profile.birth_date).format('YYYY-MM-DD')
      : '',
    company: worker.company?.name || '-',
    cover_start: insurance?.starts_at
      ? moment(insurance.starts_at).format('YYYY-MM-DD')
      : '-',
    cover_end: insurance.expired_at
      ? moment(insurance.expired_at).format('YYYY-MM-DD')
      : '-',
  }
  return data
}
