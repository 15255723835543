import 'bootstrap-4-grid'
import 'react-toastify/dist/ReactToastify.css'

import { DotSpinner } from '@uiball/loaders'
import React, { Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Pages from './router'

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<DotSpinner />}>
          <Switch>
            <Route path="/worker" render={() => <Pages />} />
            <Route path="/">
              <Redirect to={{ pathname: `/worker/overview` }} />
            </Route>
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
