import React from 'react'
import Typography from '@atoms/Typography/Typography'

export default function NoContent({ type }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center mt-5 pt-5">
      <img src="/assets/img/oc-handshake.png" alt="no-content" />
      <Typography className="heading_semibold__20">
        {`You don't have ${
          type === 'resolved' ? 'closed' : 'opened'
        } tickets yet.`}
      </Typography>
      <Typography className="text_regular__14 mt-2">
        Create a new ticket by clicking on the button below
      </Typography>
    </div>
  )
}
