import Button from '@atoms/Button/Button'
import React, { useCallback, useEffect } from 'react'
import Avatar from '@atoms/Avatar/Avatar'
import Typography from '@atoms/Typography/Typography'
import { useAxiosCall } from '@api/Api'
import { roles } from '@pages/chat/parse'
import { DotWave } from '@uiball/loaders'

export default function ThreadMemberList({ threadId }) {
  const [{ data, loading }, getThreadMembers] = useAxiosCall(
    {
      url: `threads/${threadId}/members/`,
    },
    {
      manual: true,
    }
  )
  const [, deleteMember] = useAxiosCall(
    {
      method: 'delete',
    },
    {
      manual: true,
    }
  )
  const onRemoveMember = useCallback(
    (memberId) => () => {
      deleteMember({
        url: `threads/${threadId}/members/${memberId}/delete/`,
      }).then(() => {
        getThreadMembers()
      })
    },
    [threadId]
  )

  useEffect(() => {
    if (threadId) {
      getThreadMembers()
    }
  }, [threadId])
  return (
    <div className="px-2" style={{ height: 300, overflow: 'auto' }}>
      <Typography className="text_medium__14 mb-2">Members</Typography>
      {loading ? (
        <div
          key={0}
          className="d-flex w-100 loader align-items-center justify-content-center"
          style={{ height: 300 }}
        >
          <DotWave size={32} speed={1} color="black" />
        </div>
      ) : (
        data?.map((member) => (
          <div className="d-flex my-2 align-items-center justify-content-between">
            <div className="d-flex">
              <Avatar width={40} height={40} className="mr-3" />
              <div className="d-flex flex-column">
                <Typography className="text_medium__14">
                  {member.member.full_name}
                </Typography>
                <Typography
                  className="text_light__14 mt-1"
                  style={{ color: '#B2B2B2' }}
                >
                  {`${roles[member.member.role]}`}
                </Typography>
              </div>
            </div>
            {!member.is_owner && (
              <Button
                priority="outlined"
                size="medium"
                onClick={onRemoveMember(member.id)}
              >
                <Typography className="color_red">Remove</Typography>
              </Button>
            )}
          </div>
        ))
      )}
    </div>
  )
}
