import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { copyIcon } from '@core/icons/icons'
import React from 'react'
import { useToast } from '@core/hooks/useErrorNotification'
import { RecoveryKeys } from './recovery-keys'
import { RecoveryKeysWarning } from './recovery-keys-warning'

export const copyCodes = (recoveryCodes) => {
  // Change later if we have more recovery codes
  navigator.clipboard.writeText(recoveryCodes[0])
}

export const SaveRecoveryKeys = ({ recoveryCodes }) => {
  const { successAlert } = useToast()

  return (
    <div className="d-flex flex-column align-items-center m-auto">
      <Typography className="heading_semibold__20">
        Congratulations, 2FA is now enabled! Save the recovery key!
      </Typography>
      <RecoveryKeysWarning />
      <RecoveryKeys recoveryCodes={recoveryCodes} />
      <Button
        priority="secondary"
        size="small"
        style={{ width: 164, marginTop: 48 }}
        onClick={() => {
          copyCodes(recoveryCodes)
          successAlert('Recovery code copied to clipboard')
        }}
      >
        <Icon icon={copyIcon} className="mr-2" />
        Copy code
      </Button>
    </div>
  )
}
