/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-shadow */
import React, { useCallback, useRef, createRef } from 'react'
import { toast } from 'react-toastify'

import { useQuery } from 'react-query'
import { omit } from 'lodash'
import { DotWave } from '@uiball/loaders'
import { useApp } from '@core/context'
import Status from '@atoms/Status/Status'
import Button from '@atoms/Button/Button'
import styled from 'styled-components'
import { ProgressBar } from 'react-bootstrap'
import Typography from '@atoms/Typography/Typography'
import { CgLoupe } from 'react-icons/cg'
import { useToast } from '@core/hooks/useErrorNotification'
import {
  getOnboadringDocuments,
  uploadDeclinedDocuments,
} from '../../../services/app.service'

const Root = styled.div``
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`
const HeaderCell = styled.div`
  color: #878787;
  font-family: 'Inter';
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 500;

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`
const Document = styled.div`
  background: #f9f9f9;
  border-radius: 0.5rem;
  padding: 1rem;

  margin-bottom: 1rem;
`
const Container = styled.div`
  display: flex;
  align-items: center;
`
const InfoCell = styled.div`
  width: 50%;
`
const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 2px;

  span {
    color: #ff4545;
  }
`
const Description = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 130%;
  color: #878787;
`
const StatusCell = styled.div`
  width: 25%;
`
const ActionsCell = styled.div`
  width: 25%;
  text-align: right;
`
const Error = styled.div`
  background: #fef3f2;
  border-radius: 0.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #ff4545;
  padding: 0.5rem;
  margin-top: 0.5rem;
`

const Progress = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
`

const convertToHashMap = (docsToUpload) =>
  docsToUpload.reduce((obj, item) => {
    obj[item.id] = { ...item, success: false, ref: createRef() }

    return obj
  }, {})

export default () => {
  const { worker } = useApp()
  const { successAlert, failedAlert } = useToast()

  const [documents, setDocuments] = React.useState({})
  const [fileName, setFileName] = React.useState('')
  const fileInputRef = useRef(null)

  const { isLoading, refetch, isFetching } = useQuery('docs', {
    queryFn: () => getOnboadringDocuments(worker.id, 100, 1),
    onSuccess: ({ results }) => {
      // convert response to hash-map
      setDocuments(convertToHashMap(results))
    },
  })

  const handleUpload = async (file, id) => {
    const setProgress = (progress) => {
      setDocuments((prev) => ({
        ...prev,
        [id]: {
          ...documents[id],
          isLoading: true,
          progress,
        },
      }))
    }

    uploadDeclinedDocuments(id, file, setProgress)
      .then(({ file_uuid }) => {
        setDocuments((prev) => ({
          ...prev,
          [id]: {
            ...documents[id],
            file_uuid,
            isLoading: false,
            success: true,
            progress: 0,
          },
        }))
      })
      .then(() => {
        refetch()
        successAlert(
          'Documents has been uploaded. Please, wait for the approval'
        )
      })
  }

  const handleFileInput = (e, id) => {
    const selectedFile = e.target.files[0]
    const maxFileNameLength = 200
    if (selectedFile.name.length < maxFileNameLength) {
      handleUpload(selectedFile, id)
    } else {
      failedAlert('Document name must be no longer than 200 symbols')
    }
  }

  const handleRemove = (id) => () => {
    setDocuments((prev) => ({
      ...prev,
      [id]: {
        ...omit(documents[id], ['file', 'isLoading', 'success']),
      },
    }))
  }

  const handleView = useCallback((file) => {
    return (e) => {
      e.preventDefault()
      window.open(file, '_blank')
    }
  }, [])

  const handleAttach = (ref) => {
    if (ref.current === null) return
    ref.current.click()
  }

  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )

  return (
    <Root>
      <Header>
        <HeaderCell style={{ width: '50%' }}>Document name</HeaderCell>
        <HeaderCell style={{ width: '25%' }}>Status</HeaderCell>
        <HeaderCell style={{ width: '25%' }}>Actions</HeaderCell>
      </Header>
      {Object.keys(documents).map((resultId) => {
        const { decline_reason, temp_file, state, employee_document, ref } =
          documents[resultId]
        return (
          <Document key={resultId}>
            <Container>
              <InfoCell>
                <Title>
                  {employee_document?.name}{' '}
                  {employee_document?.is_required && <span>*</span>}
                </Title>
                <Description>{employee_document?.description}</Description>
              </InfoCell>
              <StatusCell>
                <Status status={state === 'uploaded' ? 'review' : state} />
              </StatusCell>
              <ActionsCell>
                {state === 'declined' ? (
                  <>
                    <Button
                      type="button"
                      priority="secondary"
                      size="small"
                      onClick={() => handleAttach(ref)}
                    >
                      Attach
                    </Button>
                    <input
                      type="file"
                      ref={ref}
                      hidden
                      accept=".png,.jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip"
                      onChange={(e) => handleFileInput(e, resultId)}
                    />
                  </>
                ) : (
                  <Button
                    type="button"
                    priority="secondary"
                    size="small"
                    onClick={handleView(temp_file?.file)}
                  >
                    View
                  </Button>
                )}
              </ActionsCell>
            </Container>
            {state === 'declined' && <Error>{decline_reason}</Error>}
            {documents[resultId].isLoading && (
              <Progress>
                <ProgressBar
                  now={documents[resultId].progress}
                  label={
                    <Typography>{documents[resultId].progress}%</Typography>
                  }
                />
              </Progress>
            )}
          </Document>
        )
      })}
    </Root>
  )
}
