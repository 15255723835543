import './EmployeesTimeOff.scss'

import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import { coin } from '@core/icons/icons'
import CreateTimeOffModal from '@pages/employeesTimeOff/CreateTimeOffModal'
import ListHolidaysModal from '@pages/employeesTimeOff/ListHolidaysModal'
import TimeOffAccordion from '@pages/employeesTimeOff/TimeOffAccordion'
import { DotWave } from '@uiball/loaders'
import React, { useState, useContext } from 'react'
import { useMutation, useQuery } from 'react-query'

import styled from 'styled-components'
import { AppContext } from '@core/context'
import { getContractsPaidLeaves } from '../../services/contract.service'
import { createTimeOff, fetchTimeOff } from '../../services/absences.service'

const Container = styled.div`
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #e4e4e7;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 140px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`

export default function EmployeesTimeOffPage() {
  const { worker } = useContext(AppContext)
  const { successAlert } = useToast()

  const [timeOffs, setTimeOffs] = useState([])
  const [tab, setTab] = useState(0)
  const [statusTimeOff, setStatusTimeOff] = useState('&status_in=IN_PROGRESS')
  const [showHolidays, setShowHolidays] = useState(false)
  const [showNewTimeOff, setShowNewTimeOff] = useState(false)
  const [createdTimeOffId, setCreatedTimeOffId] = useState(null)

  const { isLoading, isFetching, refetch } = useQuery(
    ['timeOffs', statusTimeOff],
    {
      queryFn: () => fetchTimeOff(statusTimeOff),
      onSuccess: ({ results }) => {
        setTimeOffs(results)
      },
    }
  )

  const onTabChange = (id) => {
    setTab(id)
    switch (id) {
      case 0:
        return setStatusTimeOff('&status_in=IN_PROGRESS')
      case 1:
        return setStatusTimeOff('&status_in=CANCELED,APPROVED,REJECTED')
      default:
        return null
    }
  }

  const handleModalOpen = () => {
    setShowNewTimeOff(true)
  }

  const handleModalClose = () => {
    setShowNewTimeOff(false)
  }

  const createTimeOffRequest = useMutation({
    mutationFn: (payload) => createTimeOff(payload),
    onSuccess: (response) => {
      if (response.data) {
        setCreatedTimeOffId(response.data.id)
      }
      handleModalClose()
      refetch()
      successAlert('Successfully created!')
    },
  })

  const { data: paidLeave, isLoading: isLoadingPaid } = useQuery(
    ['getContractsPaidLeaves', worker],
    {
      queryFn: () => getContractsPaidLeaves(worker.id),
      enabled: !!worker.id,
    }
  )

  const renderTable = React.useMemo(() => {
    return timeOffs.length > 0 ? (
      <div className="d-flex flex-column mb-4">
        <div className="d-flex px-3">
          <div className="row timeOff-table-header w-100">
            <div className="col-3">
              <Typography className="text_regular-normal__14 color_text_300">
                Team member
              </Typography>
            </div>
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Country
              </Typography>
            </div>
            <div className="col-3">
              <Typography className="text_regular-normal__14 color_text_300">
                Period
              </Typography>
            </div>
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Request type
              </Typography>
            </div>
            <div className="col-2 justify-content-end d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Status
              </Typography>
            </div>
          </div>
        </div>
        <div className="">
          <TimeOffAccordion
            timeOffs={timeOffs}
            isHistory={tab === 1}
            createdTimeOffId={createdTimeOffId}
            refetch={refetch}
          />
        </div>
      </div>
    ) : (
      <div>
        <NotFound
          title="It's quiet in here ..."
          action="+ Add time off"
          onClickAction={handleModalOpen}
        />
      </div>
    )
  }, [timeOffs])
  return (
    <div className="employees-time-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Time off </PageTitle>
        <div>
          <Button
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={handleModalOpen}
          >
            Add time off
          </Button>
        </div>
      </div>
      <NotificationBlock
        render={
          <Typography className="text_regular__14 ml-2">
            Manage your time off requests. Head to the following link to check
            the{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              className="link-public-holidays"
              onClick={() => setShowHolidays(true)}
            >
              Public Holidays
            </span>{' '}
            by country.
          </Typography>
        }
        cards={[
          {
            title: 'Create a time off request',
            description:
              'Select Add Time off button, fill in the details and wait for employer to approve or reject',
            icon: coin,
          },
          {
            title: 'Review time off requests',
            description:
              'Approve or reject time off requests created by others',
            icon: coin,
          },
        ]}
      />
      <div className="row">
        <div className="col-4">
          <Container className="d-flex flex-column justify-content-between">
            <div>
              <Typography className="text_medium__14">
                Vacation days available
              </Typography>
              <Typography className="text_light__13 color_text_300">
                Every month you are credited with 2 days off
              </Typography>
            </div>
            <Typography className="heading_semibold__24">
              {paidLeave?.leave_days?.paid_leave?.available_days || 0}{' '}
              <small>days</small>
            </Typography>
          </Container>
        </div>
        <div className="col-4">
          <Container className="d-flex flex-column justify-content-between">
            <div>
              <Typography className="text_medium__14">
                Vacation days used
              </Typography>
              <Typography className="text_light__13 color_text_300">
                Paid time-off
              </Typography>
            </div>
            <Typography className="heading_semibold__24">
              {paidLeave?.leave_days?.paid_leave?.used_days || 0}{' '}
              <small>days</small>
            </Typography>
          </Container>
        </div>
        <div className="col-4">
          <Container>
            <Typography className="text_medium__14">
              Other leave used
            </Typography>
            <StyledTable>
              <tr>
                <th>
                  <Typography
                    className="text_regular-normal__14 ml-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    Parental leave
                  </Typography>
                </th>
                <td align="right">
                  <Typography
                    className="text_regular-normal__14 mr-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    {paidLeave?.leave_days?.paid_parental_leave?.used_days || 0}{' '}
                    days
                  </Typography>
                </td>
              </tr>
              <tr>
                <th>
                  <Typography
                    className="text_regular-normal__14 ml-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    Bereavement Leave
                  </Typography>
                </th>
                <td align="right">
                  <Typography
                    className="text_regular-normal__14 mr-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    {paidLeave?.leave_days?.paid_bereavement_leave?.used_days ||
                      0}{' '}
                    days
                  </Typography>
                </td>
              </tr>
              <tr>
                <th>
                  <Typography
                    className="text_regular-normal__14 ml-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    Sick Leave
                  </Typography>
                </th>
                <td align="right">
                  <Typography
                    className="text_regular-normal__14 mr-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    {paidLeave?.leave_days?.paid_sick_leave?.used_days || 0}{' '}
                    days
                  </Typography>
                </td>
              </tr>
            </StyledTable>
          </Container>
        </div>
      </div>
      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Pending">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="timeOff-table-box">
              <div className="timeOff-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
        <Tab tabId={1} title="History">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="timeOff-table-box">
              <div className="timeOff-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
      </Tabs>

      {showNewTimeOff && (
        <CreateTimeOffModal
          title="Add time off"
          onClose={handleModalClose}
          loading={createTimeOffRequest.isLoading}
          onSubmit={(data) => createTimeOffRequest.mutate(data)}
        />
      )}

      {showHolidays && (
        <ListHolidaysModal onClose={() => setShowHolidays(false)} />
      )}
    </div>
  )
}
