/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import NoContent from '@pages/chat/NoContent/NoContent'
import classNames from 'classnames'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { getDate } from '@core/utils'
import { DotWave } from '@uiball/loaders'

export default function ThreadList({
  loading,
  filteredThreads,
  onClickThread,
  currentThread,
  type,
}) {
  return (
    <div>
      {!loading && filteredThreads.length === 0 && <NoContent type={type} />}
      {loading ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 400 }}
        >
          <DotWave size={32} speed={1} color="black" />
        </div>
      ) : (
        filteredThreads.map((thread) => (
          <div
            key={thread.topic}
            onClick={onClickThread(thread)}
            className={classNames([
              'chat_thread',
              thread.id === currentThread?.id && 'chat_thread__current',
            ])}
          >
            <div className="d-flex justify-content-between">
              <Typography className="text_light__12 mb-1">
                {getDate(thread?.created_at)}
              </Typography>
              {thread.unread_messages_count !== 0 && (
                <Typography className="chat_thread_count text_light__12">
                  {thread.unread_messages_count}
                </Typography>
              )}
            </div>
            <Typography className="text_medium__14 mb-1">
              {thread.topic}
            </Typography>
            <Typography className="text_regular__14">
              {thread?.last_message?.short_content}
            </Typography>
          </div>
        ))
      )}
    </div>
  )
}
