import NotFound from '@atoms/NotFound/NotFound'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { DotWave } from '@uiball/loaders'
import React, { useState, useContext } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { AppContext } from '@core/context'
import { getContractDocuments } from '../../services/documents.service'
import { parserPayslips } from './utils'
import PayslipsEmployeeActions from './payslips-employee-actions.component'

const DEFAULT_PAGE_LIMIT = 10

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

export default function Payslips() {
  const { worker } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const {
    isFetching,
    data: payslipsResponse,
    refetch,
  } = useQuery(['getContractByIdPayslips', worker.id, page], {
    queryFn: () =>
      getContractDocuments({
        contract_id: worker.id,
        page,
        document_type: 'payslip',
        search: '',
        limit: DEFAULT_PAGE_LIMIT,
        offset: (page - 1) * DEFAULT_PAGE_LIMIT,
      }),
    onSuccess: ({ count }) => {
      setTotal(count)
    },
  })

  const fields = [
    {
      title: 'Document name',
      key: 'name',
      type: 'textMedium',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      title: 'Payslip for the month of',
      key: 'payslip_for_date',
      type: 'text',
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      title: 'Created at',
      key: 'created',
      type: 'textMedium',
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      title: 'Actions',
      key: 'actions',
      type: 'custom',
      render: (item) => (
        <PayslipsEmployeeActions item={item} refetch={refetch} />
      ),
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
  ]
  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>Payslips</PageTitle>
      </StyledTopbar>
      {isFetching ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {payslipsResponse?.results?.length > 0 ? (
            <CustomTable
              type="main"
              fields={fields}
              data={parserPayslips(payslipsResponse)}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <div>
              <NotFound title="There are no payslip yet" />
            </div>
          )}
        </div>
      )}
    </StyledContainer>
  )
}
