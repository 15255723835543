import React from 'react'
import classNames from 'classnames'

import './styles.scss'

export default function PageButton({
  disabled,
  children,
  className,
  active,
  onClick,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={!disabled ? onClick : undefined}
      className={classNames([
        className,
        'page-button',
        { 'page-button--active': active },
        `page-button--${!disabled ? 'regular' : 'disabled'}`,
      ])}
    >
      {children && children}
    </div>
  )
}
