import React, { useCallback, useState, useEffect } from 'react'
import EditModal from '@atoms/EditModal/EditModal'
import Select from '@atoms/Select/Select'
import Button from '@atoms/Button/Button'
import { useAxiosCall } from '@api/Api'
import { toast } from 'react-toastify'
import { DotWave } from '@uiball/loaders'

export default function AddMemberModal({ visible, setVisible, threadId }) {
  const [memberList, setMemberList] = useState([])

  const [, postThreadMembersAdd] = useAxiosCall(
    {
      method: 'post',
      url: `threads/${threadId}/members/create/`,
    },
    {
      manual: true,
    }
  )
  const [
    { data: membersList, loading: membersListLoading },
    getThreadsMembers,
  ] = useAxiosCall(
    {
      url: `threads/users/?thread_id=${threadId}`,
    },
    {
      manual: true,
    }
  )
  const onChangeMember = useCallback((selectMember) => {
    const selectMembers = selectMember.map((user) => user.value)
    setMemberList(selectMembers)
  }, [])

  const onClickSaveMember = useCallback(() => {
    postThreadMembersAdd({
      data: {
        members: memberList,
      },
    }).then(() => {
      getThreadsMembers()
      toast.success('New members added')
      setVisible(false)
    })
  }, [memberList])

  useEffect(() => {
    if (threadId) {
      getThreadsMembers()
    }
  }, [threadId])

  return (
    <EditModal
      // scrollable should be eq to {false} because of select dropdown menu scroll
      scrollable={false}
      visible={visible}
      footer={false}
      title="Add members"
      closeModal={() => setVisible(false)}
    >
      <div style={{ minHeight: 300 }}>
        {membersListLoading ? (
          <div
            className="d-flex w-100 loader align-items-center justify-content-center"
            style={{ height: 300 }}
          >
            <DotWave size={32} speed={1} color="black" />
          </div>
        ) : (
          <Select
            options={membersList?.map((user) => ({
              value: user.id,
              label: user.full_name,
            }))}
            isMulti
            name="thread_member"
            id="thread_member"
            placeholder="Select members"
            onChange={onChangeMember}
            value={memberList}
            label="Add members"
            className="w-100 mb-3"
          />
        )}
        {memberList.length > 0 && (
          <Button
            className="mb-4"
            priority="primary"
            size="small"
            onClick={onClickSaveMember}
          >
            Add
          </Button>
        )}
      </div>
    </EditModal>
  )
}
