import React, { useContext, useState } from 'react'
import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import { getCompensationInfo, getJobDetailsInfo } from '@pages/profile/mock'
import { AppContext } from '@core/context'
import styled from 'styled-components'
import Button from '@atoms/Button/Button'
import CreateExpenseModal from '@pages/employeesExpense/UpdateExpenseModal'
import { SalaryChangeDetails } from './SalaryChangeDetails'
import { CompensationEditModal } from './CompensationEditModal/CompensationEditModal'

const Box = styled.div`
  margin-bottom: 2rem;
`
const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

function JobDetails() {
  const { worker } = useContext(AppContext)
  const [editVisible, setEditVisible] = useState(false)

  const [salaryChange] = worker.salary_changes

  return (
    <>
      <Box>
        <BoxHeader>
          <Typography as="h2" className="heading_semibold__20 color_black">
            Job Details
          </Typography>
        </BoxHeader>

        <List lists={getJobDetailsInfo(worker)} />
      </Box>
      {worker?.contract_type === 'contractor' && (
        <Box>
          <BoxHeader>
            <Typography as="h2" className="heading_semibold__20 color_black">
              Compensation
            </Typography>
            <Button
              priority="secondary"
              size="small"
              onClick={() => setEditVisible(true)}
            >
              Edit
            </Button>
          </BoxHeader>

          <List lists={getCompensationInfo(worker)} />
        </Box>
      )}
      {salaryChange && <SalaryChangeDetails salaryChange={salaryChange} />}
      {editVisible && (
        <CompensationEditModal onClose={() => setEditVisible(false)} />
      )}
    </>
  )
}

export default JobDetails
