import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { keyboardArrowLeft } from '@core/icons/icons'
import { leaveTypes } from '@pages/employeesTimeOff/CreateTimeOffModal'
import TimeOffEvent from '@pages/employeesTimeOff/TimeOffEvent'
import moment from 'moment'
import React, { memo, useState } from 'react'
import styled from 'styled-components'

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: #fff;
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`

const Item = memo(
  ({ timeOff, expandedId, setPaymentId, refetch, isHistory }) => {
    const isOpen = expandedId === timeOff.id

    const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)
    return (
      <Styled key={timeOff.id}>
        <Header className="d-flex align-items-center" open={isOpen}>
          <div
            className="row w-100 align-items-center"
            role="button"
            aria-hidden
            onClick={handleToggle(timeOff.id)}
          >
            <div className="col-3">
              <Icon
                fill="#B2B2B2"
                style={{
                  cursor: 'pointer',
                  transform:
                    expandedId === timeOff.id
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)',
                }}
                icon={keyboardArrowLeft}
              />
              <Typography as="span" className="ml-3 text_medium__14">
                {timeOff.contract.full_name}
              </Typography>
            </div>
            <div className="col-2">
              <Typography as="span" className="text_regular-normal__14">
                {timeOff.current_country.name}
              </Typography>
            </div>
            <div className="col-3">
              <Typography as="span" className="text_regular-normal__14">
                {moment(timeOff.start_date).format('DD MMM YYYY')} -{' '}
                {moment(timeOff.end_date).format('DD MMM YYYY')}
              </Typography>
            </div>
            <div className="col-2">
              <Typography as="span" className="text_regular-normal__14">
                {
                  leaveTypes.filter(
                    (leavetype) => leavetype.value === timeOff.leave_type
                  )[0].label
                }
              </Typography>
            </div>
            <div className="col-2 justify-content-end d-flex">
              <Status
                status={
                  timeOff.status === 'IN_PROGRESS'
                    ? `timeoff_in_progress`
                    : timeOff.status
                }
              />
            </div>
          </div>
        </Header>
        {isOpen && (
          <Body open={isOpen}>
            <TimeOffEvent
              isHistory={isHistory}
              timeOff={timeOff}
              refetch={refetch}
            />
          </Body>
        )}
      </Styled>
    )
  }
)

const MyAccordion = ({ timeOffs, createdTimeOffId, refetch, isHistory }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  React.useEffect(() => {
    setExpandedItemId(createdTimeOffId)
  }, [createdTimeOffId])

  return timeOffs.map((v) => {
    return (
      <Item
        key={v.id}
        timeOff={v}
        refetch={refetch}
        isHistory={isHistory}
        expandedId={expandedItemId}
        setPaymentId={setExpandedItemId}
      />
    )
  })
}

MyAccordion.displayName = 'Accardion'

export default memo(MyAccordion)
