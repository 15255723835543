import { Api } from '@api/Api'

export const TEMPLATE_STATUS = {
  DRAFT: 'draft',
  CREATED: 'created',
}

export const AGREEMENT_STATUS = {
  NOT_SIGNED: 'not_signed',
  SIGNED: 'signed',
  UPLOADED: 'uploaded',
}

// Templates

export const getTemplates = async (params) => {
  const { data } = await Api.get(`agreement-templates/`, {
    params,
  })
  return data
}

export const getTemplate = async (id) => {
  const { data } = await Api.get(`agreement-templates/${id}/`)
  return data
}

export const deleteTemplate = async (id) => {
  const { data } = await Api.delete(`agreement-templates/${id}/delete/`)
  return data
}

export const getTemplateSignerRoles = async (id) => {
  const { data } = await Api.get(`agreement-templates/${id}/signer-roles/`)
  return data
}

export const updateTemplateData = async (id) => {
  const { data } = await Api.patch(`agreement-templates/${id}/update-data/`)
  return data
}

export const createTemplateEmbedded = async (file, mergeFieldIds) => {
  const fd = new FormData()
  fd.append('file', file)
  fd.append('title', 'Draft template')

  mergeFieldIds.forEach((id) => fd.append('merge_fields', id))

  const { data } = await Api.post(`agreement-templates/create/`, fd)
  return data
}

// Agreements

export const getAgreements = async (params) => {
  const { data } = await Api.get(`agreement-documents/`, {
    params,
  })
  return data
}

export const getAgreement = async (id) => {
  const { data } = await Api.get(`agreement-documents/${id}/`)
  return data
}

export const createAgreement = async (body) => {
  const { data } = await Api.post(`agreement-documents/create/`, body)
  return data
}

export const notify = async ({ id, body }) => {
  const { data } = await Api.post(`agreement-documents/${id}/remind/`, body)
  return data
}

export const getMergeFields = async () => {
  const { data } = await Api.get(`template-merge-fields/`)
  return data
}

export const getSignUrl = async (signatureId) => {
  const { data } = await Api.get(
    `/document-signatures/${signatureId}/sign-url/`
  )
  return data
}
