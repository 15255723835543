/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-shadow */
import React from 'react'
import Typography from '@atoms/Typography/Typography'
import Button from '@atoms/Button/Button'
import UploadButton from '@atoms/UploadButton'
import { useQuery } from 'react-query'
import { omit } from 'lodash'
import { DotWave } from '@uiball/loaders'
import { useApp } from '@core/context'
import {
  fetchUploadFile,
  getOnboadringDocuments,
} from '../../../../services/app.service'

const convertToHashMap = (docsToUpload) =>
  docsToUpload.reduce((obj, item) => {
    obj[item.id] = { ...item, success: false }

    return obj
  }, {})

export default ({ loading, onFinish }) => {
  const { worker } = useApp()

  const [documents, setDocuments] = React.useState({})

  const { isLoading } = useQuery('docs', {
    queryFn: () => getOnboadringDocuments(worker.id, 100, 1),
    onSuccess: ({ results }) => {
      // convert response to hash-map
      setDocuments(
        convertToHashMap(
          results.filter(
            (v) =>
              v.state !== 'declined' &&
              v.state !== 'approved' &&
              v.state !== 'uploaded'
          )
        )
      )
    },
  })

  const handleUpload = async (file, id) => {
    const setProgress = (progress) => {
      setDocuments((prev) => ({
        ...prev,
        [id]: {
          ...documents[id],
          isLoading: true,
          progress,
        },
      }))
    }

    fetchUploadFile(file, setProgress)
      .then(({ file_uuid }) => {
        setDocuments((prev) => ({
          ...prev,
          [id]: {
            ...documents[id],
            file_uuid,
            isLoading: false,
            success: true,
            progress: 0,
          },
        }))
      })
      .catch(() => {
        setDocuments((prev) => ({
          ...prev,
          [id]: {
            ...documents[id],
            isLoading: false,
            success: false,
            progress: 0,
          },
        }))
      })
  }
  const handleRemove = (id) => () => {
    setDocuments((prev) => ({
      ...prev,
      [id]: {
        ...omit(documents[id], ['file', 'file_uuid', 'isLoading', 'success']),
      },
    }))
  }

  const handleFinish = () => {
    onFinish({
      contract_documents: Object.keys(documents)
        .filter((resultId) => documents[resultId].success)
        .map((resultId) => ({
          employee_document: Number(documents[resultId].employee_document.id),
          temp_file: documents[resultId].file_uuid,
        })),
    })
  }

  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )

  return (
    <section className="d-flex flex-column h-100">
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        {Object.keys(documents).map((resultId) => (
          <div key={resultId} className="w-100 remo-form-input mb-5">
            <UploadButton
              id={resultId}
              accept=".png,.jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx"
              label={
                <Typography>
                  {documents[resultId].employee_document.name}
                  {!documents[resultId].employee_document.is_required && (
                    <span className="ml-1">(Optional)</span>
                  )}
                  {documents[resultId].employee_document.is_required && (
                    <span className="text-danger ml-1">*</span>
                  )}
                </Typography>
              }
              addText={documents[resultId].employee_document.description}
              isLoading={documents[resultId].isLoading}
              isSuccess={
                documents[resultId].success ||
                documents[resultId].state === 'uploaded'
              }
              progress={documents[resultId].progress}
              onRemove={handleRemove(resultId)}
              fileName={
                documents[resultId].temp_file &&
                documents[resultId].temp_file.filename
              }
              onChange={(file) => handleUpload(file, resultId)}
            />
          </div>
        ))}
      </div>

      <div className="pb-5 pt-3 align-self-center">
        <Button
          type="button"
          className="align-self-end"
          loading={loading}
          disabled={
            loading ||
            Object.keys(documents).some(
              (id) =>
                documents[id].employee_document.is_required &&
                !documents[id].success
            )
          }
          onClick={handleFinish}
        >
          Continue
        </Button>
      </div>
    </section>
  )
}
