import React, { useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { getCurrency, SplitNumber } from '@core/utils'
import { AppContext } from '@core/context'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Title = styled.h4`
  font-size: 18px;
  font-family: 'Manrope', sans-serif;
  color: #121212;
  font-weight: 600;
  margin-bottom: 1.5rem;
`
const MoreInfo = styled.div`
  width: 40%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
`
const MoreInfoItem = styled.div`
  margin-bottom: 1rem;
`
const CardItemSecondary = styled.div`
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
`
const CardItem = styled.div`
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default function PaymentDetail({ data }) {
  const { worker } = useContext(AppContext)

  return (
    <Wrapper>
      <MoreInfo>
        <Title>Payment</Title>
        <MoreInfoItem>
          <CardItem>
            <span>Period</span>
            <span>{`${moment(data.start_date).format('DD/MM/YYYY')} - ${moment(
              data.end_date
            ).format('DD/MM/YYYY')}`}</span>
          </CardItem>

          <CardItemSecondary>
            <span>{`${
              worker.compensation.contractor_wage_type.charAt(0).toUpperCase() +
              worker.compensation.contractor_wage_type.slice(1)
            } rate`}</span>
            <span>{`${SplitNumber(
              worker.compensation.contractor_rate
            )} ${getCurrency(worker.compensation?.currency)} / ${
              worker.compensation.contractor_wage_type
            }`}</span>
          </CardItemSecondary>
        </MoreInfoItem>
      </MoreInfo>
    </Wrapper>
  )
}
