export const getFields = ({ handleDelete }) => [
  {
    title: 'Type',
    key: 'type',
    type: 'textMedium',
    maxWidth: 240,
    minWidth: 240,
    width: 240,
  },
  {
    title: 'Submitted',
    key: 'created_at',
    type: 'textMedium',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Created by',
    key: 'created_by',
    add_text: 'created_by_me',
    type: 'textAction',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Total',
    key: 'total',
    type: 'textAction',
    maxWidth: 138,
    minWidth: 138,
    width: 138,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 138,
    minWidth: 138,
    width: 138,
  },
  {
    title: 'Action',
    key: 'action',
    type: 'actions',
    maxWidth: 50,
    minWidth: 50,
    width: 50,
    actions: [
      {
        action: handleDelete,
        icon: 'deleteIcon',
        disabledOnPending: true,
      },
    ],
  },
]
