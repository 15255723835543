/* eslint-disable no-shadow */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Avatar from '@atoms/Avatar/Avatar'
import Icon from '@atoms/Icon/Icon'
import ProgressStepper from '@atoms/ProgressStepper'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { logoutIcon } from '@core/icons/icons'
import React from 'react'
import { useAuth } from '../../../AuthProvider'
import DocumentsCheck from './DocumentsCheck'
import styles from './onboarding-documents.module.scss'

const step1 = '1'
const step2 = '2'
const step3 = '3'

const _steps = [
  {
    step: step1,
    title: 'Employee sent Documents',
    success: true,
    active: false,
  },
  {
    step: step2,
    title: 'Documents check',
    success: false,
    active: false,
    content: <DocumentsCheck />,
  },
  {
    step: step3,
    title: 'Welcome',
    success: false,
    active: false,
  },
]

export default () => {
  const { worker } = useApp()
  const { logout } = useAuth()

  const [steps, setSteps] = React.useState(
    _steps.reduce((obj, item) => {
      obj[item.step] = item
      return obj
    }, {})
  )
  const [progress, setProgress] = React.useState(25)

  React.useEffect(() => {
    if (
      worker.documents_state === 'waiting_document_approval' ||
      worker.documents_state === 'waiting_declined_documents'
    ) {
      setProgress(50)
      setSteps((prev) => ({
        ...prev,
        [step1]: { ...steps[step1], success: true },
        [step2]: {
          ...steps[step2],
          active: true,
        },
      }))
    }
  }, [worker])

  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-4 d-flex justify-content-between align-items-center">
        <img
          src="https://remofirst-static.s3.amazonaws.com/627a7bb97e67853f5041ccce_Logo.svg"
          alt="logo"
        />
        <div
          role="button"
          aria-hidden
          className="sidebar-logout-row d-flex align-items-center sidebar__item"
          onClick={logout}
        >
          <Icon icon={logoutIcon} fill="#878787" />
          <Typography className="text_regular__14 ml-2">Logout</Typography>
        </div>
      </div>
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        <div className={styles.card}>
          <div className="d-flex mb-4">
            <Avatar width={40} height={40} className="mr-3" />
            <div className={styles.creds}>
              <Typography>
                {worker.profile.first_name} {worker.profile.last_name}
              </Typography>
              <p style={{ color: '#878787' }}>{worker.company.name}</p>
            </div>
          </div>
          <div className="mb-4">
            <p>Your documents have been successfully uploaded.</p>
            <p>
              Remofirst local team will review them and you will receive an
              email notification.
            </p>
            <p>Now you can close this window.</p>
            <p>Thank you!</p>
          </div>
          <ProgressStepper
            steps={Object.keys(steps).map((id) => ({
              ...steps[id],
            }))}
            progress={progress}
          />
        </div>
      </div>
    </div>
  )
}
