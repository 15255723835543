import moment from 'moment'

export const getErrorValue = (message, field) => {
  if (message.toLowerCase().includes('this field')) {
    return message
      .toLowerCase()
      .replace('this field', field.charAt(0).toUpperCase() + field.slice(1))
      .replace('.', ' ')
      .replace('_', ' ')
  }

  return message
}

export const setCurrencyChangeEvent = (changeFn) => (e) => {
  changeFn({
    ...e,
    target: { ...e.target, value: e.target.value.replace(/ /g, '') },
  })
}

export const SplitNumber = (num) =>
  String(Number(num).toFixed(2)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')

export const getDate = (date) => {
  if (moment(date).isSame(new Date(), 'week')) {
    return moment(date).format('dddd, HH:mm')
  }
  return moment(date).format('DD MMM YYYY, HH:mm')
}

/**
 *
 * @param currency object
 * @returns currency string
 */
export const getCurrency = (currency = {}) => {
  if (currency === null) return ''
  const { sign, short_code } = currency
  return sign || short_code || ''
}

export const formatDate = (date) => moment(date).format('DD MMM YYYY')

const sha1 = require('js-sha1')

export const generateTokenUnisure = (uniqueId, ip) => {
  const time = moment.utc().add(2, 'h').format('DDMMYYhh')
  return sha1(uniqueId + time + process.env.REACT_APP_SECRET_KEY_UNISURE + ip)
}

export const capitalizeFirstLetter = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1)

export const SplitAmount = (num) =>
  String(Number(num)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
