import React, { useState, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import Avatar from '@atoms/Avatar/Avatar'
import List from '@atoms/List/List'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import Select from '@atoms/Select/Select'
import EditAvatar from '@atoms/EditAvatar/EditAvatar'
import { getPersonalInformation } from '@pages/profile/mock'
import { AppContext } from '@core/context'
import { Api } from '@api/Api'
import './style.scss'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import RadioButton from '@atoms/RadioButton/RadioButton'
import { updateContractWorkPermit } from '../../../services/contract.service'

function PersonalInfo(props) {
  const { profile } = props
  const { countries, worker } = useContext(AppContext)
  const queryClient = useQueryClient()
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [editState, setEditState] = useState({
    first_name: profile.first_name,
    last_name: profile.last_name,
    birth_date: profile.birth_date,
    phone_number: profile.phone_number,
    nationality: profile.nationality,
    living_country: profile.address?.living_country?.id,
    street_address: profile.address?.street_address,
    address_line: profile.address?.address_line,
    state: profile.address?.state || '',
    city: profile.address?.city,
    postal_code: profile.address?.postal_code,
    avatar: profile.avatar,
    home_living_country: profile.home_address?.living_country?.id,
    home_street_address: profile.home_address?.street_address,
    home_address_line: profile.home_address?.address_line,
    home_state: profile.home_address?.state || '',
    home_city: profile.home_address?.city,
    home_postal_code: profile.home_address?.postal_code,
    needs_work_permit: `${profile?.needs_work_permit}`,
  })
  const [expirationDate, setExpirationDate] = useState(
    worker.work_permit?.expiration_date
  )
  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }

  const onChangeBirth = (evt) => {
    setEditState((prevState) => ({
      ...prevState,
      birth_date: moment(evt).format('YYYY-MM-DD'),
    }))
  }

  const onChangeExpirationDate = (evt) => {
    setExpirationDate(moment(evt).format('YYYY-MM-DD'))
  }

  const onChangeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: option.value,
    }))
  }

  const onChangeHomeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      home_living_country: option.value,
    }))
  }
  const onChangeWorkPermit = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      needs_work_permit: e.target.value,
    }))
  }

  const mutationUpdateProfile = (newData) =>
    Api.patch('/profile/', {
      first_name: newData.first_name,
      last_name: newData.last_name,
      birth_date: newData.birth_date || null,
      phone_number: newData.phone_number,
      nationality: newData.nationality,
      address: {
        living_country: newData.living_country || null,
        street_address: newData.street_address,
        address_line: newData.address_line,
        city: newData.city,
        postal_code: newData.postal_code,
        state: newData.state,
      },
      home_address: {
        living_country: newData.home_living_country || null,
        street_address: newData.home_street_address,
        address_line: newData.home_address_line,
        city: newData.home_city,
        postal_code: newData.home_postal_code,
        state: newData.home_state,
      },
      needs_work_permit: newData.needs_work_permit,
    })

  const updateProfile = useMutation(mutationUpdateProfile)

  const updateContractWorkPermitMutation = useMutation(({ id, payload }) =>
    updateContractWorkPermit(id, payload)
  )

  const onSaveEdit = async () => {
    try {
      const { first_name, last_name } = editState
      if (first_name && last_name) {
        await updateProfile.mutateAsync(editState)
      }
      if (expirationDate) {
        await updateContractWorkPermitMutation.mutateAsync({
          id: worker.id,
          payload: {
            expiration_date: expirationDate,
          },
        })
      }
      await queryClient.invalidateQueries('profile')
      await queryClient.invalidateQueries('worker')
      toast.success('Profile updated successfully')
    } catch (e) {
      console.log(e)
    } finally {
      setVisibleEdit(false)
    }
  }

  const mutationUploadAvatar = async (file) => {
    const fd = new FormData()
    fd.append('file', file)

    return Api.post('/profile/avatar/', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const uploadProfileAvatar = useMutation(mutationUploadAvatar, {
    onSuccess: (_, imageFile) => {
      setEditState((prevState) => ({
        ...prevState,
        avatar: URL.createObjectURL(imageFile),
      }))
      toast.success('Your profile image has been updated successfully')
    },
  })

  const onAvatarUpload = (file) => uploadProfileAvatar.mutate(file)

  return (
    <div className="personal-info">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography as="h2" className="heading_semibold__20 color_black">
          Personal information
        </Typography>
        <Button
          priority="secondary"
          size="small"
          onClick={() => setVisibleEdit(true)}
        >
          Edit
        </Button>
      </div>
      <Avatar
        src={editState.avatar}
        width={100}
        height={100}
        className="personal-info__avatar"
      />
      <List lists={getPersonalInformation(profile, worker)} />

      <EditModal
        visible={visibleEdit}
        title="Personal information"
        onSave={onSaveEdit}
        isLoading={updateProfile.isLoading}
        closeModal={() => setVisibleEdit(false)}
      >
        <div className="personal-info__content">
          <EditAvatar
            src={editState.avatar}
            isLoading={uploadProfileAvatar.isLoading}
            className="personal-info-edit-avatar"
            onUpload={onAvatarUpload}
          />
          <Input
            onChange={onChangeEdit}
            label="Name"
            name="first_name"
            styleClass={!editState.first_name && 'mb-1'}
            value={editState?.first_name}
          />
          {!editState.first_name && (
            <Typography className="text_regular__14 color_red">
              First name is required
            </Typography>
          )}
          <Input
            onChange={onChangeEdit}
            label="Surname"
            styleClass={!editState.last_name && 'mb-1'}
            name="last_name"
            value={editState?.last_name}
          />
          {!editState.last_name && (
            <Typography className="text_regular__14 color_red">
              Last name is required
            </Typography>
          )}
          <div>
            <Typography className="text_medium__14 remo-form-label">
              Date of birth
            </Typography>
            <DatePicker
              selected={new Date(editState?.birth_date)}
              onChange={onChangeBirth}
              maxDate={new Date()}
              dateFormat="dd MMM yyyy"
              placeholderText="DD Mon YYYY"
            />
          </div>
          <Input
            onChange={onChangeEdit}
            label="Phone number"
            name="phone_number"
            type="phone"
            value={editState?.phone_number}
          />
          <div className="w-100 remo-form-input">
            <RadioButton
              label="Work eligibility"
              onChange={onChangeWorkPermit}
              value={editState.needs_work_permit}
              addText="Does this employee require a work eligibility document (work permit, visa, etc)?"
              options={[
                {
                  id: 'needs_work_permit_true',
                  value: 'true',
                  text: 'Yes',
                },
                {
                  id: 'needs_work_permit_false',
                  value: 'false',
                  text: 'No',
                },
              ]}
            />
          </div>
          <Input
            onChange={onChangeEdit}
            label="Nationality"
            name="nationality"
            value={editState?.nationality}
          />
          <Typography as="h2" className="heading_semibold__20 color_black">
            {worker.contract_type === 'contractor'
              ? 'Billing address'
              : 'Home address'}
          </Typography>
          {countries && (
            <Select
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              name="living_country"
              id="living_country"
              placeholder="e.g United Kingdom"
              onChange={onChangeLivingCountry}
              value={editState?.living_country}
              label="Country"
            />
          )}
          <Input
            onChange={onChangeEdit}
            label="State"
            name="state"
            value={editState?.state}
          />
          <Input
            onChange={onChangeEdit}
            label="City"
            name="city"
            value={editState?.city}
          />
          <Input
            onChange={onChangeEdit}
            label="Street Address"
            name="street_address"
            value={editState?.street_address}
          />
          <Input
            onChange={onChangeEdit}
            label="Address line"
            name="address_line"
            value={editState?.address_line}
          />
          <Input
            onChange={onChangeEdit}
            label="Postal code"
            name="postal_code"
            value={editState?.postal_code}
          />

          {profile.needs_work_permit && (
            <div>
              <Typography className="text_medium__14 remo-form-label">
                Work permit expiration date
              </Typography>
              <DatePicker
                selected={expirationDate ? new Date(expirationDate) : null}
                onChange={onChangeExpirationDate}
                minDate={new Date()}
                dateFormat="dd MMM yyyy"
                placeholderText="DD Mon YYYY"
              />
            </div>
          )}

          {worker.contract_type === 'contractor' && (
            <>
              <Typography as="h2" className="heading_semibold__20 color_black">
                Home address
              </Typography>
              {countries && (
                <Select
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  name="home_living_country"
                  id="home_living_country"
                  placeholder="e.g United Kingdom"
                  onChange={onChangeHomeLivingCountry}
                  value={editState?.home_living_country}
                  label="Country"
                />
              )}
              <Input
                onChange={onChangeEdit}
                label="State"
                name="home_state"
                value={editState?.home_state}
              />
              <Input
                onChange={onChangeEdit}
                label="City"
                name="home_city"
                value={editState?.home_city}
              />
              <Input
                onChange={onChangeEdit}
                label="Street Address"
                name="home_street_address"
                value={editState?.home_street_address}
              />
              <Input
                onChange={onChangeEdit}
                label="Address Line"
                name="home_address_line"
                value={editState?.home_address_line}
              />
              <Input
                onChange={onChangeEdit}
                label="Postal code"
                name="home_postal_code"
                value={editState?.home_postal_code}
              />
            </>
          )}
        </div>
      </EditModal>
    </div>
  )
}

export default PersonalInfo
