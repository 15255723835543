import React, { useContext } from 'react'

import { AppContext } from '@core/context'
import { REMOHEALTH_INFO } from '@features/remohealth-onboarding/consts'
import HealthInfoAccordion from '@features/remohealth-onboarding/health-info-accordion'
import moment from 'moment'
import styled from 'styled-components'

const Line = styled.div`
  display: flex;
  background: #f0f0f7;
  width: 100%;
  padding: 14px;
`
const LineWhite = styled.div`
  display: flex;
  width: 100%;
  padding: 14px;
`
const Title = styled.div`
  color: #6d6e85;
  font-size: 14px;
  font-weight: 400;
  width: 200px;
`

export const RemohealthPackageInfo = ({ insurance }) => {
  return (
    <div>
      <div className="mb-3">
        <Line>
          <Title>Package</Title>
          <div>{insurance?.insurance_type}</div>
        </Line>
        <LineWhite>
          <Title>Cost</Title>
          <div>{insurance?.purchased_price} USD / month</div>
        </LineWhite>
        <Line>
          <Title>Status</Title>
          <div>{insurance?.status}</div>
        </Line>
        <LineWhite>
          <Title>Period</Title>
          <div>{`${
            insurance?.starts_at
              ? moment(insurance.starts_at).format('DD MMM YYYY')
              : ''
          } - ${
            insurance.expired_at
              ? moment(insurance.expired_at).format('DD MMM YYYY')
              : ''
          }`}</div>
        </LineWhite>
      </div>

      <HealthInfoAccordion data={REMOHEALTH_INFO[insurance?.insurance_type]} />
    </div>
  )
}
