import React from 'react'
import { SplitNumber } from '@core/utils'
import moment from 'moment'
import PaymentDetail from '@pages/payments/payment-detail/payment-detail.component'

export const ContractorWageType = {
  monthly: 'monthly',
  hourly: 'hr',
  daily: 'd',
}
export const ContractorStates = {
  created: 'pending',
  rejected: 'cancelled',
  invoice_created: 'invoice_created',
  paid: 'paid',
}

export const payContractorsTableParse = (contracts, worker) =>
  contracts.map((contract) => {
    return {
      ...contract,
      id: contract.id,
      created_at: moment(contract.created_at).format('DD MMM, YYYY'),
      worked_hours: `${
        contract.contract_currency_sign || contract.contract_currency
      } ${SplitNumber(contract.payment_amount)}`,
      worked_hours_time: `(${moment(contract.start_date).format(
        'DD MMM'
      )} - ${moment(contract.end_date).format('DD MMM')})`,
      rate: `${
        contract.contract_currency_sign || contract.contract_currency
      } ${SplitNumber(contract.rate)}/${
        ContractorWageType[contract.contractor_wage_type]
      }`,
      total_number: contract.total,
      // TODO: change type
      type: 'New payment',
      created_by: contract.employee
        ? `${contract.employee?.first_name} ${contract.employee?.last_name}`
        : 'Generated by the platform',
      created_by_me: worker.user_id === contract.employee?.id ? ' (Me)' : null,
      total: `${
        contract.contract_currency_sign || contract.contract_currency
      } ${SplitNumber(contract.total)}`,
      payment_provider_fee: contract.payment_provider_fee,
      worked_hours_num: contract.worked_hours,
      rate_num: contract.rate,
      status: ContractorStates[contract.state],
      contractor_wage_type: contract.contractor_wage_type,
      currency: contract.contract_currency_sign || contract.contract_currency,
      currency_company: contract.company_currency_sign,
      add_payment: contract.additional_payment_detailed,
      collapseContent: <PaymentDetail data={contract} />,
    }
  })
