/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef, useState } from 'react'
import Icon from '@atoms/Icon/Icon'
import { check } from '@core/icons/icons'

import './style.scss'

export default forwardRef(({ label, id, ...props }, ref) => {
  const [checked, setChecked] = useState(props.checked || false)
  const handleCheck = (e) => setChecked(e.target.checked)

  return (
    <div>
      <label className="d-flex align-items-center">
        <div className="styledCheckbox">
          {checked && (
            <Icon
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              icon={check}
            />
          )}
        </div>
        {label && (
          <label
            htmlFor={id}
            className="ml-2 text_regular__14 color_black"
            role="button"
          >
            {label}
          </label>
        )}
        <input
          id={id}
          ref={ref}
          hidden
          type="checkbox"
          {...props}
          onChange={(e) => {
            handleCheck(e)
            props.onChange(e)
          }}
        />
      </label>
    </div>
  )
})
