import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { infoIcon } from '@core/icons/icons'
import React from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { updateContractProfile } from '../../../../services/profile.service'

const StyledModal = styled(Modal)`
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
  }
`

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
`

const StyledAlertText = styled.div`
  display: flex;
  padding-right: 1rem;
  font-size: 13px;
  color: #121212;
`

const getReceivingCurrencyOptions = (countries, currencies, worker) => {
  const defaultCurrencyShortCode = 'USD'
  const country = countries.find(
    (item) => item.id === worker?.profile?.address?.living_country?.id
  )
  const localCurrency = country?.currency
  const usdCurrency = currencies.find(
    (item) => item.short_code === defaultCurrencyShortCode
  )

  return [
    {
      id: 1,
      text: defaultCurrencyShortCode,
      value: usdCurrency?.id,
    },
    {
      id: 2,
      text: `${localCurrency?.short_code || ''} (local currency)`,
      value: localCurrency?.id,
    },
  ]
}

export function CompensationEditModal({ onClose }) {
  const { worker, countries, currencies, setWorker } = useApp()
  const { successAlert } = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      receiving_currency: `${worker.compensation?.receiving_currency?.id}`,
    },
  })

  const updateMutation = useMutation(updateContractProfile, {
    onSuccess: (v, data) => {
      onClose()
      const newReceivingCurrency = data?.body?.receiving_currency
      const newCurrencyData = currencies.find(
        (item) => item.id === newReceivingCurrency
      )
      setWorker({
        ...worker,
        compensation: {
          ...worker.compensation,
          receiving_currency: newCurrencyData,
        },
      })
      successAlert('Successfully updated')
    },
  })

  const handleEdit = ({ receiving_currency }) => {
    updateMutation.mutate({
      id: worker.id,
      body: {
        receiving_currency: +receiving_currency,
      },
    })
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">
          Receiving currency
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <div className="remo-form-input">
          <RadioButton
            {...register('receiving_currency', {
              required: 'Field is required',
            })}
            label="Receiving currency"
            options={getReceivingCurrencyOptions(countries, currencies, worker)}
          />
          {errors.receiving_currency && (
            <Typography className="text_regular__14 color_red">
              {errors.receiving_currency.message}
            </Typography>
          )}
        </div>
        <StyledAlert>
          <StyledAlertText>
            <div className="mr-2">
              <Icon icon={infoIcon} />
            </div>
            <Typography className="">
              This is the currency in which you will receive the money and the
              invoice. Make sure your bank can receive the currency you pick.{' '}
            </Typography>
          </StyledAlertText>
        </StyledAlert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          form="employeeDocumentsForm"
          priority="primary"
          size="small"
          loading={updateMutation.isLoading}
          disabled={updateMutation.isLoading}
          onClick={handleSubmit(handleEdit)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
