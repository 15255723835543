/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './OverviewPage.scss'

import { Api } from '@api/Api'
import ModalSide from '@atoms/ModalSide/ModalSide'
import { AppContext } from '@core/context'
import NumberCard from '@pages/overview/NumberCard'
import { DotWave } from '@uiball/loaders'
import React, { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'

import { Onboarding } from '@features/onboarding/onboarding.component'
import { RemohealthOnboarding } from '@features/remohealth-onboarding/remohealth-onboarding.component'
import { RemohealthPackageInfo } from '@features/remohealth-onboarding/remohealth-package-info'
import { WelcomeTitle } from '@components/welcome-title/welcome-title.component'
import { Card } from '@components/card/card.component'
import { CardHeader } from '@components/card/card-header.component'
import { CardContent } from '@components/card/card-content.component'
import { useBoolean } from 'usehooks-ts'
import {
  getOverviews,
  REMOHEALTH_STATUSES,
  transformToUnisureData,
} from './mock'
import {
  getIpAddress,
  sendUnisureData,
  unisureIsOpened,
} from '../../services/health-insurance.service'
import { getActiveHealthInsurance } from '../../services/profile.service'

function OverviewPage({ onboardingState }) {
  const { worker } = useContext(AppContext)
  const modalOpenRemohealthInfo = useBoolean(false)

  const { isLoading, data: stats } = useQuery('overviewStats', () =>
    Api.get('/contracts/stats/').then((res) => res.data)
  )

  const { data: ip } = useQuery(['ip'], {
    queryFn: getIpAddress,
  })

  const { data: activeInsurance, refetch } = useQuery('activeInsurance', {
    queryFn: () =>
      getActiveHealthInsurance({ contract_id: worker.id, is_last: true }),
    enabled: worker?.state !== 'inactive',
  })
  const insurance = activeInsurance?.results?.length
    ? activeInsurance?.results[0]
    : null

  const unisureIsOpenedMutation = useMutation(
    'unisureIsOpened',
    unisureIsOpened
  )

  const { isLoading: insuranceLoading, mutate } = useMutation(
    'checkInsurance',
    {
      mutationFn: () =>
        getActiveHealthInsurance({ contract_id: worker.id, is_last: true }),
      onSuccess: (res) => {
        if (res?.results[0]) {
          if (
            res.results[0].status === REMOHEALTH_STATUSES.CREATED ||
            res.results[0].status === REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING ||
            res.results[0].status === REMOHEALTH_STATUSES.INFO_OUTSTANDING
          ) {
            const data = transformToUnisureData(worker, res.results[0], ip)
            sendUnisureData(data)
            unisureIsOpenedMutation.mutate({ id: worker.id })
          } else refetch()
        }
      },
    }
  )

  return (
    <div className="overview-page d-flex flex-column h-100">
      <div className="overview-page__header">
        <WelcomeTitle
          firstName={worker?.profile?.first_name || worker?.profile?.last_name}
          description="Here's what we have for you today"
        />
      </div>
      {onboardingState !== 'PASSED' ? (
        <>
          <Card>
            <CardHeader
              title="Onboarding"
              description="Please finish the steps below"
            />
            <CardContent>
              <Onboarding />
            </CardContent>
          </Card>
          {insurance &&
          insurance.contract.needs_unisure_confirmation &&
          (insurance?.status === REMOHEALTH_STATUSES.CREATED ||
            insurance?.status === REMOHEALTH_STATUSES.ACTIVE ||
            insurance?.status === REMOHEALTH_STATUSES.INFO_OUTSTANDING ||
            insurance?.status === REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING ||
            insurance?.status === REMOHEALTH_STATUSES.SUBMITTED) ? (
            <Card>
              <CardHeader
                title="RemoHealth"
                description="Please complete the application"
              />
              <CardContent>
                <RemohealthOnboarding
                  insurance={insurance}
                  loading={insuranceLoading}
                  onClickUnisure={mutate}
                />
              </CardContent>
            </Card>
          ) : null}
        </>
      ) : (
        <div>
          {isLoading && !stats ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="d-flex flex-wrap justify-content-between mt-4">
              {getOverviews(
                stats,
                insurance,
                mutate,
                modalOpenRemohealthInfo.setTrue
              )
                .filter((v) => v.contract_type !== worker?.contract_type)
                .map(
                  (overview) =>
                    !overview.hide && (
                      <NumberCard
                        styleClass="mb-3"
                        {...overview}
                        key={overview.title}
                        image={overview.image}
                        number={overview.number}
                        title={overview.title}
                        link={overview.link}
                        onClick={overview.onClick}
                        subtitle={overview.description}
                      />
                    )
                )}
            </div>
          )}
        </div>
      )}
      {modalOpenRemohealthInfo.value && insurance && (
        <ModalSide
          title={`RemoHealth ${
            insurance.insurance_price?.insurance_type || ''
          }`}
          onClose={modalOpenRemohealthInfo.setFalse}
        >
          <RemohealthPackageInfo insurance={insurance} />
        </ModalSide>
      )}
    </div>
  )
}

export default OverviewPage
