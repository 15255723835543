/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint no-lonely-if: "error" */
import React, { useEffect, useState } from 'react'
import PageButton from '@atoms/Pagination/libs/PageButton/PageButton'
import Arrow from '@atoms/Pagination/libs/Arrow/Arrow'

export default function Pagination({
  total,
  onGetPage,
  pageSize: propsPageSize = 10,
  page,
}) {
  const pageSize = Math.ceil(total / propsPageSize)
  const middleNumber = pageSize - 3

  const [current, setCurrent] = useState(1)
  const [active, setActive] = useState(1)

  useEffect(() => {
    setActive(page)
  }, [page])

  const onClickPage = (_page) => {
    if (pageSize > 6) {
      if (_page + 4 <= pageSize) {
        setCurrent(_page)
      } else {
        setCurrent(middleNumber)
      }
    }
    setActive(_page)
    onGetPage(_page)
  }

  const nextPage = () => {
    if (current <= pageSize - 4) {
      setCurrent(current + 1)
      setActive(active + 1)
      onGetPage(active + 1)
    } else if (active < pageSize) {
      setActive(active + 1)
      onGetPage(active + 1)
    }
  }

  const prevPage = () => {
    if (pageSize > 6 && current > 1) {
      if (active + 3 > pageSize) {
        setCurrent(middleNumber)
      } else {
        setCurrent(current - 1)
      }
    }
    if (active > 0) {
      setActive(active - 1)
      onGetPage(active - 1)
    }
  }

  const getPage = (i) => {
    return current === 1
      ? i + current + 1
      : current < 3
      ? i + current
      : i + current - 1
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <Arrow direction="left" disabled={active === 1} onClick={prevPage} />
      <div className="d-flex">
        <PageButton
          className="ml-8"
          active={active === 1}
          onClick={() => onClickPage(1)}
        >
          1
        </PageButton>
        {current > 3 && <div className="d-flex align-items-center">...</div>}
        {[...Array(pageSize <= 6 ? pageSize - 1 : 4)].map((_, index) => (
          <PageButton
            key={`${_}_button`}
            active={active === getPage(index) && active !== 1}
            onClick={() => onClickPage(getPage(index))}
            className="ml-1"
          >
            {current === 1
              ? index + current + 1
              : current < 3
              ? index + current
              : index + current - 1}
          </PageButton>
        ))}
        {current <= pageSize - 4 && pageSize > 6 && (
          <div className="d-flex align-items-center">...</div>
        )}
        {pageSize > 6 && (
          <PageButton
            className="ml-8"
            active={active === pageSize}
            onClick={() => onClickPage(pageSize)}
          >
            {pageSize}
          </PageButton>
        )}
      </div>
      <Arrow
        direction="right"
        disabled={active === pageSize}
        onClick={nextPage}
      />
    </div>
  )
}
