/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { createSvgPath } from './services/createSvgPath'

export default function Icon({ icon, color, size = 1, ...rest }) {
  // Width and Height must be integer.
  const iconWidth = icon && icon.width
  const iconHeight = icon && icon.height
  const iconSvgParams = icon && icon.svgParams
  const iconPath = icon && icon.path

  const width = Math.round(iconWidth * size)
  const height = Math.round(iconHeight * size)
  const viewBox = `0 0 ${iconWidth} ${iconHeight}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      {...iconSvgParams}
    >
      {createSvgPath(iconPath)}
    </svg>
  )
}
