/* eslint-disable */
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { toast } from 'react-toastify'
import { ACCESS_TOKEN_NAME } from '../AuthProvider'
import { getErrorValue } from '@core/utils'
import Cookies from 'js-cookie'

const BASE_URL = process.env.REACT_APP_BASE_URL
export const BFF_API = process.env.REACT_APP_BFF_API

const customAxios = axios.create({
  baseURL: BASE_URL,
})

const customAxiosNoResponse = axios.create({
  baseURL: BASE_URL,
})

const requestInterceptor = (request) => {
  const ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_NAME)
  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  }
  return request
}

customAxios.interceptors.request.use(requestInterceptor)

customAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error
    if (error?.response?.status === 401 && !config._retry) {
      config._retry = true
      Cookies.set('redirect_url', window.location.href, {
        domain: '.remofirst.com',
      })
      window.location.href = `${BFF_API}/auth/logout`
    }

    if (error?.response?.data?.errors?.length) {
      error?.response?.data.errors.forEach((response) => {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        })
      })
    } else {
      toast.error(error?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      })
    }

    return Promise.reject(error)
  }
)

customAxiosNoResponse.interceptors.request.use(requestInterceptor)

export const useAxiosCall = makeUseAxios({
  axios: customAxios,
})
export const Api = customAxios
export const ApiNoResInterceptor = customAxiosNoResponse
