import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import RadioButton from '@atoms/RadioButton/RadioButton'
import DateInput from '@atoms/DateInput/DateInput'
import Select from '@atoms/Select/Select'
import Input from '@atoms/Input/Input'
import Button from '@atoms/Button/Button'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'
import Typography from '@atoms/Typography/Typography'
import moment from 'moment'
import { useApp } from '@core/context'
import Checkbox from '@atoms/Checkbox/Checkbox'

export default ({ nationalities, onNext }) => {
  const { worker } = useApp()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      full_name: `${worker?.profile?.first_name} ${worker?.profile?.last_name}`,
      needs_work_permit: `${worker?.profile?.needs_work_permit}`,
      sex: '',
      nationality: '',
      birth_date: worker?.profile?.birth_date
        ? new Date(worker?.profile?.birth_date)
        : '',
      phone_number: worker?.profile?.phone_number || '',

      terms: false,
      privacy: false,
    },
  })
  const [hasWorkPermit, setHasWorkPermit] = useState(false)

  const submit = (formValues) => {
    const payload = {
      profile: {
        full_name: formValues.full_name,
        sex: formValues.sex,
        birth_date: moment(formValues.birth_date).format('YYYY-MM-DD'),
        nationality: formValues.nationality.value,
        phone_number: formValues.phone_number,
        needs_work_permit: formValues.needs_work_permit,
      },
      work_permit: hasWorkPermit
        ? {
            expiration_date: moment(formValues.workPermitExpirationDate).format(
              'YYYY-MM-DD'
            ),
          }
        : null,
    }
    onNext(payload)
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <Input
            {...register('full_name', {
              required: 'Full legal name is required',
            })}
            type="text"
            label="Full legal name"
            placeholder="Enter your legal name"
          />
          {errors.full_name && (
            <Typography className="text_regular__14 color_red">
              {errors.full_name.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <RadioButton
            {...register('sex', {
              required: 'Field is required',
            })}
            label="Sex"
            addText="We know sex is non-binary but for insurance and payroll purposes, we need to collect this information"
            options={[
              {
                id: 'sex_male',
                value: 'MALE',
                text: 'Male',
              },
              {
                id: 'sex_female',
                value: 'FEMALE',
                text: 'Female',
              },
              {
                id: 'sex_other',
                value: 'OTHER',
                text: 'Other',
              },
            ]}
          />
          {errors.sex && (
            <Typography className="text_regular__14 color_red">
              {errors.sex.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="nationality"
            rules={{ required: 'Nationality is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label="Nationality"
                  options={nationalities.map(({ nationality }) => ({
                    value: nationality,
                    label: nationality,
                  }))}
                />
              )
            }}
          />
          {errors.nationality && (
            <Typography className="text_regular__14 color_red">
              {errors.nationality.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="birth_date"
            rules={{ required: 'Birthdate is required' }}
            render={({ field }) => {
              return (
                <DateInput maxDate={new Date()} {...field} label="Birthdate" />
              )
            }}
          />
          {errors.birth_date && (
            <Typography className="text_regular__14 color_red">
              {errors.birth_date.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="phone_number"
            rules={{
              required: 'Phone number is required',
            }}
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  international
                  withCountryCallingCode
                  label="Phone number"
                  placeholder="Enter phone number"
                />
              )
            }}
          />
          {errors.phone_number && (
            <Typography className="text_regular__14 color_red">
              {errors.phone_number.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <RadioButton
            {...register('needs_work_permit', {
              required: 'Field is required',
            })}
            label="Do you require a work permit or visa?"
            options={[
              {
                id: 'false',
                value: 'false',
                text: 'No',
              },
              {
                id: 'true',
                value: 'true',
                text: 'Yes',
              },
            ]}
          />
          {errors.needs_work_permit && (
            <Typography className="text_regular__14 color_red">
              {errors.needs_work_permit.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <div className="remo-form-input">
            <Checkbox
              id="work-permit-check"
              value={hasWorkPermit}
              onChange={(e) => setHasWorkPermit(e.target.checked)}
              label={<span>I have the requried work permit or visa</span>}
            />
          </div>

          {hasWorkPermit && (
            <div className="remo-form-input">
              <Controller
                control={control}
                name="workPermitExpirationDate"
                rules={{ required: 'Expiration date is required' }}
                render={({ field }) => {
                  return (
                    <DateInput
                      minDate={new Date()}
                      {...field}
                      label="Work permit"
                      addText="Please provide the expiration date of your work permit"
                    />
                  )
                }}
              />
              {errors.workPermitExpirationDate && (
                <Typography className="text_regular__14 color_red">
                  {errors.workPermitExpirationDate.message}
                </Typography>
              )}
            </div>
          )}
        </div>

        <div className="remo-form-input">
          <Checkbox
            id="terms-check"
            {...register('terms', {
              required: 'Please read and accept the Terms of use to continue',
            })}
            label={
              <span>
                I agree to the
                <a
                  className="ml-1 link"
                  href="https://www.remofirst.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
              </span>
            }
          />
          {errors.terms && (
            <Typography className="text_regular__14 color_red">
              {errors.terms.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Checkbox
            id="privacy-check"
            {...register('privacy', {
              required: 'Please read and accept the Privacy policy to continue',
            })}
            label={
              <span className="text_medium__14">
                I agree to the
                <a
                  className="ml-1 link"
                  href="https://www.remofirst.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </span>
            }
          />
          {errors.privacy && (
            <Typography className="text_regular__14 color_red">
              {errors.privacy.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
