import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { keyboardArrowLeft } from '@core/icons/icons'
import moment from 'moment'
import React, { memo, useState } from 'react'
import styled from 'styled-components'

import ExpenseEvent from './ExpenseEvent'

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: #fff;
`
const Body = styled.div`
  padding: 16px;
  background: #f9f9f9;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`

const Item = memo(({ expense, expandedId, setPaymentId, refetch }) => {
  const isOpen = expandedId === expense.id

  const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)

  return (
    <Styled key={expense.id}>
      <Header className="d-flex align-items-center" open={isOpen}>
        <div
          className="row w-100 align-items-center"
          role="button"
          aria-hidden
          onClick={handleToggle(expense.id)}
        >
          <div className="col-2 d-flex align-items-center">
            <span>
              <Icon
                fill="#B2B2B2"
                style={{
                  cursor: 'pointer',
                  transform:
                    expandedId === expense.id
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)',
                }}
                icon={keyboardArrowLeft}
              />
            </span>
            <div className="ml-3 d-flex flex-column">
              <Typography as="span" className="text_medium__14">
                {expense.contract.full_name}
              </Typography>
            </div>
          </div>
          <div className="col-2">
            <Typography as="span" className="text_regular-normal__14">
              {expense.expense_name}
            </Typography>
          </div>
          <div className="col-2">
            <Typography as="span" className="text_regular-normal__14">
              {moment(expense.created_at).format('DD MMM YYYY')}
            </Typography>
          </div>
          <div className="col-1">
            <Typography as="span" className="text_regular-normal__14">
              {expense.currency.short_code || expense.currency.sign}{' '}
              {expense.amount}
            </Typography>
          </div>
          <div className="col-2 d-flex flex-column">
            <Typography as="span" className="text_regular-normal__14">
              {expense.created_by?.full_name}
            </Typography>
            {expense.created_by.organization_name && (
              <Typography as="span" className="text_light__13 color_text_300">
                {expense.created_by.organization_name}
              </Typography>
            )}
          </div>
          <div className="col-1 justify-content-end d-flex">
            {expense.payout_month}
          </div>
          <div className="col-2 justify-content-end d-flex">
            <Status status={expense.state} />
          </div>
        </div>
      </Header>
      <Body open={isOpen}>
        <ExpenseEvent expense={expense} refetch={refetch} />
      </Body>
    </Styled>
  )
})

const MyAccordion = ({ expenses, createdExpenseId, refetch }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  React.useEffect(() => {
    setExpandedItemId(createdExpenseId)
  }, [createdExpenseId])

  return expenses.map((v) => {
    return (
      <Item
        key={v.id}
        expense={v}
        refetch={refetch}
        expandedId={expandedItemId}
        setPaymentId={setExpandedItemId}
      />
    )
  })
}

MyAccordion.displayName = 'Accardion'

export default memo(MyAccordion)
