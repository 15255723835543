import './EmployeesTimeOff.scss'

import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import { edit, fileIcon } from '@core/icons/icons'
import CreateTimeOffModal, {
  reasonTypes,
} from '@pages/employeesTimeOff/CreateTimeOffModal'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import TextArea from '@atoms/TextArea/TextArea'
import Status from '@atoms/Status/Status'
import { EventSides } from '@pages/employeesTimeOff/enums'
import { DotWave } from '@uiball/loaders'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import { cancelTimeOff, updateTimeOff } from '../../services/absences.service'
import { getContractsPaidLeaves } from '../../services/contract.service'

const Styled = styled.div`
  background: #f9f9f9;
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
`

const StyledComment = styled.div`
  overflow-wrap: break-word;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`
export default ({ timeOff, refetch, isHistory }) => {
  const { successAlert } = useToast()
  const [showEditTimeOff, setShowEditTimeOff] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showApprove, setShowApprove] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const [showReject, setShowReject] = useState(false)
  const cancelTimeOffRequest = useMutation({
    mutationFn: (payload) => cancelTimeOff(payload),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
    },
  })

  const { data: paidLeaves, isLoading: isLoadingPaid } = useQuery(
    ['getContractsPaidLeaves', timeOff],
    {
      queryFn: () => getContractsPaidLeaves(timeOff.contract.id),
      enabled: !!timeOff,
    }
  )

  const onApproveTimeOff = () => {
    cancelTimeOffRequest.mutate({
      id: timeOff.id,
      data: {
        event_side: 'CONTRACT',
        status: 'APPROVED',
      },
    })
  }
  const onRejectTimeOff = () => {
    cancelTimeOffRequest.mutate({
      id: timeOff.id,
      data: {
        event_side: 'CONTRACT',
        status: 'REJECTED',
        reject_reason: rejectReason,
      },
    })
  }

  const handleModalOpen = () => {
    setShowEditTimeOff(true)
  }
  const handleModalClose = () => {
    setShowEditTimeOff(false)
  }
  const editTimeOffRequest = useMutation({
    mutationFn: (payload) => updateTimeOff(payload),
    onSuccess: () => {
      handleModalClose()
      refetch()
      successAlert('Successfully updated!')
    },
  })
  const onCancelTimeOff = () => {
    cancelTimeOffRequest.mutate({
      id: timeOff.id,
      data: {
        event_side: 'CONTRACT',
        status: 'CANCELED',
      },
    })
  }
  const onEditTimeOff = (body) => {
    const data = {
      id: timeOff.id,
      data: {
        ...body,
      },
    }
    editTimeOffRequest.mutate(data)
  }

  const onChangeReject = (e) => {
    setRejectReason(e.target.value)
  }

  const showActions = (timeOffProps) => {
    if (
      timeOffProps.status !== 'CANCELED' &&
      timeOffProps.status !== 'REJECTED' &&
      timeOffProps.status !== 'APPROVED'
    ) {
      const findEvent = timeOffProps.events.filter(
        (evt) => evt.event_side === 'CONTRACT'
      )[0]
      if (findEvent) {
        return (
          findEvent.status !== 'CREATED' &&
          findEvent.status !== 'APPROVED' &&
          findEvent.status !== 'REJECTED'
        )
      }
    }
    return false
  }

  const findRejectStatus = useMemo(
    () => timeOff.events.filter((evt) => evt.status === 'REJECTED')[0],
    [timeOff]
  )
  const findReasonType = useMemo(
    () =>
      reasonTypes[timeOff.leave_type].filter(
        (type) => type.value === timeOff.leave_reason_type
      )[0]?.name,
    [timeOff]
  )
  return (
    <Styled className="timeOffEvent">
      <div className="row justify-content-center">
        {timeOff.status === 'REJECTED' && (
          <div className="col-3 content-body mr-1">
            <Status status="REJECTED" />
            <div className="d-flex justify-content-between mt-2">
              <Typography as="span" className="text_medium__14">
                {findRejectStatus.action_user?.profile.first_name}{' '}
                {findRejectStatus.action_user?.profile.last_name}
              </Typography>
              <Typography
                as="span"
                className="text_regular-normal__14 color_text_300"
              >
                {EventSides[findRejectStatus.event_side]}
              </Typography>
            </div>
            <Typography as="span" className="text_regular-normal__14 mt-2">
              {findRejectStatus.reject_reason}
            </Typography>
          </div>
        )}
        <div className="col-5 content-body ml-1">
          <div className="d-flex justify-content-between mb-2">
            <Typography className="heading_semibold__18">
              Request time off
            </Typography>
            {timeOff.status !== 'CANCELED' &&
              timeOff.status !== 'APPROVED' &&
              timeOff.status !== 'REJECTED' &&
              timeOff.request_creator_role === 'CONTRACT' && (
                <Button
                  onClick={handleModalOpen}
                  priority="secondary"
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0"
                >
                  <Icon fill="none" icon={edit} />
                  <Typography className={classNames('text_light__12', 'ml-2')}>
                    Edit
                  </Typography>
                </Button>
              )}
          </div>

          <StyledTable>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Country
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {timeOff.current_country.name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Day off
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {timeOff.total_days}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Public Holidays
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {timeOff.public_holidays}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Reason
                </Typography>
              </th>
              <td align="right">
                <Typography
                  className="text_regular-normal__14 mr-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  {
                    reasonTypes[timeOff.leave_type].filter(
                      (type) => type.value === timeOff.leave_reason_type
                    )[0]?.label
                  }
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography
                  className="text_regular-normal__14 ml-2"
                  style={{ letterSpacing: '0.4px' }}
                >
                  Attached document
                </Typography>
              </th>
              <td align="right">
                {timeOff.attached_document ? (
                  <a
                    target="_blank"
                    className="text_regular-normal__14 color_black d-flex align-items-center justify-content-end"
                    style={{ textDecoration: 'none' }}
                    href={timeOff.attached_document}
                    rel="noreferrer"
                  >
                    <Icon
                      stroke="black"
                      className="mr-2"
                      fill="white"
                      icon={fileIcon}
                      style={{ cursor: 'pointer' }}
                    />
                    {timeOff.filename}
                  </a>
                ) : (
                  <Typography
                    className="text_regular-normal__14 mr-2"
                    style={{ letterSpacing: '0.4px' }}
                  >
                    -
                  </Typography>
                )}
              </td>
            </tr>
          </StyledTable>
          {timeOff.comment && <StyledComment>{timeOff.comment}</StyledComment>}
          {timeOff.status !== 'CANCELED' &&
            timeOff.status !== 'APPROVED' &&
            timeOff.status !== 'REJECTED' &&
            timeOff.request_creator_role === 'CONTRACT' && (
              <Button
                className="mt-2 w-100"
                size="small"
                onClick={() => setShowConfirm(true)}
                priority="danger_outlined"
              >
                Cancel
              </Button>
            )}
          {showActions(timeOff) && (
            <div className="d-flex justify-content-between">
              <Button
                className="mt-2 w-50 mr-1"
                size="small"
                onClick={() => setShowReject(true)}
                priority="danger_outlined"
              >
                Reject
              </Button>
              <Button
                className="mt-2 w-50 ml-1"
                size="small"
                onClick={() => setShowApprove(true)}
                priority="primary"
              >
                Approve
              </Button>
            </div>
          )}
        </div>
        {!isHistory && timeOff.leave_type === 'PAID' && (
          <div className="col-5 content-body ml-1">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoadingPaid ? (
              <div
                className="edit-avatar__loader"
                style={{ width: 40, height: 40 }}
              >
                <DotWave size={32} speed={1} color="black" />
              </div>
            ) : paidLeaves ? (
              <>
                <Typography className="heading_semibold__18 mb-3">
                  PTO balance
                </Typography>
                <StyledTable>
                  <tr>
                    <th>
                      <Typography
                        className="text_regular-normal__14 ml-2"
                        style={{ letterSpacing: '0.4px' }}
                      >
                        Current PTO balance
                      </Typography>
                    </th>
                    <td align="right">
                      <Typography
                        className="text_regular-normal__14 mr-2"
                        style={{ letterSpacing: '0.4px' }}
                      >
                        {paidLeaves?.leave_days?.[findReasonType]
                          ?.available_days || '0'}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Typography
                        className="text_regular-normal__14 ml-2"
                        style={{ letterSpacing: '0.4px' }}
                      >
                        Time requested
                      </Typography>
                    </th>
                    <td align="right">
                      <Typography
                        className="text_regular-normal__14 mr-2"
                        style={{ letterSpacing: '0.4px' }}
                      >
                        {timeOff.total_days}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Typography
                        className="text_regular-normal__14 ml-2"
                        style={{ letterSpacing: '0.4px' }}
                      >
                        Remaining days after request
                      </Typography>
                    </th>
                    <td align="right">
                      <Typography
                        className="text_regular-normal__14 mr-2"
                        style={{ letterSpacing: '0.4px' }}
                      >
                        {Number(
                          Number(
                            paidLeaves?.leave_days?.[findReasonType]
                              ?.available_days || 0
                          ) - timeOff.total_days
                        ).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </StyledTable>
                {Number(
                  paidLeaves?.leave_days?.[findReasonType]?.available_days || 0
                ) -
                  timeOff.total_days <
                  0 && (
                  <NotificationBlock
                    isError
                    render={
                      <Typography className="text_regular-normal__14 color_red">
                        <strong>Pay attention:</strong> You have taken more days
                        than are available to you. However, you can still create
                        the request and send it for Employer’s approval. Once
                        approved, your remaining balance will become negative.
                      </Typography>
                    }
                  />
                )}
              </>
            ) : null}
          </div>
        )}
      </div>
      {showEditTimeOff && (
        <CreateTimeOffModal
          title="Edit time off"
          onClose={handleModalClose}
          timeOff={timeOff}
          isEdit
          loading={editTimeOffRequest.isLoading}
          onSubmit={onEditTimeOff}
        />
      )}
      {showConfirm && (
        <Confirm
          title="Cancel time off?"
          yesButtonProps={{
            text: 'Cancel Forever',
          }}
          cancelButtonProps={{
            text: 'Close',
          }}
          isLoading={cancelTimeOffRequest.isLoading}
          onCancel={() => setShowConfirm(false)}
          onYes={onCancelTimeOff}
        >
          <Typography>
            This action will cancel the request forever and you will not be able
            to edit it anymore. Employee and their Employer will be both
            notified if cancelled. The request will be stored in History tab.
          </Typography>
        </Confirm>
      )}
      {showReject && (
        <Confirm
          title="Reject"
          isLoading={cancelTimeOffRequest.isLoading}
          onCancel={() => setShowReject(false)}
          onYes={onRejectTimeOff}
          yesButtonProps={{
            text: 'Send',
          }}
        >
          <TextArea
            placeholder="Decline reason"
            rows={4}
            onChange={onChangeReject}
          />
        </Confirm>
      )}
      {showApprove && (
        <Confirm
          title="Approve time off request ?"
          isLoading={cancelTimeOffRequest.isLoading}
          onCancel={() => setShowApprove(false)}
          onYes={onApproveTimeOff}
          yesButtonProps={{
            text: 'Approve',
            priority: 'primary',
          }}
        />
      )}
    </Styled>
  )
}
