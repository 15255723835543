import moment from 'moment'

export const parserPayslips = (payslips) => {
  if (!payslips || !payslips.results) return []
  return payslips.results.map((item) => ({
    id: item.id,
    name: item.filename,
    payslip_for_date: moment(item.payslip_for_date).format('MMMM YYYY'),
    created: moment(item.created_at).format('DD MMM YYYY'),
    original: item,
  }))
}
