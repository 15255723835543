export const REMO_HEALTH_PACKAGE = {
  CORE: 'core',
  STANDARD: 'standard',
  PREMIUM: 'premium',
  PLUS: 'plus',
}

const basePackage = {
  id: 1,
  title: 'Base package',
  description:
    'The RemoHealth Core package is the entry-level benefit option available to Remofirst employees as it provides in-patient benefits only. These benefits focus only on elective and emergency treatment in-patient (in-hospital) treatment as well as international medical evacuation. Coverage is worldwide, excluding Hong Kong, Singapore, Canada and the United States of America.',
  packages: [
    {
      paid: 'Paid up to your annual limit of USD 1,000,000',
      elements: [
        'All in-patient costs',
        'Cancer treatment',
        'Operating theatre charges and intensive care',
        'Transplants — (surgery only)',
        'Parent accommodation',
        'Home nursing after in-patient treatment',
        'Ground ambulance ',
        'Assistance cover ',
        'Local air ambulance',
        'Compassionate visit and living allowance',
        'Specialist fees — whilst in hospital',
        'Kidney failure and treatment',
        'Advanced imaging',
        'Psychiatric treatment ',
        'Pathology, X-rays, diagnostic tests and physiotherapy services — while you’re an admitted patient in hospital',
        'Outside area of cover — Acute conditions are covered for 30 calendar days per trip',
        'Evacuation — We cover the transport costs for  in-patient treatment or day case treatment, as well as for evacuation when the treatment is not available locally',
        'Return journey — We will cover the cost of an economy class air ticket by the most direct route available. In the event of your death while you are away from home, we will pay reasonable costs for transportation only of your body',
      ],
    },
  ],
}

const seriousMatter = {
  id: 2,
  title: 'Serious Matters',
  description:
    'The Serious Matters benefit forms the foundation of the RemoHealth Standard, Plus and Premium products. The benefits focus on elective and emergency treatment in- patient (in-hospital) treatment as well as international medical evacuation. Over and above the standard hospitalisation benefits, it also makes provision for accident-related dental treatment, hospice and palliative care, as well as psychiatric treatment. Coverage is worldwide, excluding Canada and the United States. All Serious Matters benefits are subject to pre-authorisation.',
  packages: [
    {
      paid: 'Paid up to your annual limit USD 5 000 000',
      elements: [
        'All in-patient costs',
        'Cancer treatment',
        'Transplants — (surgery only)',
        'Parent accommodation',
        'Specialist fees — whilst in hospital',
        'Nursing care — medicine, and surgical dressings',
        'Hospice and palliative care — up to USD 40 000  following t he diagnosis t hat your condition is terminal',
        'Kidney failure and treatment',
        'Operating t heatre charges — and intensive care',
        'Home nursing after in-patient treatment',
        'Physicians’ and Specialist ’ fees — whilst in hospital',
        'Surgical operations — including pre-and post-operative care',
        'In-patient rehabilitation',
        'Psychiatric treatment ',
        'Pathology',
        'Evacuation — We cover the transport costs for  in-patient treatment or day case treatment, as well as for evacuation when the treatment is not available locally',
        'Ground ambulance ',
        'Prost heses, implants and appliances',
        'Assistance cover ',
        'Local air ambulance',
        'Outside area of cover — Acute conditions are covered for 30 calendar days per trip',
        'Compassionate visit and living allowance',
        'Return journey — We will cover the cost of an economy class air ticket by the most direct route available. In the event of your death while you are away from home, we will pay reasonable costs for transportation only of your body',
        'Pathology, X-rays, diagnostic tests and physiotherapy services — while you’re an admitted patient in hospital',
        'Accident-related dental treatment',
      ],
    },
  ],
}

const dailyMatter = {
  id: 3,
  title: 'Daily Matters',
  description:
    'We understand that sometimes ‘life happens’, which is why we’ve developed essential day-to-day benefits for all of those regular services (such as GP and specialist visits) and weird and wonderful tests (such as blood tests, X- rays and ECGs) that you may need. Accidents do happen from time to time, which is why these benefits cover accident-related dental treatment and advancing imaging (such as MRI, CT and PET scans). All Daily Matters benefits are on a reimbursement basis where members pay upfront for treatment and services and claim back from the Umatter policy.',
  packages: [
    {
      paid: 'Paid in full up to overall limit',
      elements: [
        'Specialist’ fees',
        'Basic pathology',
        'General Medical Practitioner (GP) fees',
        'Advanced imaging — (pre-authorisations required)',
      ],
    },
    {
      paid: 'USD 20 000',
      elements: ['HIV/AIDS drug therapy'],
    },
    {
      paid: 'Limited to USD 4 000 per prosthetic device per year',
      elements: ['Prosthetic devices required at the time of treatment'],
    },
  ],
}

const optiDentiMatters = {
  id: 4,
  title: 'Opti-Denti Matters',
  description:
    'It matters to us that you’re seeing a bright future and smiling about what lies ahead. This section offers optional dental treatment and optical benefits, including preventative tests and checks (such as eye tests and dental check-ups), as well as routine dental maintenance and new spectacles and contact lenses. All Opti-Denti Matters benefits are on a reimbursement basis where members pay upfront for treatment and services and claim back from the Umatter policy.',
  packages: [
    {
      paid: 'USD 400 - Optical',
      elements: [
        'Eye test —  every year',
        'The percentages (%) represent the reimbursement rates.',
        '75% for non-cosmetic spectacles and contact lenses',
        'Frames (75%) and lenses (every 2 years)',
      ],
    },
    {
      paid: 'USD 4200 - Dental treatment',
      elements: [
        'Preventative (100%)',
        'Routine (80%)',
        'Major restorative, orthodontic and periodontal (50%). A 6-month (six) waiting period is applicable to this benefit.',
      ],
    },
  ],
}

const wellnessMatters = {
  id: 5,
  title: 'Wellness Matters',
  description:
    'We believe that everyone should take their health matters into their own hands and adopt a holistic approach to their overall well-being. A healthy body needs a healthy mind to guide it, which is why our optional wellness benefits are of our most popular services. These optional extras include cover for full wellness screenings and preventative tests, as well as treatment by psychologists and complementary medicine practitioners. All Wellness Matters benefits are on a reimbursement basis where members pay upfront for treatment and services and claim back from the Umatter policy.',
  packages: [
    {
      paid: '40 visits',
      elements: [
        'Complementary medicine practitioners For necessary medical treatment, we pay for supplementary treatment provided by acupuncturists, chiropractors, homeopaths, osteopaths and traditional Chinese medicine, psychologists, psychiatrists, occupational therapy, orthoptists, dieticians, speech therapists and ergotherapy',
      ],
    },
    {
      paid: 'USD 2 000',
      elements: ['Wellness & Full health screening'],
    },
    {
      paid: 'USD 1 000',
      elements: ['Child wellness'],
    },
    {
      paid: 'USD 350',
      elements: ['Vaccinations'],
    },
    {
      paid: '12 Visits',
      elements: ['Physiotherapists and Chiropractors'],
    },
  ],
}

export const REMOHEALTH_INFO = {
  [REMO_HEALTH_PACKAGE.CORE]: [basePackage],
  [REMO_HEALTH_PACKAGE.STANDARD]: [basePackage, seriousMatter, dailyMatter],
  [REMO_HEALTH_PACKAGE.PLUS]: [
    basePackage,
    seriousMatter,
    dailyMatter,
    optiDentiMatters,
  ],
  [REMO_HEALTH_PACKAGE.PREMIUM]: [
    basePackage,
    seriousMatter,
    dailyMatter,
    optiDentiMatters,
    wellnessMatters,
  ],
}
