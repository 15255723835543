/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { add, searchIcon } from '@core/icons/icons'
import { useAxiosCall } from '@api/Api'
import Icon from '@atoms/Icon/Icon'
import CreateThread from '@pages/chat/CreateThread/CreateThread'
import Input from '@atoms/Input/Input'
import Tabs from '@atoms/Tabs/Tabs'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import ThreadEditModal from '@pages/chat/ThreadEditModal/ThreadEditModal'
import ChatList from '@pages/chat/ChatList/ChatList'
import ThreadList from '@pages/chat/ThreadList/ThreadList'
import './Chat.scss'
import EditModal from '@atoms/EditModal/EditModal'
import AddMemberModal from '@pages/chat/AddMemberModal/AddMemberModal'
import NoFound from '@pages/chat/NoFound/NoFound'

export default function Chat() {
  const [visible, setVisible] = useState(false)
  const [visibleAbout, setVisibleAbout] = useState(false)
  const [currentThread, setCurrentThread] = useState()
  const [messages, setMessages] = useState([])
  const [messagePage, setMessagePage] = useState(0)
  const [activeChat, setActiveChat] = useState(true)
  const [visibleAlert, setVisibleAlert] = useState(false)
  const [visibleAddMember, setVisibleAddMember] = useState(false)
  const [search, setSearch] = useState()
  const [{ data, loading }, getThreads] = useAxiosCall(
    {
      url: `threads/?state=${
        activeChat ? 'ACTIVE' : 'RESOLVED'
      }&limit=100&offset=0`,
    },
    {
      manual: true,
    }
  )
  const [{ loading: loadingUpdate }, patchThreadUpdate] = useAxiosCall(
    {
      method: 'patch',
      url: `threads/${currentThread?.id}/update/`,
    },
    {
      manual: true,
    }
  )
  const [, postThreads] = useAxiosCall(
    {
      method: 'post',
      url: `threads/create/`,
    },
    {
      manual: true,
    }
  )
  const onClickThread = useCallback(
    (thread) => () => {
      setMessages([])
      setMessagePage(0)
      setCurrentThread(thread)
    },
    []
  )

  const onClickTab = useCallback((tabId) => {
    setActiveChat(tabId === 0)
    setMessages([])
    setMessagePage(0)
    setCurrentThread(undefined)
  }, [])

  const onCreateThread = (threadData) => {
    postThreads({ data: threadData }).then(() => {
      if (activeChat) {
        getThreads()
      } else {
        setActiveChat(true)
      }
    })
    setVisible(false)
  }

  const filteredThreads = useMemo(
    () =>
      search
        ? data?.results?.filter((thread) =>
            thread.topic.toLowerCase().includes(search.toLowerCase())
          )
        : data?.results || [],
    [search, data]
  )

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
  }

  const onFinishResolve = useCallback(() => {
    patchThreadUpdate({
      data: { state: activeChat ? 'RESOLVED' : 'ACTIVE' },
    }).then(() => {
      setCurrentThread(undefined)
      if (activeChat) {
        getThreads()
      } else {
        setActiveChat(true)
      }
    })
    setVisibleAlert(false)
  }, [currentThread])

  const onClickResolve = useCallback(() => {
    setVisibleAbout(false)
    setVisibleAlert(true)
  }, [])

  const onTopicResolve = useCallback(() => {
    setVisibleAlert(true)
  }, [])

  const onClickAddMember = useCallback(() => {
    setVisibleAddMember(true)
  }, [])

  useEffect(() => {
    getThreads()
  }, [activeChat])
  return (
    <div className="chat d-flex w-100">
      <div
        className="chat_threads d-flex flex-column justify-content-between mr-5 h-100"
        style={{ minWidth: 340, maxWidth: 340 }}
      >
        <div
          className="d-flex flex-column"
          style={{ height: '100%', overflow: 'hidden' }}
        >
          <Typography className="heading_semibold__24 mb-4">Connect</Typography>
          <Input
            onChange={onChangeSearch}
            placeholder="Search"
            type="text"
            name="search"
            value={search}
            endIcon={<Icon icon={searchIcon} />}
            styleClass="chat-search mb-4"
          />
          <Tabs selectedTab={activeChat ? 0 : 1} onTabChange={onClickTab}>
            <Tab
              tabId={0}
              title="Opened"
              count={
                !loading && data?.count > 0 && activeChat
                  ? data?.count
                  : undefined
              }
            >
              {search && search.length > 0 && filteredThreads.length === 0 ? (
                <NoFound />
              ) : (
                <ThreadList
                  type="unresolved"
                  currentThread={currentThread}
                  filteredThreads={filteredThreads}
                  loading={loading || loadingUpdate}
                  onClickThread={onClickThread}
                />
              )}
            </Tab>
            <Tab
              tabId={1}
              title="Closed"
              count={
                !loading && data?.count > 0 && !activeChat
                  ? data?.count
                  : undefined
              }
            >
              {search && search.length > 0 && filteredThreads.length === 0 ? (
                <NoFound />
              ) : (
                <ThreadList
                  type="resolved"
                  currentThread={currentThread}
                  filteredThreads={filteredThreads}
                  loading={loading || loadingUpdate}
                  onClickThread={onClickThread}
                />
              )}
            </Tab>
          </Tabs>
        </div>
        <Button
          className="mb-1"
          priority="secondary"
          size="medium"
          onClick={() => setVisible(true)}
        >
          New ticket
          <Icon icon={add} className="ml-1" />
        </Button>
      </div>
      {currentThread && (
        <ChatList
          currentThread={currentThread}
          activeChat={activeChat}
          messagePage={messagePage}
          messages={messages}
          onClickAddMember={onClickAddMember}
          setMessagePage={setMessagePage}
          setMessages={setMessages}
          setVisibleAbout={setVisibleAbout}
          onClickResolve={onTopicResolve}
          isOwner={currentThread?.is_owner}
        />
      )}
      {visible && (
        <CreateThread onSave={onCreateThread} setVisible={setVisible} />
      )}
      {visibleAbout && (
        <ThreadEditModal
          closeModal={() => {
            setVisibleAbout(false)
          }}
          onClickResolve={onClickResolve}
          thread={currentThread}
          activeChat={activeChat}
          isOwner={currentThread?.is_owner}
        />
      )}
      {currentThread && (
        <AddMemberModal
          visible={visibleAddMember}
          setVisible={setVisibleAddMember}
          threadId={currentThread.id}
        />
      )}
      <EditModal
        visible={visibleAlert}
        footer={false}
        closeModal={() => setVisibleAlert(false)}
      >
        <div className="d-flex align-items-center flex-column">
          <img src="/assets/img/oc-handshake.png" alt="handshake" />
          <Typography className="heading_semibold__24 mt-5 mb-3 text-center">
            Ready to close this ticket?
          </Typography>
          <Typography className="text_regular__14 color_text_300 text-center">
            Your dialogue will be saved. You can always find it in the Closed
            tab.
          </Typography>
          <div className="d-flex mt-4">
            <Button
              priority="secondary"
              size="medium"
              onClick={() => setVisibleAlert(false)}
            >
              Cancel
            </Button>

            <Button
              className="ml-4"
              priority="primary_black"
              size="medium"
              onClick={onFinishResolve}
            >
              {`${activeChat ? 'Close' : 'Open'} ticket`}
            </Button>
          </div>
        </div>
      </EditModal>
    </div>
  )
}
