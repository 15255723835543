import React, { useContext, useEffect } from 'react'
import Typography from '@atoms/Typography/Typography'
import { Controller, useForm } from 'react-hook-form'
import DateInput from '@atoms/DateInput/DateInput'
import Input from '@atoms/Input/Input'
import { getCurrency, SplitNumber } from '@core/utils'
import styled from 'styled-components'
import { AppContext } from '@core/context'
import { ContractorWageType } from '@pages/payments/parse'

import { getDaysInMonth, differenceInDays, addDays } from 'date-fns'
import classNames from 'classnames'

const WorkingTime = {
  monthly: 'months',
  hourly: 'hours',
  daily: 'days',
}

const WrapperTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f9f9f9;
  border-radius: 8px;
`

const TotalItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
  &:first-child {
    border-bottom: 1px solid #dfdfdf;
  }
`

const countMonth = (start, end) => {
  const months =
    differenceInDays(addDays(end, 1), start) / getDaysInMonth(start)
  return months.toFixed(2)
}

const countDays = (start, end) => {
  return differenceInDays(addDays(end, 1), start)
}

const countHours = (start, end, hoursPerDay = 8) => {
  return differenceInDays(addDays(end, 1), start) * hoursPerDay
}

export const TabCurrentRate = ({ onSubmit }) => {
  const { worker } = useContext(AppContext)

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm()

  const workingTime = +watch('worked_time')
  const startDate = watch('start_date')
  const endDate = watch('end_date')

  useEffect(() => {
    if (startDate && endDate) {
      if (worker.compensation.contractor_wage_type === 'monthly') {
        setValue('worked_time', countMonth(startDate, endDate))
      }
      if (worker.compensation.contractor_wage_type === 'daily') {
        setValue('worked_time', countDays(startDate, endDate))
      }
      if (worker.compensation.contractor_wage_type === 'hourly') {
        setValue('worked_time', countHours(startDate, endDate))
      }
    }
  }, [startDate, endDate])

  return (
    <form
      id="timeOff-form"
      key="CurrenctRate"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography className="text_medium__14 my-1">
        {' '}
        Specify the period{' '}
      </Typography>
      <div className="row">
        <div className="col-6 pr-2">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="start_date"
              rules={{ required: 'Start date is required' }}
              render={({ field }) => {
                return <DateInput {...field} placeholder="Start date" />
              }}
            />
            {errors.start_date && (
              <Typography className="text_regular__14 color_red">
                {errors.start_date.message}
              </Typography>
            )}
          </div>
        </div>
        <div className="col-6 pl-2">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="end_date"
              rules={{ required: 'End date is required' }}
              render={({ field }) => {
                return (
                  <DateInput
                    {...field}
                    placeholder="End date"
                    minDate={startDate}
                  />
                )
              }}
            />
            {errors.end_date && (
              <Typography className="text_regular__14 color_red">
                {errors.end_date.message}
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('w-100 remo-form-input', {
          'd-none': worker.compensation.contractor_wage_type === 'monthly',
        })}
      >
        <Input
          {...register('worked_time', {
            required: `Total working ${
              WorkingTime[worker.compensation.contractor_wage_type]
            } is required`,
          })}
          disabled={worker.compensation.contractor_wage_type === 'monthly'}
          type="number"
          label={`Total working ${
            WorkingTime[worker.compensation.contractor_wage_type]
          }`}
          placeholder="Total"
          required
        />

        {errors?.worked_time && (
          <Typography className="text_regular__14 color_red">
            {errors?.worked_time.message}
          </Typography>
        )}
      </div>

      {workingTime ? (
        <WrapperTotal>
          <TotalItem>
            <div>
              {`${
                worker.compensation.contractor_wage_type
                  .charAt(0)
                  .toUpperCase() +
                worker.compensation.contractor_wage_type.slice(1)
              } rate:`}
            </div>
            <div>
              {`${getCurrency(worker.compensation?.currency)} ${SplitNumber(
                worker.compensation.contractor_rate
              )}`}
            </div>
          </TotalItem>
          {worker.compensation.contractor_wage_type !==
          ContractorWageType.monthly ? (
            <TotalItem>
              <div>Payment amount:</div>
              <div>
                {`${getCurrency(worker.compensation?.currency)} ${SplitNumber(
                  worker.compensation.contractor_rate * workingTime
                )}`}
              </div>
            </TotalItem>
          ) : null}
        </WrapperTotal>
      ) : null}
    </form>
  )
}
