import Button from '@atoms/Button/Button'
import { CardStepper } from '@components/card-stepper/card-stepper.component'
import { CardStepperStep } from '@components/card-stepper/card-stepper-step.component'
import {
  REMOHEALTH_STATUSES,
  RemohealthStateDescription,
} from '@pages/overview/mock'

import React from 'react'

export const RemohealthOnboarding = ({
  insurance,
  loading,
  onClickUnisure,
}) => {
  const completed = (status) => {
    switch (status) {
      case REMOHEALTH_STATUSES.SUBMITTED:
      case REMOHEALTH_STATUSES.ACCEPTED:
      case REMOHEALTH_STATUSES.ACTIVE:
        return true
      default:
        return false
    }
  }
  const rejected = (status) => {
    switch (status) {
      case REMOHEALTH_STATUSES.CANCELED:
      case REMOHEALTH_STATUSES.EXPIRED:
        return true
      default:
        return false
    }
  }
  if (!insurance) return null

  const { status } = insurance

  return (
    <CardStepper>
      <CardStepperStep
        title="Health insurance enrollment form"
        description={RemohealthStateDescription(status)}
        completed={completed(status)}
        rejected={rejected(status)}
        actions={[
          (status === REMOHEALTH_STATUSES.CREATED ||
            status === REMOHEALTH_STATUSES.INFO_OUTSTANDING ||
            status === REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING) && (
            <Button
              key={1}
              type="button"
              size="small"
              priority="primary"
              loading={loading}
              onClick={onClickUnisure}
            >
              Fill out the form
            </Button>
          ),
        ]}
      />
    </CardStepper>
  )
}
