import React from 'react'
import PageTitle from '@atoms/PageTitle/PageTitle'
import { useMutation, useQuery } from 'react-query'
import { Api } from '@api/Api'
import Tabs from '@atoms/Tabs/Tabs'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import { useApp } from '@core/context'
import { DotWave } from '@uiball/loaders'
import JobDetails from '@pages/profile/libs/JobDetails'

import Typography from '@atoms/Typography/Typography'
import Button from '@atoms/Button/Button'
import { toast } from 'react-toastify'
import useBoolean from '@core/hooks/useBoolean'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import PersonalInfo from './libs/PersonalInfo'
import { PaymentDetailsTab } from './libs/PaymentDetailsTab/PaymentDetailsTab'
import { getResetPassword } from '../../services/app.service'
import Benefit from './libs/Benefit'
import './style.scss'
import EmergencyContact from './libs/EmergencyContact/EmergencyContact'
import { SecurityTab } from './libs/SecurityMFA/security-2fa-tab'

const StyledTabs = styled(Tabs)`
  .tab-content {
    overflow: initial;
  }
`

function ProfilePage() {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const activeTab = queryParams.get('tab') || '0'

  const { worker } = useApp()
  const { toggle: clickReset, value: resetClick } = useBoolean(false)

  const { isLoading, data } = useQuery('profile', () =>
    Api.get('/profile/me/').then((res) => res.data)
  )
  const resetPassword = useMutation({
    mutationFn: (payload) => getResetPassword(payload.email),
    onSuccess: () => {
      toast.success('Check your email for a link to set your password.')
    },
  })
  const onResetPassword = () => {
    clickReset()
    resetPassword.mutate({ email: data.profile?.email })
  }

  const handleTabChange = (tab) => {
    queryParams.set('tab', tab)
    history.push({ search: queryParams.toString() })
  }

  return (
    <div className="profile-page h-100">
      {isLoading && !data ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div className="profile-page__content h-100">
          <PageTitle>Account</PageTitle>
          <div className="mt-4">
            <StyledTabs onTabChange={handleTabChange} selectedTab={+activeTab}>
              <Tab tabId={0} title="Personal Info">
                {data && <PersonalInfo profile={data.profile} />}
              </Tab>
              <Tab tabId={1} title="Job Details">
                <JobDetails />
              </Tab>
              <Tab
                tabId={2}
                title="Payment Details"
                hidden={worker && worker.contract_type === 'full_time_employee'}
              >
                <PaymentDetailsTab />
              </Tab>
              <Tab
                tabId={3}
                title="Benefits"
                hidden={worker && worker.contract_type === 'contractor'}
              >
                <Benefit employee={worker} />
              </Tab>
              <Tab
                tabId={5}
                title="Emergency contact"
                hidden={worker && worker.contract_type === 'contractor'}
              >
                <EmergencyContact />
              </Tab>
              <Tab
                tabId={4}
                title="Password & Account"
                hidden={worker && worker.contract_type === 'contractor'}
              >
                <div>
                  <Typography className="heading_semibold__20 mt-3 mb-4">
                    Password & Account
                  </Typography>
                  <Typography className="text_medium__14 mb-2">
                    Password reset
                  </Typography>
                  <Typography
                    className="text_light__12 color_text_300"
                    style={{ width: 480, fontWeight: 500 }}
                  >
                    Click the button below to reset password and send the email:
                    <Typography className=" color_black">
                      {data?.profile?.email}
                    </Typography>
                  </Typography>
                  <Button
                    className="mt-3"
                    priority="secondary"
                    size="small"
                    disabled={resetClick}
                    loading={resetPassword.isLoading}
                    onClick={onResetPassword}
                  >
                    {resetClick ? 'Check your email' : 'Reset password'}
                  </Button>
                </div>
              </Tab>
              <Tab tabId={6} title="Security">
                <SecurityTab />
              </Tab>
            </StyledTabs>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfilePage
