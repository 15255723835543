/* eslint-disable react/jsx-no-bind */
import EditModal from '@atoms/EditModal/EditModal'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import React, { useState } from 'react'

import { useBoolean } from 'usehooks-ts'

import { useApp } from '@core/context'
import { useMutation } from 'react-query'
import { addMFA } from '../../../../services/mfa.service'
import { DisablingMfaModal } from './disabling-mfa-modal'
import { EnablingMfaModal } from './enabling-mfa-modal'
import { DownLoadAuthenticator } from './download-authenticator'

export const SecurityTab = () => {
  const { profile } = useApp()
  const disablingConfirmOpen = useBoolean(false)
  const enablingMFAOpen = useBoolean(false)
  const disablingMFAOpen = useBoolean(false)
  const [authState, setAuthState] = useState({
    recovery_codes: [],
    barcode_uri: '',
    authenticator_type: '',
    secret: '',
  })

  const { mutate: mutateAddMFA, isLoading: isLoadingAddMFA } = useMutation(
    'addMFA',
    addMFA,
    {
      onSuccess: (response) => {
        setAuthState(response)
        enablingMFAOpen.setTrue()
      },
    }
  )

  const handleToggleMFA = () => {
    if (profile.enable_mfa) disablingConfirmOpen.setTrue()
    else mutateAddMFA()
  }

  function handleKeyDown(e) {
    const keyboardEvent = e
    if (keyboardEvent.key === 'Enter') {
      const nextStep = document.getElementById('next-step')
      nextStep?.click()
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <Toggle
          disabled={isLoadingAddMFA}
          checked={profile.enable_mfa}
          onChange={handleToggleMFA}
        />
        <Typography className="heading_semibold__16 ml-2">
          Two-factor authentication (2FA)
        </Typography>
      </div>
      <Typography className="text_light__14 color_black_light mt-3">
        Two-factor authentication (2FA) keeps your account safe by requiring you
        to provide two types of verification (e.g., a password and a smartphone
        code).
      </Typography>

      {!profile.enable_mfa && <DownLoadAuthenticator />}

      <EditModal
        visible={disablingConfirmOpen.value}
        title="Disable two-factor authentication (2FA)?"
        okText="Confirm"
        onSave={() => {
          disablingMFAOpen.setTrue()
          disablingConfirmOpen.setFalse()
        }}
        closeModal={disablingConfirmOpen.setFalse}
      >
        <Typography className="text_light__14 color_black_light">
          After disabling, you will log in to the system in the standard way
        </Typography>
      </EditModal>

      <EnablingMfaModal
        recovery_codes={authState.recovery_codes}
        barcode_uri={authState.barcode_uri}
        secret={authState.secret}
        open={enablingMFAOpen.value}
        onClose={enablingMFAOpen.setFalse}
        onKeyDown={handleKeyDown}
      />

      <DisablingMfaModal
        open={disablingMFAOpen.value}
        onClose={disablingMFAOpen.setFalse}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
