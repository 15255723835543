/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

export default forwardRef((props, ref) => {
  const { label, addText, value, name, options, column, ...rest } = props
  return (
    <div {...rest} className="radio">
      <label>
        <Typography
          className={classNames([
            'text_medium__14',
            !addText && 'remo-form-label',
          ])}
        >
          {label}
        </Typography>
        {addText && (
          <Typography
            className="text_light__12 color_text_300 mt-1"
            style={{ marginBottom: 12, whiteSpace: 'pre-line' }}
          >
            {addText}
          </Typography>
        )}
        <div
          className={classNames('d-flex', {
            'align-items-center': !column,
            'flex-column': column,
          })}
        >
          {options?.map((option) => (
            <div key={option.id} className="d-flex align-items-center mb-2">
              <Typography
                as="label"
                component="label"
                htmlFor={option.id}
                className="text_regular__14 mr-2"
              >
                <input
                  ref={ref}
                  type="radio"
                  name={name}
                  id={option.id}
                  checked={value ? value === option.value : undefined}
                  value={option.value}
                  {...rest}
                />
                <span className="ml-1">{option.text}</span>
              </Typography>
            </div>
          ))}
        </div>
      </label>
    </div>
  )
})
