import Typography from '@atoms/Typography/Typography'
import React from 'react'

/**
 * @param {Function} setStep required
 */
export default ({
  title,
  disabled,
  forceDisabled,
  setStep,
  stepIndex,
  ...rest
}) => {
  const handleClick = () => {
    setStep(stepIndex + 1)
  }

  return (
    <section className="d-flex flex-column">
      <button
        style={{ border: 'none', background: 'none' }}
        type="button"
        disabled={forceDisabled || disabled}
        onClick={handleClick}
      >
        <Typography className="heading_semibold__18">{title}</Typography>
      </button>
      <div>{rest.children}</div>
    </section>
  )
}
