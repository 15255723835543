import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import styledComponents from 'styled-components'

const StyledModal = styledComponents(Modal)`
  .modal-content {
    padding: 16px;
    border-radius: 16px;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
`

export default function EditModal({
  visible,
  closeModal,
  title,
  onSave,
  size = 'md',
  children,
  isLoading: loading = false,
  scrollable = false,
  footer = true,
  okText = 'Save',
}) {
  return (
    <StyledModal
      centered
      size={size}
      show={visible}
      onHide={closeModal}
      scrollable={scrollable}
      animation={false}
    >
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Button
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            priority="primary"
            size="small"
            disabled={loading}
            onClick={onSave}
          >
            {okText}
          </Button>
        </Modal.Footer>
      )}
    </StyledModal>
  )
}
