import NotFound from '@atoms/NotFound/NotFound'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { parseHolidays } from '@pages/employeesTimeOff/parse'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getHolidays } from '../../services/holidays.service'

const generateArrayOfYears = () => {
  const max = new Date().getFullYear()
  const min = max + 9
  const years = []

  // eslint-disable-next-line no-plusplus
  for (let i = max; i <= min; i++) {
    years.push(i)
  }
  return years
}

export default ({ title = 'Public Holidays', onClose }) => {
  const { countriesActive } = useContext(AppContext)
  const [selectedCountry, setSelectedCountry] = useState()
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const { isLoading, data } = useQuery(
    ['holidays', selectedCountry, selectedYear],
    {
      queryFn: () => getHolidays(selectedCountry, selectedYear),
      enabled: !!selectedCountry,
    }
  )
  const onChangeCountry = (option) => {
    setSelectedCountry(option.value)
  }
  const onChangeYear = (option) => {
    setSelectedYear(option.value)
  }

  const fields = [
    {
      title: 'Date',
      key: 'date',
      type: 'string',
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      title: 'Day',
      key: 'day',
      type: 'string',
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      title: 'Name',
      key: 'name',
      type: 'textMedium',
      maxWidth: 260,
      minWidth: 260,
      width: 260,
    },
  ]

  return (
    <Modal
      show
      centered
      size="lg"
      className="dialog-modal"
      dialogClassName="modal-90w"
      onHide={onClose}
    >
      <Modal.Header closeButton className="mb-0">
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body className="px-3 pt-2">
        <div className="row">
          <div className="col-6">
            <div className="remo-form-input">
              <Select
                options={countriesActive.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                name="select_country"
                id="select_country"
                placeholder="e.g United Kingdom"
                onChange={onChangeCountry}
                value={selectedCountry}
                label="Select country"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="remo-form-input">
              <Select
                options={generateArrayOfYears().map((year) => ({
                  value: year,
                  label: year,
                }))}
                name="year"
                id="year"
                placeholder="e.g 2022"
                onChange={onChangeYear}
                value={selectedYear}
                label="Year"
              />
            </div>
          </div>
        </div>
        {!data ? (
          <NotFound
            loading={isLoading}
            className="holiday-not-found"
            title="Please select a country"
            description="You will see the list of the public holidays in this country"
          />
        ) : (
          <div
            className={classNames([
              'row holidays-table',
              data?.results?.length > 1 && 'holidays-table-height',
            ])}
          >
            {data && data?.results?.length >= 1 ? (
              <CustomTable
                loading={isLoading}
                fields={fields}
                data={parseHolidays(data.results)}
              />
            ) : (
              <NotFound
                loading={isLoading}
                className="holiday-not-found"
                title="We have not found any days off for this country and year"
              />
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
