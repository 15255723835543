import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import Tab from './libs/Tab/Tab'
import './style.scss'

export default function Tabs({
  className,
  children,
  selectedTab,
  mode,
  onTabChange,
}) {
  const [active, setActive] = useState(selectedTab || 0)
  const onClickTab = useCallback(
    (id) => {
      if (onTabChange) {
        onTabChange(id)
      }
      setActive(id)
    },
    [onTabChange]
  )

  useEffect(() => {
    if (selectedTab) setActive(selectedTab)
  }, [selectedTab])

  return (
    <div className={classNames(className, 'tabs')}>
      <div className="d-flex align-items-center tabs">
        {children?.map((tab, index) => {
          return tab && tab.props ? (
            <Tab
              tabId={tab.props.tabId}
              title={tab.props.title}
              mode={mode}
              active={active === tab.props.tabId}
              onClick={onClickTab}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              hidden={tab.props.hidden}
            />
          ) : null
        })}
        <div className="tabs__line" />
      </div>
      <div className="tab-content mt-4">
        {children?.find((tab) => tab?.props?.tabId === active)?.props.children}
      </div>
    </div>
  )
}
