import axios from 'axios'
import { Api } from '@api/Api'

export const getIpAddress = async () => {
  const res = await axios.get('https://api.ipify.org?format=json')
  return res.data.ip
}

export function sendUnisureData(params) {
  const form = document.createElement('form')
  form.method = 'post'
  form.target = '_blank'
  form.action = process.env.REACT_APP_UNISURE_LINK

  Object.keys(params).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = params[key]

      form.appendChild(hiddenField)
    }
  })

  document.body.appendChild(form)
  form.submit()
}

export const unisureIsOpened = async ({ id }) => {
  const { data } = await Api.post(`contracts/${id}/insurance-is-opened/`)

  return data
}
