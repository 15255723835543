import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  margin-bottom: 1rem;
`
const Title = styled.div`
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 4px;
`

const Description = styled.div`
  font-size: 0.875rem;
  color: #878787;

  a {
    color: #04c53a;
  }
`

export const CardHeader = ({ title, description }) => (
  <Root>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Root>
)
