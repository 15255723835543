import React from 'react'
import ContentPlaceholder from '../content-placeholder/content-placeholder.component'

export default function StepAgreement({ worker }) {
  return worker.agreement_signed ? (
    <ContentPlaceholder
      imgSrc="/assets/img/document-signed.png"
      title="Welcome to the team"
      description="You have signed all the documents and now you are ready to use the platform"
      info="If you had already signed the agreement and the page has not changed, try refreshing the page"
    />
  ) : (
    <ContentPlaceholder
      imgSrc="/assets/img/document-sign.png"
      title="Sign the contract from your email"
      description="We are crafting your contract. Soon you will receive an email from Remofirst asking you to sign the agreement. Please, check your Inbox for an email."
      info="If you had already signed the agreement and the page has not changed, try refreshing the page"
    />
  )
}
