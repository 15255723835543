import React, { useEffect, useState } from 'react'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Step from '@atoms/Stepper/Step'
import styled from 'styled-components'
import { useAuth } from '../../../AuthProvider'

import PersonalProfileForm from './forms/PersonalProfileForm'
import AddressForm from './forms/AddressForm'
import PaymentsForm from './forms/PaymentsForm'
import HomeAddressForm from './forms/HomeAddressForm'

// const step1 = 'Terms of Service'
const step1 = 'Personal profile'
const step2 = 'Billing address'
const step3 = 'Home address'
const step4 = 'Payment Details'

const TermsWrapper = styled.div`
  position: relative;
  height: calc(100% - 40px); // 40px is margin-top of header
`
const TermsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default ({ loading, onSave }) => {
  const [activeStep, setStep] = useState(1)
  const [address, setAddress] = useState(null)
  const { logout } = useAuth()

  const [payload, setPayload] = useState({})

  const handleAgree = () => {
    setStep((prev) => Number(prev) + 1)
  }

  const handleNext = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      ...formValues,
    }))
    setStep((prev) => Number(prev) + 1)
  }

  const handleAddress = (formValues) => {
    setAddress(formValues)
    setPayload((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        address: formValues,
      },
    }))
    setStep((prev) => prev + 1)
  }

  const handleHomeAddress = (formValues) => {
    const homeAddress = { ...formValues }
    setPayload((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        home_address: homeAddress,
      },
      same_as_billing_address: formValues.same_as_billing_address,
    }))
    setStep((prev) => prev + 1)
  }

  const handleSave = (formValues) => {
    setPayload((prev) => {
      const body = { ...prev, ...formValues }
      onSave(body)
      return body
    })
  }

  useEffect(() => {
    console.log(payload)
  }, [payload])

  return (
    <Stepper
      activeStep={activeStep}
      title="Finish activating your account"
      description="Please ensure you complete all fields carefully and accurately. Remofirst will then create your Employment Agreement using the information you provide"
      logout={logout}
    >
      {/* <Step step="1" title={step1} setStep={setStep}>
        <StepContent fullWidth>
          <Terms onAgree={handleAgree} />
        </StepContent>
      </Step> */}

      <Step step="1" title={step1} setStep={setStep}>
        <StepContent title={step1}>
          <PersonalProfileForm onNext={handleNext} />
        </StepContent>
      </Step>

      <Step
        step="2"
        title={step2}
        disabled={activeStep === 1}
        setStep={setStep}
      >
        <StepContent title={step2}>
          <AddressForm onNext={handleAddress} />
        </StepContent>
      </Step>

      <Step step="3" title={step3} disabled={activeStep <= 2} setStep={setStep}>
        <StepContent title={step3}>
          <HomeAddressForm address={address} onNext={handleHomeAddress} />
        </StepContent>
      </Step>

      <Step step="4" title={step4} disabled={activeStep <= 3} setStep={setStep}>
        <StepContent title={step4}>
          <PaymentsForm
            loading={loading}
            onFinish={handleSave}
            livingCountryId={payload?.profile?.address?.living_country}
          />
        </StepContent>
      </Step>
    </Stepper>
  )
}
