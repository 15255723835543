import Button from '@atoms/Button/Button'
import { CardStepper } from '@components/card-stepper/card-stepper.component'
import { CardStepperStep } from '@components/card-stepper/card-stepper-step.component'
import { useApp } from '@core/context'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { client } from '@pages/agreements/hellosign-client'
import { useToast } from '@core/hooks/useErrorNotification'
import { getSignUrl } from '../../services/sign.service'

export const Onboarding = () => {
  const { worker } = useApp()
  const history = useHistory()
  const { successAlert } = useToast()

  const { mutate, data: signUrl } = useMutation(['signUrl'], {
    mutationFn: (signatureId) => getSignUrl(signatureId),
    onSuccess: ({ sign_url }) => {
      client.open(sign_url, {
        skipDomainVerification:
          process.env.REACT_APP_HELLOSIGN_SKIP_DOMAIN === 'true',
      })
    },
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (signUrl) {
      client.on('finish', () => {
        successAlert(
          'Agreement has been signed! It may take some time to change status.'
        )
      })
    }
    return () => {
      client.off('finish')
    }
  }, [signUrl])

  const getDocumentActionsPlaceholderText = () => {
    if (worker.documents_state === 'waiting_document_approval')
      return 'Under review'
    if (
      worker.documents_state === 'waiting_declined_documents' ||
      worker.documents_state === 'waiting_document'
    )
      return ''
    return 'Approved'
  }

  const isDocumentsCompleted = () =>
    worker.documents_state !== 'waiting_declined_documents' &&
    worker.documents_state !== 'waiting_document_approval'

  const isDocumentsRejected = () =>
    worker.documents_state === 'waiting_declined_documents'

  const getSignActionsPlaceholderText = () => {
    if (worker?.job_agreement_document?.signature?.state === 'signed')
      return 'Signed'
    return ''
  }

  const isSignCompleted = () =>
    worker?.job_agreement_document?.signature?.state === 'signed'

  const isSignVisible = () =>
    worker?.has_agreement_to_sign &&
    worker?.job_agreement_document?.signature?.state === 'awaiting_signature'

  const handleSign = () => {
    mutate(worker?.job_agreement_document?.signature?.id)
  }

  return (
    <CardStepper>
      {worker?.need_to_provide_documents && (
        <CardStepperStep
          title="Documents"
          description="Our local service provider is reviewing the documents that you’ve submitted. Please, wait for your documents to be approved"
          actionsPlaceholderText={getDocumentActionsPlaceholderText()}
          completed={isDocumentsCompleted()}
          rejected={isDocumentsRejected()}
          actions={[
            <Button
              key={1}
              type="button"
              size="small"
              priority="secondary"
              onClick={() => history.push('documents')}
            >
              {worker.documents_state === 'waiting_declined_documents' ||
              worker.documents_state === 'waiting_document'
                ? 'Need action'
                : 'View'}
            </Button>,
          ]}
        />
      )}
      <CardStepperStep
        title="Sign the employment agreement"
        description="Once our local service provider crafts a contract, you will receive a copy for signing."
        actionsPlaceholderText={getSignActionsPlaceholderText()}
        completed={isSignCompleted()}
        actions={[
          isSignVisible() && (
            <Button
              key={1}
              type="button"
              size="small"
              priority="secondary"
              onClick={handleSign}
            >
              Sign
            </Button>
          ),
        ]}
      />
    </CardStepper>
  )
}
