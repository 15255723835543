import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { MdCheck } from 'react-icons/md'
import styled from 'styled-components'

import styles from './status-stepper.module.scss'

const Title = styled(Typography)`
  color: ${(p) => !p.success && '#878787'};
`
const Step = styled.div`
  color: ${(p) => !p.success && '#878787'};
`
const Progress = styled(ProgressBar)`
  background: #f5f5f5;
  height: 10px;
  .progress-bar {
    color: #037b0f;
    background: #037b0f;
  }
`

export default ({ steps, progress }) => {
  return (
    <>
      {steps.map((v, index) => (
        <div key={v.step} className={styles.container}>
          <div
            className={classNames(styles.step, {
              [styles.active]: v.success,
            })}
          >
            {v.success && <MdCheck color="#04c53a" fontSize={22} />}
            {!v.success && index + 1}
          </div>
          <div
            className={classNames(styles.content, {
              [styles.divider]: index !== steps.length - 1,
            })}
          >
            <Title
              success={v.success || v.active ? 1 : 0}
              className="text_medium__14 mt-1"
            >
              {v.title}
            </Title>
            {v.active && v.content && <div className="mt-2">{v.content}</div>}
          </div>
        </div>
      ))}
    </>
  )
}
