import React from 'react'
import Typography from '@atoms/Typography/Typography'

export default ({ title, fullWidth, children }) => {
  return (
    <section className="d-flex flex-column h-100 w-100">
      <header
        className="d-flex align-items-center justify-content-center pl-5 pr-5"
        style={{ marginTop: 40 }}
      >
        <Typography as="h4" className="heading_semibold__24">
          {title}
        </Typography>
      </header>

      <main className="flex-grow-1 d-flex justify-content-center align-items-center">
        <div className="h-100" style={{ width: fullWidth ? '100%' : 480 }}>
          {children}
        </div>
      </main>
    </section>
  )
}
