import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import RadioButton from '@atoms/RadioButton/RadioButton'

export default ({ address, onNext }) => {
  const { worker, countries } = useApp()
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      living_country: worker?.profile?.address?.living_country?.id || '',
      street_address: worker?.profile?.address?.street_address || '',
      address_line: worker?.profile?.address?.address_line || '',
      city: worker?.profile?.address?.city || '',
      postal_code: worker?.profile?.address?.postal_code || '',
      state: worker?.profile?.address?.state || '',
      same_as_billing_address: 'true',
    },
  })

  const watchSameAsBillingAddress = watch('same_as_billing_address')

  useEffect(() => {
    if (watchSameAsBillingAddress === 'true') {
      reset({
        ...address,
        same_as_billing_address: 'true',
      })
    } else {
      reset({
        living_country: '',
        street_address: '',
        address_line: '',
        city: '',
        postal_code: '',
        same_as_billing_address: 'false',
      })
    }
  }, [address, watchSameAsBillingAddress])

  const isDisabled = () => {
    return watchSameAsBillingAddress === 'true'
  }

  const submit = (formValues) => {
    const living_country =
      formValues.living_country.value || formValues.living_country
    const payload = {
      ...formValues,
      living_country,
    }

    if (formValues.address_line === '') {
      delete payload.address_line
    }

    onNext(payload)
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <RadioButton
            {...register('same_as_billing_address', {
              required: 'Field is required',
            })}
            label="The same as billing address?"
            options={[
              {
                id: 'Yes',
                text: 'Yes',
                value: true,
              },
              {
                id: 'No',
                text: 'No',
                value: false,
              },
            ]}
          />
          {errors.same_as_billing_address && (
            <Typography className="text_regular__14 color_red">
              {errors.same_as_billing_address.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Controller
            control={control}
            name="living_country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label="Country"
                  options={countries.map((v) => ({
                    value: v.id,
                    label: v.name,
                  }))}
                  isRequired
                  isDisabled={isDisabled()}
                />
              )
            }}
          />
          {errors.living_country && (
            <Typography className="text_regular__14 color_red">
              {errors.living_country.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Input
            {...register('street_address', {
              required: 'Full legal name is required',
            })}
            type="text"
            label="Address"
            placeholder="Enter your address"
            isRequired
            disabled={isDisabled()}
          />
          {errors.street_address && (
            <Typography className="text_regular__14 color_red">
              {errors.street_address.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Input
            {...register('address_line')}
            type="text"
            label="Address line 2 (Optional)"
            placeholder="Enter your address complement"
            disabled={isDisabled()}
          />
          {errors.address_line && (
            <Typography className="text_regular__14 color_red">
              {errors.address_line.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Input
            {...register('city', {
              required: 'City is required',
            })}
            type="text"
            label="City"
            placeholder="Enter your city"
            disabled={isDisabled()}
            isRequired
          />
          {errors.city && (
            <Typography className="text_regular__14 color_red">
              {errors.city.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Input
            {...register('state', {
              required: 'State is required',
            })}
            type="text"
            label="State"
            placeholder="Enter your state"
            disabled={isDisabled()}
            isRequired
          />
          {errors.state && (
            <Typography className="text_regular__14 color_red">
              {errors.state.message}
            </Typography>
          )}
        </div>
        <div className="remo-form-input">
          <Input
            {...register('postal_code', {
              required: 'Field is required',
            })}
            type="text"
            label="Postal code"
            placeholder="Enter your zip or postal code"
            isRequired
            disabled={isDisabled()}
          />
          {errors.postal_code && (
            <Typography className="text_regular__14 color_red">
              {errors.postal_code.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
