/* eslint-disable no-shadow */
import React from 'react'

const usePagination = (props) => {
  const [_page, setPage] = React.useState(props.page)
  const [_limit, setLimit] = React.useState(props.limit)

  const handleSetLimit = (limit) => {
    setPage(props.page)
    setLimit(limit)
  }

  return {
    page: _page,
    setPage,
    limit: _limit,
    setLimit: handleSetLimit,
  }
}

export default usePagination
