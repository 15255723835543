import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import Input from '@atoms/Input/Input'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'

export default ({ loading, submitTitle, onFinish }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      relationship: '',
      email: '',
      phone_number: '',
    },
  })

  const submit = (formValues) => {
    onFinish({
      emergency_contact: formValues,
    })
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <Input
            {...register('name', {
              required: 'Name is required',
            })}
            type="text"
            label="Name"
            placeholder="Enter your emergency contact’s full name"
          />
          {errors.name && (
            <Typography className="text_regular__14 color_red">
              {errors.name.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('relationship', {
              required: 'Field is required',
            })}
            type="text"
            label="Relationship"
            placeholder="E.g. mother, father, spouse"
          />
          {errors.relationship && (
            <Typography className="text_regular__14 color_red">
              {errors.relationship.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Input
            {...register('email', {
              required: 'Email is required',
              validate: {
                isEmail: (v) => {
                  return /.+@.+\..+/.test(v) || 'Please enter correct email'
                },
              },
            })}
            type="email"
            label="Email"
            placeholder="Enter your emergency contact’s email address"
          />
          {errors.email && (
            <Typography className="text_regular__14 color_red">
              {errors.email.message}
            </Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="phone_number"
            rules={{
              required: 'Phone number is required',
            }}
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  international
                  withCountryCallingCode
                  label="Phone number"
                  placeholder="Enter your emergency contact’s phone number"
                />
              )
            }}
          />
          {errors.phone_number && (
            <Typography className="text_regular__14 color_red">
              {errors.phone_number.message}
            </Typography>
          )}
        </div>
      </section>

      <div className="pb-5 align-self-center">
        <Button
          type="submit"
          className="align-self-end"
          loading={loading}
          disabled={loading}
        >
          {submitTitle}
        </Button>
      </div>
    </form>
  )
}
