/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Icon from '@atoms/Icon/Icon'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { deleteIcon } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import moment from 'moment'
import React, { useMemo, useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { AppContext } from '@core/context'
import { useQuery } from 'react-query'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import { fetchUploadFile } from '../../services/app.service'
import { calculateDayOff } from '../../services/absences.service'
import { getContractsPaidLeaves } from '../../services/contract.service'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`

export const leaveTypes = [
  { value: 'PAID', label: 'Paid time off' },
  { value: 'UNPAID', label: 'Unpaid time off' },
]
export const reasonTypes = {
  PAID: [
    { value: 'VACATION', label: 'Vacation', is_paid: true, name: 'paid_leave' },
    {
      value: 'PARENTAL_LEAVE',
      label: 'Parental leave',
      is_paid: true,
      name: 'paid_parental_leave',
    },
    {
      value: 'BEREAVEMENT',
      label: 'Bereavement',
      is_paid: true,
      name: 'paid_bereavement_leave',
    },
    {
      value: 'SICK_LEAVE',
      label: 'Sick Leave',
      is_paid: true,
      name: 'paid_sick_leave',
    },
  ],
  UNPAID: [
    { value: 'PARENTAL_LEAVE', label: 'Parental leave', is_paid: false },
    {
      value: 'BEREAVEMENT',
      label: 'Bereavement',
      is_paid: false,
    },
    {
      value: 'SICK_LEAVE',
      label: 'Sick Leave',
      is_paid: false,
    },
    { value: 'CAREGIVER_LEAVE', label: 'Caregiver leave', is_paid: false },
    { value: 'EDUCATIONAL_LEAVE', label: 'Education leave', is_paid: false },
    { value: 'MARRIAGE_LEAVE', label: 'Marriage leave', is_paid: false },
    {
      value: 'MILITARY_TRAINING_ASSEMBLY',
      label: 'Military training assembly',
      is_paid: false,
    },
    { value: 'MILITARY_LEAVE', label: 'Military leave', is_paid: false },
    {
      value: 'MILITARY_MEDICAL_EXAMINATION',
      label: 'Military medical examination',
      is_paid: false,
    },
    {
      value: 'SABBATICAL',
      label: 'Sabbatical',
      is_paid: false,
    },
    {
      value: 'CIVIC_DUTIES',
      label: 'Civic duties',
      is_paid: false,
    },
    {
      value: 'RELIGIOUS_OBSERVANCE',
      label: 'Religious observance',
      is_paid: false,
    },
    {
      value: 'OTHER_UNPAID_LEAVE',
      label: 'Other unpaid leave',
      is_paid: false,
    },
  ],
}

export default ({ onClose, onSubmit, loading, title, isEdit, timeOff }) => {
  const { worker } = useContext(AppContext)
  const [doc, setDoc] = useState({
    file_uuid: timeOff?.filename || undefined,
  })
  const [totalDayOff, setTotalDayOff] = useState()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      contract: worker.id || undefined,
      start_date: timeOff?.start_date
        ? new Date(timeOff.start_date)
        : undefined,
      end_date: timeOff?.end_date ? new Date(timeOff.end_date) : undefined,
      leave_type: timeOff?.leave_type || undefined,
      is_paid: timeOff?.is_paid || false,
      is_one_day_absence: timeOff?.is_one_day_absence || false,
      current_country: worker?.working_country?.id || undefined,
      leave_reason_type: timeOff?.leave_reason_type || undefined,
      start_date_is_half_day: timeOff?.start_date_is_half_day || false,
      comment: timeOff?.comment || undefined,
    },
  })

  const starts_at_watch = watch('start_date')
  const end_at_watch = watch('end_date')
  const oneDayWatch = watch('is_one_day_absence')
  const start_half_watch = watch('start_date_is_half_day')
  const currentCountry = watch('current_country')
  const leaveTypeWatch = watch('leave_type')
  const leaveReasonTypeWatch = watch('leave_reason_type')

  const { isLoading: isLoadingTotalDayOff } = useQuery(
    [
      'calculateDayOff',
      starts_at_watch,
      end_at_watch,
      currentCountry,
      start_half_watch,
    ],
    {
      queryFn: () =>
        calculateDayOff({
          start_date: moment(starts_at_watch).format('YYYY-MM-DD'),
          end_date: end_at_watch
            ? moment(end_at_watch).format('YYYY-MM-DD')
            : undefined,
          start_date_is_half_day: start_half_watch || undefined,
          country: currentCountry,
        }),
      onSuccess: ({ data }) => {
        setTotalDayOff(Object.values(data)[0])
      },
      enabled: !!currentCountry && !!starts_at_watch,
    }
  )
  const { data, isLoading: isLoadingPaid } = useQuery(
    ['getContractsPaidLeaves', worker],
    {
      queryFn: () => getContractsPaidLeaves(worker.id),
      enabled: !!worker.id,
    }
  )
  const checkAllRequire = useMemo(() => {
    return !!(starts_at_watch && leaveTypeWatch)
  }, [starts_at_watch, leaveTypeWatch])
  const handleCreateTimeOff = (formValues) => {
    const data = {
      ...formValues,
      attached_document:
        doc.file_uuid === timeOff?.filename ? undefined : doc.file_uuid,
      start_date: moment(formValues.start_date).format('YYYY-MM-DD'),
      // eslint-disable-next-line no-nested-ternary
      end_date: oneDayWatch
        ? moment(formValues.start_date).format('YYYY-MM-DD')
        : formValues.end_date
        ? moment(formValues.end_date).format('YYYY-MM-DD')
        : undefined,
      contract: formValues.contract.value || formValues.contract,
      current_country:
        formValues.current_country || formValues.contract.countryId,
      leave_type: formValues.leave_type.value || formValues.leave_type,
      // eslint-disable-next-line no-nested-ternary
      leave_reason_type:
        formValues.leave_reason_type.value || formValues.leave_reason_type,
      request_creator_role: 'CONTRACT',
      is_paid: formValues.leave_reason_type.is_paid,
    }
    onSubmit(data)
  }

  const handleUpload = async (file) => {
    const setProgress = (progress) => {
      setDoc((prev) => ({
        ...prev,
        isLoading: true,
        progress,
      }))
    }

    fetchUploadFile(file, setProgress)
      .then(({ file_uuid }) => {
        setDoc((prev) => ({
          ...prev,
          file_uuid,
          isLoading: false,
          success: true,
          progress: 0,
        }))
      })
      .catch(() => {
        setDoc((prev) => ({
          ...prev,
          isLoading: false,
          success: false,
          progress: 0,
        }))
      })
  }

  const handleRemove = () => () => {
    setDoc({})
  }
  const handleDelete = () => {
    setDoc({
      file_uuid: undefined,
    })
  }
  return (
    <StyledModal show onHide={onClose} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4">
        <form id="timeOff-form" onSubmit={handleSubmit(handleCreateTimeOff)}>
          <div className="remo-form-input mt-3 mb-3">
            <div className="toggle-bg">
              <div className="d-flex align-items-center justify-content-between">
                <Typography className="text_medium__14">
                  I need one day
                </Typography>
                <Toggle
                  id="is_one_day_absence"
                  {...register('is_one_day_absence')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pr-2">
              <div className="w-100 remo-form-input">
                <Controller
                  control={control}
                  name="start_date"
                  rules={{ required: 'Start date is required' }}
                  render={({ field }) => {
                    return (
                      <DateInput
                        {...field}
                        isRequired
                        label="First day of the leave"
                      />
                    )
                  }}
                />
                {errors.start_date && (
                  <Typography className="text_regular__14 color_red">
                    {errors.start_date.message}
                  </Typography>
                )}
              </div>
            </div>
            {!oneDayWatch && (
              <div className="col-6 pl-2">
                <div className="w-100 remo-form-input">
                  <Controller
                    control={control}
                    name="end_date"
                    rules={{ required: 'End date is required' }}
                    render={({ field }) => {
                      return (
                        <DateInput
                          {...field}
                          isRequired
                          label="Last day of the leave"
                          minDate={starts_at_watch}
                        />
                      )
                    }}
                  />
                  {errors.end_date && (
                    <Typography className="text_regular__14 color_red">
                      {errors.end_date.message}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="remo-form-input mt-3 mb-1">
            <div className="toggle-bg">
              <div className="d-flex align-items-center justify-content-between">
                <Typography className="text_medium__14">
                  Start from second part of the day
                </Typography>
                <Toggle
                  id="start_date_is_half_day"
                  {...register('start_date_is_half_day')}
                />
              </div>
            </div>
          </div>

          <div className="remo-form-input mt-1 mb-3">
            <div className="toggle-bg d-flex justify-content-between align-items-center">
              <div>
                <Typography className="text_medium__14">
                  Total time off
                </Typography>
                <Typography className="text_regular-normal__14 color_text_300">
                  This doesnt include weekends and national holidays
                </Typography>
              </div>

              {isLoadingTotalDayOff ? (
                <div className="edit-avatar__loader">
                  <DotWave size={12} speed={1} color="black" />
                </div>
              ) : (
                <Typography className="text_medium__14">
                  {totalDayOff}
                </Typography>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col pr-2">
              <Controller
                control={control}
                name="leave_type"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label="Leave Type"
                      options={leaveTypes}
                    />
                  )
                }}
              />
              {errors.leave_type && (
                <Typography className="text_regular__14 color_red">
                  {errors.leave_type.message}
                </Typography>
              )}
            </div>
            <div className="col pl-2">
              <Controller
                control={control}
                name="leave_reason_type"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      isDisabled={!leaveTypeWatch}
                      label="Reason Type"
                      options={
                        leaveTypeWatch?.value || leaveTypeWatch
                          ? reasonTypes[leaveTypeWatch.value || leaveTypeWatch]
                          : []
                      }
                    />
                  )
                }}
              />
              {errors.leave_reason_type && (
                <Typography className="text_regular__14 color_red">
                  {errors.leave_reason_type.message}
                </Typography>
              )}
            </div>
          </div>
          <TextArea placeholder="Comment" {...register('comment')} />
          {errors.comment && (
            <Typography className="text_regular__14 color_red">
              {errors.comment.message}
            </Typography>
          )}
          {doc.file_uuid && timeOff?.attached_document ? (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Typography as="span" className="text_medium__14">
                {timeOff?.filename}
              </Typography>
              <Button
                priority="secondary"
                style={{ height: '32px' }}
                size="small"
                className="px-2 ml-3"
                onClick={handleDelete}
              >
                <Icon fill="none" icon={deleteIcon} />
                <Typography className={classNames('text_light__12', 'ml-2')}>
                  Delete
                </Typography>
              </Button>
            </div>
          ) : (
            <UploadButton
              addText={doc.description}
              isLoading={doc.isLoading}
              isSuccess={doc.success}
              progress={doc.progress}
              onRemove={handleRemove()}
              onChange={(_file) => handleUpload(_file)}
              accept="application/pdf"
            />
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {data &&
          totalDayOff &&
          leaveTypeWatch?.value === leaveTypes[0].value &&
          leaveReasonTypeWatch?.name ? (
            isLoadingPaid ? (
              <div
                className="edit-avatar__loader"
                style={{ width: 40, height: 40 }}
              >
                <DotWave size={32} speed={1} color="black" />
              </div>
            ) : (
              <StyledTable className="mt-3">
                <tr>
                  <th>
                    <Typography
                      className="text_regular-normal__14 ml-2"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      Current PTO balance
                    </Typography>
                  </th>
                  <td align="right">
                    <Typography
                      className="text_regular-normal__14 mr-2"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      {data?.leave_days?.[leaveReasonTypeWatch?.name]
                        ?.available_days || 0}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className="text_regular-normal__14 ml-2"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      Time requested
                    </Typography>
                  </th>
                  <td align="right">
                    <Typography
                      className="text_regular-normal__14 mr-2"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      {totalDayOff}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Typography
                      className="text_regular-normal__14 ml-2"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      Remaining days after request
                    </Typography>
                  </th>
                  <td align="right">
                    <Typography
                      className="text_regular-normal__14 mr-2"
                      style={{ letterSpacing: '0.4px' }}
                    >
                      {Number(
                        Number(
                          data?.leave_days?.[leaveReasonTypeWatch?.name]
                            ?.available_days || 0
                        ) - totalDayOff
                      ).toFixed(2)}
                    </Typography>
                  </td>
                </tr>
                {Number(
                  data?.leave_days?.[leaveReasonTypeWatch?.name]
                    ?.available_days || 0
                ) -
                  totalDayOff <
                  0 && (
                  <NotificationBlock
                    isError
                    render={
                      <Typography className="text_regular-normal__14 color_red">
                        <strong>Pay attention:</strong> You have taken more days
                        than are available to you. However, you can still create
                        the request and send it for Employer’s approval. Once
                        approved, your remaining balance will become negative.
                      </Typography>
                    }
                  />
                )}
              </StyledTable>
            )
          ) : null}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          form="timeOff-form"
          type="submit"
          size="small"
          disabled={!checkAllRequire}
          loading={loading}
          priority="primary"
        >
          {isEdit ? 'Update & Send' : 'Create'}
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
